@charset "UTF-8";

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 0px !important;
}

* {
    padding: 0;
    margin: 0;
}

body {
    /* font-family: "Poppins", sans-serif; */
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 15px;
    background-color: #ffffff;
}

img {
    max-width: 100%;
}

a,
button {
    transition: 0.3s;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

button:focus,
input:focus,
textarea:focus {
    outline: 0;
}

button {
    background-color: transparent;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #322fb3;
    margin: 0px;
    color: #343c55;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

p {
    font-size: 14px !important;
    font-weight: normal;
    color: #717788;
    margin-bottom: 15px;
    letter-spacing: 1px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

p:last-child {
    margin-bottom: 0px;
}

hr {
    border-bottom: 1px solid #e0e0e0;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

*::-moz-selection {
    background: #343c55;
    color: #ffffff;
    text-shadow: none;
}

::-moz-selection {
    background: #343c55;
    color: #ffffff;
    text-shadow: none;
}

::selection {
    background: #343c55;
    color: #ffffff;
    text-shadow: none;
}


/*--
 - Input Placeholder
-----------------------------------------*/

*::-moz-placeholder {
    color: #343c55;
    font-size: 14px;
    opacity: 1;
}

*::placeholder {
    color: #343c55;
    font-size: 14px;
    opacity: 1;
}


/* Brand Color */

.bg-one {
    background-color: #f2fbfe;
}


/* Custom Class */

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gradient-1 {
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
}

.gradient-2 {
    background: #4facfe;
    background: linear-gradient(-45deg, #00f2fe 0%, #28cffe 50%, #4facfe 100%);
}

.gradient-text-1 {
    background: #322fb3;
    background: linear-gradient(to right, #322fb3 39%, #d5312c 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heading-one h4 {
    font-size: 1.3rem !important;
    letter-spacing: 2px;
    font-weight: 100 !important;
}


/* Section Heading */


/* Section Spacing */

.mb-n20 {
    margin-bottom: -20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-n30 {
    margin-bottom: -30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-n60 {
    margin-bottom: -60px;
}

@media only screen and (max-width: 767px) {
    .mb-n60 {
        margin-bottom: -30px;
    }
}

.mb-60 {
    margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
    .mb-60 {
        margin-bottom: 30px;
    }
}


/* Social Style */

.facebook {
    color: #3b5998;
}

.twitter {
    color: #1da1f2;
}

.linkedin {
    color: #0077b5;
}

.youtube {
    color: #dd4b39;
}


/* Custom Container */

@media only screen and (min-width: 1400px) {
    .container {
        max-width: 1194px;
    }
}

.custom-container {
    max-width: 1450px;
    width: 100%;
}

@media only screen and (min-width: 1800px) {
    .custom-container {
        margin: 0 100px 0 auto;
    }
}

@media only screen and (min-width: 1700px) and (max-width: 1799px) {
    .custom-container {
        margin: 0 100px 0 auto;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .custom-container {
        margin: 0 80px 0 auto;
        max-width: 1300px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .custom-container {
        margin: 0 30px 0 auto;
        max-width: 1200px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1299px) {
    .custom-container {
        margin: 0 auto 0;
    }
}


/* Spacing */

.section-padding {
    /* padding: 120px 0; */
    padding-top: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .section-padding {
        padding-top: 80px 0 !important;
    }
}

@media only screen and (min-width: 992px) {
    .mh126 {
        min-height: 321px !important;
    }

    .mh125 {
        min-height: 258px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding {
        padding-top: 80px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding {
        padding-top: 80px 0;
    }
}

@media only screen and (max-width: 767px) {
    .section-padding {
        padding: 60px 0;
    }
}

@media only screen and (max-width: 479px) {
    .section-padding {
        padding: 20px 0;
    }
}

.section-padding-top {
    padding-top: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .section-padding-top {
        padding-top: 120px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding-top {
        padding-top: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding-top {
        padding-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .section-padding-top {
        padding-top: 60px;
    }
}

.section-padding-bottom {
    padding-bottom: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .section-padding-bottom {
        padding-bottom: 120px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding-bottom {
        padding-bottom: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding-bottom {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .section-padding-bottom {
        padding-bottom: 60px;
    }
}

@media only screen and (max-width: 479px) {
    .section-padding-bottom {
        padding-bottom: 60px;
    }
}

.section-margin {
    margin: 120px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .section-margin {
        margin: 120px 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-margin {
        margin: 110px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-margin {
        margin: 80px 0;
    }
}

@media only screen and (max-width: 767px) {
    .section-margin {
        margin: 60px 0;
    }
}

@media only screen and (max-width: 479px) {
    .section-margin {
        margin: 60px 0;
    }
}

.section-margin-top {
    margin-top: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .section-margin-top {
        margin-top: 80px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-margin-top {
        margin-top: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-margin-top {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .section-margin-top {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 479px) {
    .section-margin-top {
        margin-top: 60px;
    }
}

.section-margin-bottom {
    margin-bottom: 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .section-margin-bottom {
        margin-bottom: 120px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-margin-bottom {
        margin-bottom: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-margin-bottom {
        margin-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .section-margin-bottom {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 479px) {
    .section-margin-bottom {
        margin-bottom: 60px;
    }
}


/* Scroll Top style */

.scroll-top {
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
    display: block;
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: #322fb3;
    background-size: 200% auto;
    background-position: left center;
    transition: 0.3s;
    overflow: hidden;
    border: 1px solid #343c55;
}

.scroll-top:hover {
    background-color: #343c55;
    border: 1px solid #322fb3;
    background-position: right center;
}

.scroll-top:hover i {
    color: White;
    transition: 0.3s;
}

.scroll-top:hover .arrow-top {
    transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
    transform: translate(-50%, -50%);
}

.scroll-top i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #ffffff;
    transition: 0.3s;
}

.scroll-top.show {
    display: none;
    visibility: visible;
    opacity: 1;
    bottom: 60px;
}

.scroll-top.show.show-active {
    display: block;
}

.scroll-top .arrow-top {
    transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
    transform: translate(-50%, 80px);
}

@media only screen and (max-width: 479px) {
    .scroll-top {
        font-size: 20px;
    }
}


/* Logo Style */

@media only screen and (max-width: 767px) {
    .logo {
        width: 160px;
    }
}

@media only screen and (max-width: 575px) {
    .logo {
        width: 140px;
    }
}


/* Swiper Pagination Style */

.pagination-style-one .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    margin: 0 14px !important;
    background: #322fb3;
}

@media only screen and (max-width: 767px) {
    .pagination-style-one .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin: 0 8px !important;
    }
}

.pagination-style-one .swiper-pagination-bullet-active {
    position: relative;
}

.pagination-style-one .swiper-pagination-bullet-active::before {
    content: "";
    position: absolute;
    border: 2px solid #d5312c;
    height: 22px;
    width: 22px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
}

@media only screen and (max-width: 767px) {
    .pagination-style-one .swiper-pagination-bullet-active::before {
        width: 18px;
        height: 18px;
    }
}


/* Default Pagination Style */

.pagination-wrapper .pagination .page-item {
    margin-right: 8px;
}

.pagination-wrapper .pagination .page-item:last-child {
    margin-right: 0px;
}

.pagination-wrapper .pagination .page-item .page-link {
    border-radius: 5px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #717788;
    font-size: 16px;
    border: 1px solid #bdbdcc;
}

.pagination-wrapper .pagination .page-item .page-link:focus {
    box-shadow: none;
}

.pagination-wrapper .pagination .page-item .page-link:hover,
.pagination-wrapper .pagination .page-item .page-link.active {
    color: white;
    background: #d5312c;
    border: 1px solid #d5312c;
}

.z-index-9 {
    z-index: 9;
}


/*----------------------------------------*/


/*  1.2 Button Styles CSS
/*----------------------------------------*/

.btn-style-one {
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
    border: 0px;
    border-radius: 7px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    padding: 20px 45px;
    line-height: 1;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .btn-style-one {
        padding: 16px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .btn-style-one {
        padding: 15px 20px;
        font-size: 14px;
    }
}

.btn-style-one:focus {
    box-shadow: none;
}

.btn-style-one::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(113, 83, 217);
    background: linear-gradient(-45deg, #322fb3 20%, #417dd2 50%, #d5312c 100%);
    opacity: 0;
    transition: 0.5s;
    border-radius: 7px;
    z-index: 1;
}

.btn-style-one:hover span {
    color: #ffffff;
}

.btn-style-one:hover::before {
    opacity: 1;
    transition: 0.5s;
}

.btn-style-one span {
    position: relative;
    z-index: 3;
    color: #ffffff;
}

.z9 {
    z-index: 999;
}

.btn-style-two {
    background: #d5312c;
    border: 0px;
    border-radius: 7px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    padding: 25px 65px;
    line-height: 1;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .btn-style-two {
        padding: 16px 30px;
    }

    .m-f {
        font-size: 23px !important;
    }

    .p-f {
        font-size: 12px !important;
    }

    .hero .ones {
        padding: 8px 35px !important;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .btn-style-two {
        padding: 15px 20px;
        font-size: 14px;
    }
}

.btn-style-two:focus {
    box-shadow: none;
}

.btn-style-two:hover {
    color: #ffffff;
    background-color: #343c55;
}

.btn-style-three {
    background: #322fb3;
    border: 0px;
    border-radius: 7px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    padding: 20px 60px;
    line-height: 1;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .btn-style-three {
        padding: 16px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .btn-style-three {
        padding: 15px 20px;
        font-size: 14px;
    }
}

.btn-style-three:focus {
    box-shadow: none;
}

.btn-style-three:hover {
    color: #ffffff;
    background-color: #343c55;
}

.btn-style-four {
    background: #d5312c;
    border: 0px;
    border-radius: 7px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    padding: 21px 65px;
    line-height: 1;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .btn-style-four {
        padding: 16px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .btn-style-four {
        padding: 15px 20px;
        font-size: 14px;
    }
}

.btn-style-four:focus {
    box-shadow: none;
}

.btn-style-four:hover {
    color: #ffffff;
    background-color: #343c55;
}


/*----------------------------------------*/


/*  1.3 Heading Styles CSS
/*----------------------------------------*/

.heading-one-subtitle {
    color: #175faa;
    font-size: 15px;
    letter-spacing: 4px;
    font-weight: 100;
    text-transform: uppercase;
    line-height: 1;
}

@media only screen and (max-width: 767px) {
    .heading-one-subtitle {
        font-size: 15px;
        letter-spacing: 1px;
    }
}

.heading-one-title {
    /* margin-top: 30px; */
    font-size: 1.8rem !important;
    font-weight: 500;
    color: #343c55;
    letter-spacing: 2px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .heading-one-title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .heading-one-title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .heading-one-title {
        font-size: 36px;
    }
}

@media only screen and (max-width: 479px) {
    .heading-one-title {
        font-size: 30px;
    }
}

.heading-one-text-two {
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
}

.rotate-title {
    font-size: 260px;
    font-weight: 700;
    line-height: 1;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #322fb3;
    -webkit-text-fill-color: transparent;
    opacity: 0.28;
    top: 80%;
    position: absolute;
    z-index: 9;
}

.rotate-title.rotate-left {
    transform: rotate(270deg);
    left: -300px;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .rotate-title.rotate-left {
        font-size: 200px;
        left: -260px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .rotate-title.rotate-left {
        font-size: 130px;
        left: -177px;
    }
}

.rotate-title.rotate-right {
    transform: rotate(90deg);
    right: -450px;
    overflow: hidden;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .rotate-title.rotate-right {
        font-size: 200px;
        right: -370px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .rotate-title.rotate-right {
        font-size: 130px;
        right: -240px;
    }
}


/*----------------------------------------*/


/*  1.4 Keyframe Styles CSS
/*----------------------------------------*/

@-webkit-keyframes moveupdown1 {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes moveupdown1 {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@-webkit-keyframes moveupdown2 {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes moveupdown2 {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@-webkit-keyframes moveleftright1 {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes moveleftright1 {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0px);
    }
}

@-webkit-keyframes zoom1 {
    0% {
        transform: scale(1px);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoom1 {
    0% {
        transform: scale(1px);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes circle1 {
    0% {
        transform: rotate(0deg) scale(1);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes circle1 {
    0% {
        transform: rotate(0deg) scale(1);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

@-webkit-keyframes circle2 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes circle2 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes moveleftright {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-30px);
    }
}

@keyframes moveleftright {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-30px);
    }
}

@-webkit-keyframes moveleftrightwo {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-30px);
    }
}

@keyframes moveleftrightwo {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-30px);
    }
}

@-webkit-keyframes animshape1 {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    50% {
        transform: translate(10px, 10px) rotate(0deg) scale(1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@keyframes animshape1 {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    50% {
        transform: translate(10px, 10px) rotate(0deg) scale(1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@-webkit-keyframes animshape2 {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    50% {
        transform: translate(10px, -10px) rotate(0deg) scale(1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@keyframes animshape2 {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    50% {
        transform: translate(10px, -10px) rotate(0deg) scale(1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}


/* =============================
    2.1 - Header Style
================================ */

.header-one .logo-2 {
    display: none;
}

.header-one.sticky .logo-1 {
    display: none;
}

.header-one.sticky .logo-2 {
    display: block;
}

.header-one.sticky .hamburger_button span {
    background: #322fb3;
}

.header-one.sticky .hamburger_button:hover span {
    background: #d5312c;
}

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: 0.3s;
}

.header-wrapper-two .main-menu>ul>li>a {
    line-height: 145px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .header-wrapper-two .main-menu>ul>li>a {
        line-height: 130px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-wrapper-two .main-menu>ul>li>a {
        line-height: 110px;
    }
}

.header-wrapper-two .hamburger_button span {
    background: #322fb3;
}

.header-wrapper-two .hamburger_button:hover span {
    background: #d5312c;
}

.offcanvas {
    z-index: 9999;
    padding: 20px;
}

.offcanvas .btn-close {
    background: none;
    font-size: 20px;
}

.offcanvas .btn-close:focus {
    box-shadow: none;
}

.offcanvas .logo {
    width: 150px;
}

.header-right_actions {
    margin-left: 40px;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .header-right_actions {
        margin-left: 0;
    }
}

.header-right_button {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.header-button .btn-style-one {
    opacity: 0.93;
}


/* Main Menu Style */

.main-menu>ul>li {
    display: inline-flex;
    margin-right: 40px;
}

.main-menu>ul>li:last-child {
    margin-right: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .main-menu>ul>li {
        margin-right: 40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu>ul>li {
        margin-right: 25px;
    }
}

.main-menu>ul>li>a {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 120px;
    cursor: pointer;
    font-weight: 500;
    color: #343c55;
    /* color: #fff; */
    position: relative;
    transition: 0.3s;
    letter-spacing: 1px;
}

.main-menu>ul>li:hover>a {
    color: #322fb3;
}

.main-menu .has-children {
    position: relative;
}

.main-menu .has-children>.sub-menu {
    position: absolute;
    background: #ffffff;
    box-shadow: 0 0 6px -3px;
    width: 230px;
    transform-origin: 0 0 0;
    transform: scaleY(0);
    transition: all 0.3s ease-in-out;
    z-index: 9;
    padding-left: 0;
    top: 100%;
    border-bottom: 2px solid #322fb3;
    padding: 0px;
}

.main-menu .has-children>.sub-menu>li {
    border-bottom: 1px dashed #ffe9e9;
}

.main-menu .has-children>.sub-menu>li:last-child {
    border-bottom: 0px;
}

.main-menu .has-children>.sub-menu>li a {
    padding: 12px 20px;
    font-weight: 500;
    font-size: 16px;
    color: #343c55;
    text-transform: uppercase;
    display: block;
}

.main-menu .has-children>.sub-menu>li:hover a {
    color: #322fb3;
    transition: 0.3s;
}

.main-menu .has-children>.sub-menu>li.has-submenu {
    position: relative;
}

.main-menu .has-children>.sub-menu>li.has-submenu>a {
    position: relative;
    display: block;
}

.main-menu .has-children>.sub-menu>li.has-submenu>a::before {
    content: "›";
    color: #343c55;
    position: absolute;
    font-size: 26px;
    line-height: 1;
    top: 4px;
    right: 15px;
}

.main-menu .has-children>.sub-menu>li.has-submenu .submenu-nav {
    background-color: #ffffff;
    left: 100%;
    right: auto;
    top: 8px;
    opacity: 0;
    margin-top: 30px;
    color: #131b13;
    opacity: 0;
    margin-top: 43px;
    min-width: 180px;
    padding: 0;
    position: absolute;
    top: 0%;
    transition: all 0.3s ease-out;
    visibility: hidden;
    width: 200px;
    z-index: 9999;
    border-bottom: 2px solid #322fb3;
}

.main-menu .has-children>.sub-menu>li.has-submenu .submenu-nav li {
    border-bottom: 1px dashed #ffe9e9;
}

.main-menu .has-children>.sub-menu>li.has-submenu .submenu-nav li:last-child {
    border-bottom: 0px;
}

.main-menu .has-children>.sub-menu>li.has-submenu .submenu-nav a {
    padding: 12px 20px;
    font-weight: 500;
    font-size: 16px;
    color: #343c55;
    display: block;
}

.main-menu .has-children>.sub-menu>li.has-submenu .submenu-nav a:hover {
    color: #322fb3;
}

.main-menu .has-children>.sub-menu>li.has-submenu:hover .submenu-nav {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.main-menu .has-children:hover>.sub-menu {
    transform: scaleY(1);
}


/* Social Icon Style */

.social-icon li {
    margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .social-icon li {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .social-icon li {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .social-icon li {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .social-icon li {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .social-icon li {
        margin-right: 10px;
    }
}

.social-icon li:last-child {
    margin-right: 0px;
}

.social-icon li a {
    font-size: 20px;
}

@media only screen and (max-width: 479px) {
    .social-icon li a {
        font-size: 16px;
    }
}


/* Hamburger Style */

.hamburger {
    margin-left: 20px;
}

@media only screen and (max-width: 479px) {
    .hamburger {
        margin-left: 10px;
    }
}

.hamburger_button {
    line-height: 100px;
    position: relative;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.hamburger_button span {
    list-style: none;
    width: 20px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    transition: 0.9s;
}

.hamburger_button span:nth-of-type(1) {
    top: 20%;
}

.hamburger_button span:nth-of-type(2) {
    top: 50%;
}

.hamburger_button span:nth-of-type(3) {
    top: 80%;
}

.hamburger_button.open span:nth-of-type(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg) rotateY(180deg);
}

.hamburger_button.open span:nth-of-type(2) {
    width: 0;
}

.hamburger_button.open span:nth-of-type(3) {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) rotateY(180deg);
}

.hamburger_button:hover span {
    background: #d5312c;
}


/* Sticky Style */

.sticky-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    margin-top: 0px;
    background-color: #ffffff;
    box-shadow: 10px 0px 49px rgba(25, 7, 89, 0.08);
    transition: 0.3s;
}

.sticky-header.sticky .main-menu>ul>li>a {
    line-height: 90px;
}

.sticky-header.sticky .social-icon li a {
    line-height: 80px;
}

.sticky-header.sticky .header-button {
    line-height: 80px;
}


/* Mobile Menu Style */

.mobile-menu li {
    display: block;
    position: relative;
}

.mobile-menu li a {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    display: inline-block;
    padding: 10px 0;
    color: #343c55;
}

.mobile-menu li a:hover {
    color: #322fb3;
}

.mobile-menu li.menu-item-has-children .menu-expand {
    line-height: 50;
    top: -7px;
    right: 0%;
    width: 30px;
    position: absolute;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.mobile-menu li.menu-item-has-children .menu-expand i {
    display: block;
    position: relative;
    width: 10px;
    margin-top: 25px;
    border-bottom: 1px solid #343c55;
    transition: all 250ms ease-out;
    float: right;
}

.mobile-menu li.menu-item-has-children .menu-expand i::before {
    top: 0;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    transform: rotate(90deg);
    border-bottom: 1px solid #343c55;
    transition: 0.4s;
}

.mobile-menu li.menu-item-has-children.active>.menu-expand i::before {
    transform: rotate(0);
}

.mobile-menu li ul li a {
    padding: 5px 15px 5px;
    font-size: 15px;
    font-weight: 400;
}

.mobile-menu li ul li a:hover {
    color: #322fb3;
}

.mobilemenu-close {
    padding: 0 !important;
    color: #343c55 !important;
    opacity: 1 !important;
    margin: 0 !important;
}


/* Header Three */

.header-three {
    position: fixed;
    width: 100%;
    z-index: 9;
}

.header-three .main-menu>ul>li>a {
    color: #ffffff;
}

.header-three .logo-2 {
    display: none;
}

.header-three.sticky .main-menu>ul>li>a {
    color: #343c55;
}

.header-three.sticky .logo-2 {
    display: block;
}

.header-three.sticky .logo-1 {
    display: none;
}


/* Header Four  */

.header-four {
    /* margin-top: -25px; */
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;
}

.header-four .header-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
    .header-four .header-top {
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 10px;
    }
}

.header-four .header-top .left-link {
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #bbbbbb;
}

.header-four .header-top-link {
    color: #717788;
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-size: 15px;
    letter-spacing: 1px;
    margin-left: 5px;
}

.header-four .header-top-link:hover {
    color: #322fb3;
}

@media only screen and (max-width: 767px) {
    .header-four .header-top-link {
        font-size: 14px;
    }
}

.header-four .header-top span {
    border-right: 1px solid rgba(113, 119, 136, 0.5);
    margin-right: 20px;
    padding-right: 20px;
    line-height: 0.3;
    display: flex;
    align-items: center;
    -moz-column-gap: 3px;
    column-gap: 3px;
}

.header-four .header-top span:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
}

.header-four .header-top .social-icon {
    display: flex;
}

.header-four .header-top .social-icon li a {
    font-size: 16px;
}

.header-four .btn-style-one {
    height: 54px;
    background: linear-gradient(-50deg, #5bcff2 15%, #417dd2 47%, #322fb3 80%);
}

@media only screen and (max-width: 767px) {
    .header-four .btn-style-one {
        height: auto;
    }
}

.header-four .main-menu>ul>li>a {
    line-height: 83px;
}

.header-four.sticky .header-top {
    display: none;
}


/* =============================
    2.2 - Slider Style
================================ */


/* Slider One Animation Start */

.animation-style-01 .swiper-slide-active .slider-content .subtitle {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.01s;
    animation-delay: 0.01s;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .slider-content .title {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .slider-content .btn-style-two {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .sliderone-social li:nth-child(1) {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.01s;
    animation-delay: 0.01s;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .sliderone-social li:nth-child(2) {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .sliderone-social li:nth-child(3) {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.06s;
    animation-delay: 0.06s;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .sliderone-social li:nth-child(4) {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .slider-image.slide-two-image {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-01 .swiper-slide-active .slider-image .shape-circle {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


/* Slider Two Animation Start */

.animation-style-02 .swiper-slide-active .heading-one-subtitle {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.01s;
    animation-delay: 0.01s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .heading-one-title {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .heading-one p {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.06s;
    animation-delay: 0.06s;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .heading-one .btn-style-one {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-leftimg .img-one {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-delay: 0.01s;
    animation-delay: 0.01s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-leftimg .img-two {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-rightimg .img-one {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-delay: 0.01s;
    animation-delay: 0.01s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-rightimg .img-two {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-rightimg .img-three {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-shape1 .shape-7 img {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-shape2 .shape-6 img {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-shape2 .shape-8 img {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animation-style-02 .swiper-slide-active .slidertwo-shape3 .shape-1 img {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


/* Slider One Style Start */

.sliderone {
    position: relative;
    margin: 0 -100px;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderone {
        margin: 0 -80px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .sliderone {
        margin: 0 -30px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1299px) {
    .sliderone {
        margin: 0;
    }
}

.sliderone .swiper-slide {
    padding: 0 100px;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderone .swiper-slide {
        padding: 0 80px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .sliderone .swiper-slide {
        padding: 0 30px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1299px) {
    .sliderone .swiper-slide {
        padding: 0;
    }
}

.sliderone-slingle {
    height: 960px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderone-slingle {
        height: 880px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .sliderone-slingle {
        height: 874px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .sliderone-slingle {
        height: 870px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sliderone-slingle {
        height: 794px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-slingle {
        height: 625px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-slingle {
        height: 550px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-slingle {
        height: 800px;
    }
}

@media only screen and (max-width: 479px) {
    .sliderone-slingle {
        height: 720px;
    }
}

.sliderone-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .sliderone-content {
        align-items: flex-start;
        padding-top: 60px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-content {
        flex-direction: column;
        padding-top: 60px;
    }
}

@media only screen and (max-width: 479px) {
    .sliderone-content {
        padding-top: 60px;
    }
}

.sliderone-content .slider-content {
    flex: 0 0 auto;
    width: 42%;
    padding: 0 15px;
    z-index: 3;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderone-content .slider-content {
        width: 47%;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .sliderone-content .slider-content {
        width: 50%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1299px) {
    .sliderone-content .slider-content {
        width: 50%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .sliderone-content .slider-content {
        width: 60%;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-content .slider-content {
        width: 65%;
    }
}

@media only screen and (max-width: 479px) {
    .sliderone-content .slider-content {
        width: 100%;
    }
}

.sliderone-content .slider-content .subtitle {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    display: flex;
    margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sliderone-content .slider-content .subtitle {
        font-size: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-content .slider-content .subtitle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .slider-content .subtitle {
        font-size: 18px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-content .slider-content .subtitle {
        font-size: 16px;
    }
}

.sliderone-content .slider-content .title {
    font-size: 72px;
    font-weight: 700;
    color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sliderone-content .slider-content .title {
        font-size: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-content .slider-content .title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .slider-content .title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-content .slider-content .title {
        font-size: 36px;
    }
}

.sliderone-content .slider-content .call {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-content .slider-content .call {
        position: relative;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .slider-content .call {
        position: relative;
        margin-top: 30px;
        bottom: 0px;
    }
}

.sliderone-content .slider-content .call span {
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .sliderone-content .slider-content .call span {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .slider-content .call span {
        font-size: 14px;
    }
}

.sliderone-content .slider-content .call a {
    color: #ffffff;
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    margin-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-content .slider-content .call a {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .slider-content .call a {
        font-size: 18px;
    }
}

.sliderone-content .slider-image {
    flex: 0 0 auto;
    width: 50%;
    padding: 0 15px;
    position: relative;
    z-index: 2;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderone-content .slider-image {
        width: 47%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .sliderone-content .slider-image {
        width: 50%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .sliderone-content .slider-image {
        width: 40%;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-content .slider-image {
        width: 100%;
    }
}

.sliderone-content .slider-image-one {
    margin-left: -300px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-content .slider-image-one {
        margin-left: -200px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .slider-image-one {
        margin-left: -135px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone-content .slider-image-one {
        margin-left: 0;
        margin-top: 30px;
    }
}

.sliderone-content .btn-style-two {
    margin-top: 40px;
    display: inline-flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone-content .btn-style-two {
        padding: 20px 40px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone-content .btn-style-two {
        padding: 18px 35px;
        margin-top: 30px;
    }
}

.sliderone-content .btn-style-two:hover {
    background-color: #ffffff;
    color: #343c55;
}

.sliderone .swiper-pagination {
    position: absolute;
    right: 50px !important;
    left: auto !important;
    width: 10px !important;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderone .swiper-pagination {
        right: 40px !important;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .sliderone .swiper-pagination {
        right: 20px !important;
    }
}

@media only screen and (min-width: 130px) and (max-width: 1299px) {
    .sliderone .swiper-pagination {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .sliderone .swiper-pagination {
        display: flex;
        left: 50% !important;
        transform: translate(-50%, -50%);
        position: relative;
        display: flex !important;
        flex-direction: row;
        -moz-column-gap: 20px;
        column-gap: 20px;
        width: 100% !important;
        bottom: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .sliderone .swiper-pagination {
        -moz-column-gap: 10px;
        column-gap: 10px;
        bottom: 50px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone .swiper-pagination {
        bottom: 0px;
    }
}

@media only screen and (max-width: 479px) {
    .sliderone .swiper-pagination {
        bottom: 0px;
    }
}

.sliderone .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    background: #322fb3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone .swiper-pagination-bullet {
        background: #ffffff;
    }
}

@media only screen and (max-width: 767px) {
    .sliderone .swiper-pagination-bullet {
        background: #ffffff;
        width: 10px;
        height: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderone .swiper-pagination-bullet {
        background: #322fb3;
    }
}

.sliderone .swiper-pagination-bullet-active {
    position: relative;
}

.sliderone .swiper-pagination-bullet-active::before {
    content: "";
    position: absolute;
    border: 2px solid #d5312c;
    height: 22px;
    width: 22px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
}

@media only screen and (max-width: 767px) {
    .sliderone .swiper-pagination-bullet-active::before {
        width: 18px;
        height: 18px;
    }
}

.sliderone .button-container {
    position: absolute;
    bottom: 0px;
    right: 0;
}

.sliderone .swiper-button {
    position: relative;
    left: auto;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderone .swiper-button {
        display: none;
    }
}

.sliderone .swiper-button-prev,
.sliderone .swiper-button-next {
    height: 60px;
    width: 60px;
    background-color: transparent;
    border: 1px solid #d5312c;
    border-radius: 7px;
    transition: 0.3s;
    position: relative;
    left: 0;
    right: 0;
}

.sliderone .swiper-button-prev::after,
.sliderone .swiper-button-next::after {
    display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .sliderone .swiper-button-prev,
    .sliderone .swiper-button-next {
        height: 50px;
        width: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .sliderone .swiper-button-prev,
    .sliderone .swiper-button-next {
        height: 40px;
        width: 40px;
    }
}

.sliderone .swiper-button-prev:hover,
.sliderone .swiper-button-next:hover {
    background-color: #d5312c;
    border: 1px solid #d5312c;
    opacity: 1;
    transition: 0.3s;
}

.sliderone .swiper-button .prev-arrow-1,
.sliderone .swiper-button .next-arrow-1,
.sliderone .swiper-button .prev-arrow-2,
.sliderone .swiper-button .next-arrow-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.sliderone .swiper-button .next-arrow-2 {
    transform: rotate(180deg) translate(50%, 40%);
    opacity: 0;
}

.sliderone .swiper-button-next:hover .next-arrow-1 {
    opacity: 0;
}

.sliderone .swiper-button-next:hover .next-arrow-2 {
    opacity: 1;
}

.sliderone .swiper-button .prev-arrow-1 {
    transform: rotate(-180deg) translate(50%, 40%);
}

.sliderone .swiper-button .prev-arrow-2 {
    opacity: 0;
}

.sliderone .swiper-button-prev:hover .prev-arrow-1 {
    opacity: 0;
}

.sliderone .swiper-button-prev:hover .prev-arrow-2 {
    opacity: 1;
}

.sliderbg-1 {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    height: 960px;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderbg-1 {
        width: 46%;
        height: 880px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .sliderbg-1 {
        width: 41%;
        height: 874px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
    .sliderbg-1 {
        width: 38%;
        height: 874px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .sliderbg-1 {
        height: 870px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sliderbg-1 {
        height: 794px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1299px) {
    .sliderbg-1 {
        width: 30%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sliderbg-1 {
        width: 100%;
        height: 625px;
    }
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 {
        height: 550px;
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .sliderbg-1 {
        height: 625px;
    }
}

.sliderbg-1 .shape {
    position: absolute;
    opacity: 0.6;
}

.sliderbg-1 .shape-1 {
    top: 13%;
    left: 6%;
    z-index: 1;
    -webkit-animation: animshape1 5s infinite linear;
    animation: animshape1 5s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-1 {
        display: none;
    }
}

.sliderbg-1 .shape-2 {
    top: 19%;
    left: 74%;
    z-index: 1;
    -webkit-animation: animshape1 6s infinite linear;
    animation: animshape1 6s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-2 {
        display: none;
    }
}

.sliderbg-1 .shape-3 {
    top: 22%;
    left: 27%;
    z-index: 1;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-3 {
        display: none;
    }
}

.sliderbg-1 .shape-4 {
    top: 33%;
    left: 18%;
    z-index: 1;
    -webkit-animation: circle1 2.2s infinite alternate;
    animation: circle1 2.2s infinite alternate;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-4 {
        display: none;
    }
}

.sliderbg-1 .shape-5 {
    top: 49%;
    left: 13%;
    z-index: 1;
    -webkit-animation: circle2 2.2s infinite linear;
    animation: circle2 2.2s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-5 {
        display: none;
    }
}

.sliderbg-1 .shape-6 {
    top: 52%;
    left: 28%;
    z-index: 1;
    -webkit-animation: animshape2 5s infinite linear;
    animation: animshape2 5s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-6 {
        display: none;
    }
}

.sliderbg-1 .shape-7 {
    top: 79%;
    left: 75%;
    z-index: 1;
    -webkit-animation: circle2 2.2s infinite linear;
    animation: circle2 2.2s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-7 {
        display: none;
    }
}

.sliderbg-1 .shape-8 {
    top: 71%;
    left: 69%;
    z-index: 1;
    -webkit-animation: circle1 2.2s infinite alternate;
    animation: circle1 2.2s infinite alternate;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-8 {
        display: none;
    }
}

.sliderbg-1 .shape-9 {
    top: 92%;
    left: 9%;
    z-index: 1;
    -webkit-animation: animshape1 5s infinite linear;
    animation: animshape1 5s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-9 {
        display: none;
    }
}

.sliderbg-1 .shape-10 {
    top: 94%;
    left: 73%;
    z-index: 1;
    -webkit-animation: animshape1 5s infinite linear;
    animation: animshape1 5s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-10 {
        display: none;
    }
}

.sliderbg-1 .shape-11 {
    top: 78%;
    left: 18%;
    z-index: 1;
    -webkit-animation: animshape2 5s infinite linear;
    animation: animshape2 5s infinite linear;
}

@media only screen and (max-width: 767px) {
    .sliderbg-1 .shape-11 {
        display: none;
    }
}

.sliderbg-1 .sliderone-social {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    row-gap: 30px;
}

@media only screen and (min-width: 1550px) and (max-width: 1699px) {
    .sliderbg-1 .sliderone-social {
        left: 15px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1549px) {
    .sliderbg-1 .sliderone-social {
        left: 15px;
    }
}

@media only screen and (min-width: 130px) and (max-width: 1299px) {
    .sliderbg-1 .sliderone-social {
        display: none;
    }
}

.sliderbg-1 .sliderone-social li a {
    color: #ffffff;
    font-size: 24px;
}

.sliderbg-1 .sliderone-social li a:hover {
    color: #d5312c;
}


/* Slider Two Style Start */

.slider-two {
    height: 1000px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slider-two {
        height: 100vh;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-two {
        height: 800px;
    }
}

.slider-two .heading-one {
    position: relative;
    z-index: 9999;
}

.slider-two .heading-one-subtitle {
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .slider-two .heading-one-subtitle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-two .heading-one-subtitle {
        font-size: 18px;
    }
}

.slider-two .heading-one-title {
    font-size: 72px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slider-two .heading-one-title {
        font-size: 60px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slider-two .heading-one-title {
        font-size: 60px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .slider-two .heading-one-title {
        font-size: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-two .heading-one-title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-two .heading-one-title {
        font-size: 40px;
    }
}

.slider-two .heading-one p {
    font-size: 21px;
    margin: 30px 0 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slider-two .heading-one p {
        font-size: 18px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-two .heading-one p {
        font-size: 16px;
        margin: 20px 0 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .slider-two .heading-one p {
        font-size: 16px;
        margin: 10px 0 30px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-two .heading-one p {
        margin: 10px 0 20px;
    }
}

.slider-two .heading-one .btn-style-one {
    display: inline-block;
    padding: 25px 60px;
    font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-two .heading-one .btn-style-one {
        padding: 22px 50px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-two .heading-one .btn-style-one {
        padding: 18px 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-two {
        height: 945px;
    }

    .slider-two .heading-one {
        margin: 190px 0 50px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-two {
        height: 850px;
    }

    .slider-two .heading-one {
        margin: 130px 0 50px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-two {
        height: 750px;
    }

    .slider-two .heading-one {
        margin: 150px 0 50px;
    }
}

@media only screen and (max-width: 479px) {
    .slider-two {
        height: 660px;
    }

    .slider-two .heading-one {
        margin: 150px 0 50px;
    }
}

.slidertwo {
    height: 1000px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo {
        height: 100vh;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo {
        height: 800px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .slidertwo {
        height: 100%;
    }
}

.slidertwo .swiper-slide {
    display: flex;
    align-items: center;
}

.slidertwo-shape1 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.slidertwo-shape1 .scene {
    width: 100%;
    height: 100%;
}

.slidertwo-shape1 .shape {
    position: absolute;
}

.slidertwo-shape1 .shape.shape-7 {
    top: 0;
    right: 20%;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-shape1 .shape.shape-7 {
        right: 8%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-7 {
        right: 8%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-shape1 .shape.shape-7 {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-7 {
        right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-7 img {
        width: 80%;
    }
}

.slidertwo-shape1 .shape.shape-9 {
    left: 445px;
    top: 20%;
    z-index: 1;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-shape1 .shape.shape-9 {
        left: 360px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-shape1 .shape.shape-9 {
        left: 340px;
        top: 24%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape1 .shape.shape-9 {
        left: 20%;
        top: 22%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-9 {
        top: 18%;
        left: 24%;
        width: 5%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-9 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-10 {
    left: 32.6%;
    top: 32.5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-10 {
        left: 33%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-10 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-11 {
    top: 23.6%;
    right: 38%;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-11 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-12 {
    top: 12%;
    right: 3%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-12 {
        top: 16%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-12 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-14 {
    top: 22%;
    right: 8.6%;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape1 .shape.shape-14 {
        right: 6%;
        top: 25%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-14 {
        right: 7%;
        top: 28%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-14 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-15 {
    right: 30%;
    top: 39%;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-15 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-16 {
    right: 22%;
    top: 53%;
    z-index: 1;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-shape1 .shape.shape-16 {
        right: 24%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-shape1 .shape.shape-16 {
        right: 23%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape1 .shape.shape-16 {
        right: 26%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-16 {
        right: 28%;
        width: 6%;
        top: 56%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-16 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-17 {
    right: 28%;
    bottom: 30%;
    -webkit-animation: circle1 3s infinite linear;
    animation: circle1 3s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-shape1 .shape.shape-17 {
        right: 30%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-shape1 .shape.shape-17 {
        right: 32%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape1 .shape.shape-17 {
        right: 35%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-17 {
        right: 38%;
        bottom: 26%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-17 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-18 {
    left: 40%;
    bottom: 17%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-18 {
        left: 35%;
        bottom: 28%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-18 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-19 {
    left: 7.8%;
    bottom: 15.2%;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-19 {
        left: 5%;
        bottom: 28%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-19 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-20 {
    left: 14%;
    bottom: 3%;
    z-index: -1;
    -webkit-animation: moveleftright1 3s infinite linear;
    animation: moveleftright1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-20 {
        left: 5%;
        bottom: 20%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-20 {
        display: none;
    }
}

.slidertwo-shape1 .shape.shape-21 {
    right: 23%;
    bottom: 8%;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-shape1 .shape.shape-21 {
        right: 27%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-shape1 .shape.shape-21 {
        right: 27%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape1 .shape.shape-21 {
        right: 32%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape1 .shape.shape-21 {
        right: 36%;
        bottom: 6%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape1 .shape.shape-21 {
        display: none;
    }
}

.slidertwo-shape2 .shape {
    position: absolute;
}

.slidertwo-shape2 .shape.shape-6 {
    top: 0;
    left: 0px;
    z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape2 .shape.shape-6 img {
        width: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-shape2 .shape.shape-6 {
        display: none;
    }
}

.slidertwo-shape2 .shape.shape-8 {
    right: 0;
    bottom: 5%;
    z-index: -1;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-shape2 .shape.shape-8 {
        width: 28%;
        bottom: 3%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-shape2 .shape.shape-8 {
        width: 30%;
        bottom: 3%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape2 .shape.shape-8 {
        width: 32%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape2 .shape.shape-8 {
        width: 35%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape2 .shape.shape-8 {
        width: 31%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-shape2 .shape.shape-8 {
        display: none;
    }
}

.slidertwo-shape2 .shape.shape-13 {
    top: 26%;
    right: 11.6%;
    z-index: 6;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-shape2 .shape.shape-13 {
        top: 28%;
        right: 10%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-shape2 .shape.shape-13 {
        top: 24%;
        right: 12%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape2 .shape.shape-13 {
        display: none;
    }
}

.slidertwo-shape3 .shape {
    position: absolute;
}

.slidertwo-shape3 .shape.shape-1 {
    left: 0;
    top: 50%;
    z-index: 999;
    transform: translateY(-40%);
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-shape3 .shape {
        display: none;
    }
}

.slidertwo-leftimg {
    margin-left: -12px;
}

.slidertwo-leftimg .img-one {
    position: relative;
    margin-left: 0px;
    top: -35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-leftimg .img-one {
        position: relative;
        width: 100%;
        left: 0%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-leftimg .img-one {
        position: relative;
        width: 95%;
        left: 0%;
        top: -60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-leftimg .img-one {
        top: 0px;
        width: 85%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-leftimg .img-one {
        top: 0px;
        width: 90%;
    }
}

.slidertwo-leftimg .img-two {
    position: absolute;
    bottom: 6%;
    left: 17.4%;
    z-index: 99;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-leftimg .img-two {
        left: 15%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-leftimg .img-two {
        left: 12%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-leftimg .img-two {
        bottom: 24%;
        left: 14%;
        width: 18%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-leftimg .img-two {
        bottom: 24%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-leftimg .img-two {
        position: relative;
        bottom: 100px;
        left: 68%;
        width: 34%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-leftimg .img-two {
        position: relative;
        bottom: 70px;
        left: 60%;
        width: 34%;
    }
}

@media only screen and (max-width: 479px) {
    .slidertwo-leftimg .img-two {
        bottom: 50px;
    }
}

.slidertwo-rightimg {
    text-align: right;
    margin-right: -12px;
}

.slidertwo-rightimg .img-one {
    position: absolute;
    bottom: 2px;
    right: 0;
    z-index: 8;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-rightimg .img-one {
        bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-rightimg .img-one {
        bottom: 35px;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .slidertwo-rightimg .img-one img {
        width: 96%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .slidertwo-rightimg .img-one img {
        width: 90%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-rightimg .img-one img {
        width: 85%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-rightimg .img-one img {
        width: 76%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-rightimg .img-one img {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-rightimg .img-one img {
        width: 45%;
    }
}

@media only screen and (max-width: 575px) {
    .slidertwo-rightimg .img-one img {
        width: 40%;
    }
}

.slidertwo-rightimg .img-two {
    position: absolute;
    right: 17%;
    top: 22%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-rightimg .img-two {
        right: 20%;
        top: 18%;
        width: 14%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-rightimg .img-two {
        position: absolute;
        right: 30%;
        top: 52%;
        width: 15%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-rightimg .img-two {
        top: 55%;
        width: 18%;
        right: 30%;
    }
}

@media only screen and (max-width: 575px) {
    .slidertwo-rightimg .img-two {
        top: 58%;
        right: 35%;
    }
}

@media only screen and (max-width: 479px) {
    .slidertwo-rightimg .img-two {
        top: 62%;
        right: 35%;
    }
}

.slidertwo-rightimg .img-three {
    position: absolute;
    right: 31%;
    bottom: 11%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .slidertwo-rightimg .img-three {
        right: 35%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slidertwo-rightimg .img-three {
        width: 10%;
        right: 40%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slidertwo-rightimg .img-three {
        bottom: 5%;
        width: 10%;
        right: 36%;
    }
}

@media only screen and (max-width: 767px) {
    .slidertwo-rightimg .img-three {
        bottom: 6%;
        width: 10%;
        right: 36%;
    }
}

.slidertwo .swiper-pagination {
    bottom: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .slidertwo .swiper-pagination {
        bottom: 0px;
        line-height: 1;
    }
}

.slidertwo .swiper-pagination-bullet {
    width: 40px;
    border-radius: 5px;
    height: 6px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

@media only screen and (max-width: 767px) {
    .slidertwo .swiper-pagination-bullet {
        width: 30px;
    }
}

.slidertwo .swiper-pagination-bullet-active {
    background: #322fb3;
}


/* Hero Style Start */

.hero {
    height: 100vh;
    /* padding-top: 140px; */
    overflow: hidden;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero {
        height: 950px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero {
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .hero {
        padding-top: 115px;
    }
}

@media only screen and (max-width: 575px) {
    .hero {
        padding-top: 88px;
    }
}

.hero-content {
    position: relative;
    top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-content {
        top: 20px;
    }
}

.hero-content .subtitle {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    color: #d5312c;
    display: flex;
    margin-bottom: 5px;
    letter-spacing: 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content .subtitle {
        font-size: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .subtitle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-content .subtitle {
        font-size: 18px;
    }
}

@media only screen and (max-width: 575px) {
    .hero-content .subtitle {
        font-size: 16px;
    }
}

.hero-content .title {
    font-size: 72px;
    font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content .title {
        font-size: 30px;
    }

    .hero-content .text {
        font-size: 14px !important;
        color: #717788;
        margin: 12px 0 5px !important;
    }

    .hero .btn-style-one {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-content .title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .hero-content .title {
        font-size: 36px;
    }
}

.hero-content .text {
    font-size: 21px;
    color: #717788;
    margin: 45px 0 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .text {
        margin: 30px 0 0px;
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-content .text {
        margin: 9px 0 0px;
        font-size: 16px;
    }

    .heading-one-title {
        font-size: 1.3rem !important;
        font-weight: 500;
        color: #343c55;
        letter-spacing: 2px !important;
    }
}

.hero-content .btn-style-one {
    display: inline-block;
    padding: 25px 64px;
}

.hero .btn-style-one {
    margin-top: 40px;
    display: inline-flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero .btn-style-one {
        padding: 20px 40px;
    }
}

@media only screen and (max-width: 767px) {
    .hero .btn-style-one {
        padding: 18px 35px;
        margin-top: 25px;
    }
}

.hero .btn-style-one:hover {
    background-color: #ffffff;
    color: #343c55;
}

.hero-facts-info {
    position: absolute;
    z-index: 3;
    background: #ffffff;
    box-shadow: -14px 22px 48px 0px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 10px;
    max-width: 294px;
    width: 100%;
    padding: 35px;
    top: 210px;
    left: -40px;
    visibility: visible;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

.hero-facts-info-review {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.hero-facts-info-review span {
    color: #f2c71b;
    line-height: 1;
}

.hero-facts-info-text {
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-style: italic;
    margin: 5px 0px 0px;
}

@media only screen and (max-width: 479px) {
    .hero-facts-info-text {
        font-size: 12px;
        margin-top: 0;
    }
}

.hero-facts-info .hero-facts-client-name {
    text-transform: uppercase;
    line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-facts-info {
        padding: 25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-facts-info {
        top: 72%;
        left: -20px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-facts-info {
        top: 76%;
        left: -20px;
        max-width: 240px;
        padding: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .hero-facts-info {
        left: 0;
    }
}

@media only screen and (max-width: 479px) {
    .hero-facts-info {
        max-width: 180px;
        padding: 15px;
        left: -5px;
    }
}

.hero-facts-client {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    background: #ffffff;
    padding: 25px 25px 20px;
    box-shadow: 15px 11px 48px 0px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    right: -38%;
    top: 90%;
    visibility: visible;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

.hero-facts-client-name {
    font-size: 14px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-facts-client {
        right: -190px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-facts-client {
        right: -100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-facts-client {
        right: -6%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-facts-client {
        right: -4%;
        top: 90px;
        padding: 20px 20px 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-facts-client {
        right: 0;
        top: 50%;
        padding: 20px 20px 15px;
    }
}

@media only screen and (max-width: 575px) {
    .hero-facts-client {
        right: 0;
        top: 54%;
        padding: 15px 15px 10px;
    }
}

.hero-facts-shape1 {
    position: absolute;
    margin-left: 30px;
    top: -194px;
    right: -240px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-facts-shape1 {
        right: -190px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-facts-shape1 {
        right: -100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-facts-shape1 {
        right: -35px;
        top: -130px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-facts-shape1 {
        right: -15px;
        top: -90px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-facts-shape1 {
        top: 90px;
        right: auto;
    }
}

.hero-facts-shape2 {
    position: absolute;
    z-index: 2;
    top: -150px;
    left: 12%;
}

.hero-facts-shape2 img {
    -webkit-animation: zoom1 5s infinite linear;
    animation: zoom1 5s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-facts-shape2 {
        top: -190px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-facts-shape2 {
        left: 10%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-facts-shape2 {
        top: -100px;
        left: 8%;
    }

    .hero-facts-shape2 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-facts-shape2 {
        top: 14%;
        left: 8%;
    }
}

@media only screen and (max-width: 575px) {
    .hero-facts-shape2 img {
        width: 80%;
    }
}

@media only screen and (max-width: 479px) {
    .hero-facts-shape2 {
        top: 18%;
    }

    .hero-facts-shape2 img {
        width: 60%;
    }
}

.hero-facts-shape3 {
    position: absolute;
    z-index: 2;
    background: #ffffff;
    padding: 20px;
    box-shadow: 15px 11px 48px 0px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    right: -9%;
    top: -192px;
    visibility: visible;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-facts-shape3 {
        right: -3%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-facts-shape3 {
        right: 7%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-facts-shape3 {
        right: 15%;
        top: -140px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-facts-shape3 {
        right: 15%;
        top: -90px;
        padding: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-facts-shape3 {
        right: 20%;
        top: 13%;
    }
}

@media only screen and (max-width: 575px) {
    .hero-facts-shape3 {
        right: 12%;
        top: 12%;
        padding: 15px;
    }
}

.hero-facts-shape4 {
    position: absolute;
    z-index: 2;
    left: 43%;
    top: 420px;
}

.hero-facts-shape4 img {
    -webkit-animation: moveleftright1 5s infinite linear;
    animation: moveleftright1 5s infinite linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-facts-shape4 {
        top: 100%;
        left: 40%;
    }
}

@media only screen and (max-width: 767px) {
    .hero-facts-shape4 {
        display: none;
    }
}

.hero-image {
    position: absolute;
    top: -250px;
    right: -210px;
}

.hero-image-wrapper {
    position: relative;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-image {
        right: -180px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-image {
        right: -110px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-image {
        right: -20px;
        top: -180px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-image {
        right: -10px;
        top: -120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-image {
        position: relative;
        top: 40px;
        right: auto;
    }
}

.hero .scene {
    height: 100%;
    position: absolute !important;
    width: 100%;
}

.hero-shape.shape-1 {
    position: absolute;
    top: 100px;
    left: -15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-1 {
        top: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-1 {
        display: none;
    }
}

.hero-shape.shape-2 {
    position: absolute;
    top: 100px;
    left: -15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-2 {
        top: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-2 {
        display: none;
    }
}

.hero-shape.shape-3 {
    position: absolute;
    top: 25px;
    left: 50px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-shape.shape-3 {
        left: 2%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-shape.shape-3 {
        top: 0px;
        left: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-3 {
        display: none;
    }
}

.hero-shape.shape-4 {
    position: absolute;
    top: 60px;
    left: 125px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-shape.shape-4 {
        left: 4%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-shape.shape-4 {
        left: 15px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-4 {
        display: none;
    }
}

.hero-shape.shape-5 {
    position: absolute;
    top: 280px;
    left: 220px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-shape.shape-5 {
        left: 9%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-shape.shape-5 {
        left: 60px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-5 {
        display: none;
    }
}

.hero-shape.shape-6 {
    position: absolute;
    top: 660px;
    left: 315px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-shape.shape-6 {
        left: 20%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-6 {
        left: 22%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-6 {
        display: none;
    }
}

.hero-shape.shape-7 {
    position: absolute;
    top: 100px;
    left: 43%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-7 {
        top: 80px;
        left: 40%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-7 {
        display: none;
    }
}

.hero-shape.shape-8 {
    position: absolute;
    top: 80px;
    left: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-8 {
        top: 50px;
        left: 52%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-8 {
        display: none;
    }
}

.hero-shape.shape-9 {
    position: absolute;
    top: 42%;
    left: 44%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-9 {
        display: none;
    }
}

.hero-shape.shape-10 {
    position: absolute;
    top: 62%;
    left: 33%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-10 {
        left: 28%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-10 {
        display: none;
    }
}

.hero-shape.shape-12 {
    position: absolute;
    top: 0%;
    right: 8%;
}

.hero-shape.shape-12 img {
    -webkit-animation: circle2 5s infinite linear;
    animation: circle2 5s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-shape.shape-12 {
        right: 7%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-12 {
        display: none;
    }
}

.hero-shape.shape-13 {
    position: absolute;
    top: 14%;
    right: 5%;
}

.hero-shape.shape-13 img {
    -webkit-animation: zoom1 5s infinite linear;
    animation: zoom1 5s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-shape.shape-13 {
        right: 1%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-shape.shape-13 {
        top: 12%;
        right: 1%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-shape.shape-13 {
        right: 2%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-13 {
        top: 10%;
        right: 1%;
    }

    .hero-shape.shape-13 img {
        width: 70%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-13 {
        display: none;
    }
}

.hero-shape.shape-14 {
    position: absolute;
    top: 0;
    right: 3%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-14 {
        display: none;
    }
}

.hero-shape.shape-15 {
    position: absolute;
    top: 61%;
    right: 2%;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .hero-shape.shape-15 {
        top: 76%;
        right: 1%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .hero-shape.shape-15 {
        top: 70%;
        right: 1%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-shape.shape-15 {
        top: 70%;
        right: 1%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-shape.shape-15 {
        top: 65%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .hero-shape.shape-15 {
        display: none;
    }
}


/* =============================
    2.3 - Strategy Style
================================ */

.strategy {
    position: relative;
}

.strategy .heading-one {
    max-width: 800px;
    width: 100%;
    margin: 0 auto 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .strategy .heading-one {
        margin: 0 auto 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy .heading-one {
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .strategy .heading-one {
        margin: 0 auto 40px;
    }
}

.strategy-single {
    display: flex;
    align-items: flex-start;
}

.strategy-icon {
    flex: 0 0 auto;
    margin-right: 50px;
    position: relative;
}

.strategy-icon span {
    content: "";
    position: absolute;
    background-color: #def6fe;
    height: 48px;
    width: 48px;
    border-radius: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.strategy-icon img {
    position: relative;
    top: 20px;
    left: 20px;
}

.strategy-content .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .strategy-content .title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .strategy-content .title {
        margin-bottom: 15px;
        font-size: 20px;
    }
}

.strategy-shape {
    position: absolute;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy-shape {
        display: none;
    }
}

.strategy-shape.shape-1 {
    top: 70px;
    left: 135px;
    -webkit-animation: zoom1 4s infinite linear;
    animation: zoom1 4s infinite linear;
}

.strategy-shape.shape-2 {
    bottom: 100px;
    right: 20px;
    -webkit-animation: circle1 2.2s infinite alternate;
    animation: circle1 2.2s infinite alternate;
}

.strategy-shape.shape-3 {
    top: 150px;
    right: 100px;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

.strategy-shape.shape-4 {
    top: 115px;
    right: 20px;
    -webkit-animation: moveupdown1 2s infinite linear;
    animation: moveupdown1 2s infinite linear;
}

.strategy-shape.shape-5 {
    top: 70px;
    left: 80px;
    opacity: 0.6;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

.strategy-two .heading-one {
    margin-right: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .strategy-two .heading-one {
        margin-right: 80px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .strategy-two .heading-one {
        margin-right: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .strategy-two .heading-one {
        margin-right: 0px;
    }
}

.strategy-two .heading-one-title {
    margin: 5px 0 40px;
}

@media only screen and (max-width: 767px) {
    .strategy-two .heading-one-title {
        margin: 5px 0 30px;
    }
}

.strategy-two-inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy-two-inner {
        gap: 30px;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .strategy-two-inner {
        gap: 30px;
        margin-top: 40px;
    }
}

.strategy-two-single {
    display: flex;
    -moz-column-gap: 30px;
    column-gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy-two-single {
        max-width: 333px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .strategy-two-single {
        max-width: 100%;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
}

.strategy-two-icon {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(50, 47, 179, 0.2);
    background: linear-gradient(-45deg, rgba(91, 207, 242, 0.2) 0%, rgba(65, 125, 210, 0.2) 50%, rgba(50, 47, 179, 0.2) 100%);
    flex: 0 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .strategy-two-icon {
        width: 80px;
        height: 80px;
    }
}

.strategy-two-content .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .strategy-two-content .title {
        font-size: 22px;
        margin-bottom: 15px;
    }
}

.strategy-home2-shape {
    position: absolute;
    left: 0;
    top: 28%;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy-home2-shape {
        top: 26%;
    }
}

.strategy-home2-shape .shape {
    position: absolute;
}

.strategy-home2-shape .shape.shape-1 {
    right: -10px;
    top: 30px;
    -webkit-animation: moveleftright1 3s infinite linear;
    animation: moveleftright1 3s infinite linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy-home2-shape .shape.shape-1 {
        right: -50px;
        top: 27%;
    }

    .strategy-home2-shape .shape.shape-1 img {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .strategy-home2-shape .shape.shape-1 {
        display: none;
    }
}

.strategy-home2-shape .shape.shape-2 {
    left: 80px;
    top: 28%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .strategy-home2-shape .shape.shape-2 {
        left: 20px;
        top: 24%;
    }

    .strategy-home2-shape .shape.shape-2 img {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .strategy-home2-shape .shape.shape-2 {
        display: none;
    }
}


/* =============================
    2.4 - About Style
================================ */

.about {
    /* background-image: url(../public/assets/images/about/about-bg.png); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 190px;
    margin-bottom: -1px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about {
        padding-bottom: 160px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about {
        padding-bottom: 90px;
        background-size: contain;
        background-position: bottom;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about .heading-one-title {
        font-size: 50px;
    }
}

.about-details {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.about-details .col-one {
    flex: 0 0 auto;
    width: 48%;
    margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 767px) {
    .about-details .col-one {
        width: 100%;
        margin: 0px 0 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-details .col-one {
        width: 46%;
    }
}

.about-details .col-two {
    flex: 0 0 auto;
    width: 26%;
    margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-details .col-two {
        width: 43%;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-details .col-two {
        width: 40%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-details .col-two {
        width: 28%;
    }
}

@media only screen and (max-width: 575px) {
    .about-details .col-two {
        width: 58%;
    }
}

.about-details .col-three {
    flex: 0 0 auto;
    width: 140px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-details .col-three {
        width: 130px;
    }
}

@media only screen and (max-width: 479px) {
    .about-details .col-three {
        width: 120px;
    }
}

.about-details-quote {
    background-color: #ffffff;
    position: relative;
    border-radius: 0 7px 7px 0;
    padding: 15px 35px 10px 22px;
}

.about-details-quote::after {
    position: absolute;
    content: "";
    background-color: #d5312c;
    height: 95%;
    width: 4px;
    left: 0;
    top: 0;
}

.about-details-quote p {
    position: relative;
    top: -20px;
    left: 26px;
    font-style: italic;
}

.about-details-list li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.about-details-list li p {
    margin-left: 15px;
}

.about-details .btn-style-one {
    display: inline-block;
    margin-top: 40px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
}

@media only screen and (max-width: 767px) {
    .about-details .btn-style-one {
        margin-top: 20px;
    }
}

.about-details-video {
    position: relative;
}

.about-details-video::after {
    position: absolute;
    content: "";
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    border-radius: 14px;
    transition: 0.4s;
}

.about-details-video::before {
    position: absolute;
    content: "";
    background: #322fb3;
    background: linear-gradient(-45deg, #322fb3 20%, #417dd2 50%, #5bcff2 100%);
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 14px;
    transition: 0.4s;
}

.about-details-video .icon-img {
    width: 100%;
}

.about-details-video .video-popup {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about-details-video:hover::after {
    opacity: 0;
    transition: 0.4s;
}

.about-details-video:hover::before {
    opacity: 0.8;
    transition: 0.4s;
}

.about .about-counter {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    margin-bottom: 115px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about .about-counter {
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about .about-counter {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about .about-counter {
        margin-bottom: 0;
        position: absolute;
        top: -108%;
        right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .about .about-counter {
        margin-bottom: 0;
        position: absolute;
        top: -142%;
        right: 0;
    }
}

@media only screen and (max-width: 575px) {
    .about .about-counter {
        margin-bottom: 0;
        position: absolute;
        top: -2%;
        right: 20px;
        gap: 10px;
    }
}

@media only screen and (max-width: 479px) {
    .about .about-counter {
        right: 10px;
    }
}

.about .about-counter .funfact-inner {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 575px) {
    .about .about-counter .funfact-inner {
        margin-bottom: 5px;
        line-height: 1;
    }
}

.about .about-counter-1,
.about .about-counter-2 {
    padding: 45px 50px 55px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    border-radius: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .about .about-counter-1,
    .about .about-counter-2 {
        padding: 30px 30px 38px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {

    .about .about-counter-1,
    .about .about-counter-2 {
        padding: 10px 15px;
    }
}

@media only screen and (max-width: 575px) {

    .about .about-counter-1,
    .about .about-counter-2 {
        justify-content: space-between;
        padding: 10px;
    }
}

.about .about-counter-1 .odometer,
.about .about-counter-1 .niktext,
.about .about-counter-2 .odometer,
.about .about-counter-2 .niktext {
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-size: 60px;
    font-weight: 700;
    color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .about .about-counter-1 .odometer,
    .about .about-counter-1 .niktext,
    .about .about-counter-2 .odometer,
    .about .about-counter-2 .niktext {
        font-size: 40px;
    }
}

@media only screen and (max-width: 767px) {

    .about .about-counter-1 .odometer,
    .about .about-counter-1 .niktext,
    .about .about-counter-2 .odometer,
    .about .about-counter-2 .niktext {
        font-size: 26px;
    }
}

.about .about-counter-1 .niktext,
.about .about-counter-2 .niktext {
    line-height: 1;
}

@media only screen and (max-width: 767px) {

    .about .about-counter-1 .niktext,
    .about .about-counter-2 .niktext {
        line-height: inherit;
        margin-left: 2px;
    }
}

.about .about-counter-1 .increment,
.about .about-counter-2 .increment {
    color: #ffffff;
    font-size: 26px;
    position: relative;
    top: -10px;
}

@media only screen and (max-width: 767px) {

    .about .about-counter-1 .increment,
    .about .about-counter-2 .increment {
        font-size: 16px;
        margin-left: 3px;
    }
}

.about .about-counter-1 {
    background-color: #322fb3;
}

.about .about-counter-2 {
    background-color: #d5312c;
}

.about .about-counter-title {
    font-size: 21px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about .about-counter-title {
        font-size: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .about .about-counter-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
    }
}

.about-image {
    position: absolute;
    width: 560px;
    margin-left: 95px;
    margin-top: -170px;
}

@media only screen and (min-width: 1550px) and (max-width: 1800px) {
    .about-image {
        width: 540px;
        margin-left: auto;
        right: 20px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-image {
        width: 465px;
        margin-left: auto;
        right: 20px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-image {
        width: 560px;
        margin-left: auto;
        right: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-image {
        width: 450px;
        margin-left: auto;
        right: 20px;
        margin-top: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image {
        position: relative;
        margin: 20px auto 30px;
    }
}

@media only screen and (max-width: 767px) {
    .about-image {
        position: relative;
        margin: 30px auto 0px;
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .about-image {
        position: inherit;
    }
}

.about-image-inner-thumb {
    background-color: #322fb3;
    color: #ffffff;
    padding: 40px 25px;
    border-radius: 10px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 479px) {
    .about-image-inner-thumb {
        padding: 20px 15px;
        bottom: 20px;
        right: 20px;
    }
}

.about-image-inner-thumb .text {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px;
}

@media only screen and (max-width: 479px) {
    .about-image-inner-thumb .text {
        font-size: 16px;
    }
}

.about-image-inner-thumb .date {
    font-size: 42px;
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-weight: 700;
    line-height: 1;
}

@media only screen and (max-width: 479px) {
    .about-image-inner-thumb .date {
        font-size: 30px;
    }
}

.about-tab {
    position: absolute;
    bottom: 0;
    left: 73px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-tab {
        left: 35px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 767px) {
    .about-tab {
        left: 28px;
    }
}

@media only screen and (max-width: 479px) {
    .about-tab {
        left: 12px;
    }
}

.about-tab .nav-item {
    margin-right: 2px;
}

.about-tab .nav-link {
    border: 1px solid transparent;
    background-color: #322fb3;
    padding: 35px 100px;
    border-radius: 10px 10px 0 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #ffffff;
}

.about-tab .nav-link.active,
.about-tab .nav-link:hover {
    background-color: #d5312c;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-tab .nav-link {
        padding: 30px 90px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-tab .nav-link {
        padding: 30px 60px;
    }
}

@media only screen and (max-width: 767px) {
    .about-tab .nav-link {
        padding: 20px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .about-tab .nav-link {
        padding: 15px 22px;
        font-size: 14px;
    }
}

.about-tab-content {
    margin-top: 90px;
    position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-tab-content {
        margin-top: 70px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-tab-content {
        margin-top: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-tab-content {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .about-tab-content {
        margin-top: 30px;
    }
}

.about-tab-content .heading-one-title {
    font-size: 48px;
    margin-top: 10px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
    .about-tab-content .heading-one-title {
        font-size: 40px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 479px) {
    .about-tab-content .heading-one-title {
        font-size: 36px;
    }
}

.about-tab-content .heading-one p {
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .about-tab-content .heading-one p {
        margin-bottom: 20px;
    }
}

.about-tab-content .heading-one .btn-style-one {
    margin-top: 20px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .about-tab-content .heading-one .btn-style-one {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .about-tab-content .shape {
        display: none;
    }
}

.about-tab-content .shape.shape-1 {
    position: absolute;
    top: 10%;
    right: 3%;
    -webkit-animation: animshape1 3s infinite linear;
    animation: animshape1 3s infinite linear;
}

.about-tab-content .shape.shape-2 {
    position: absolute;
    top: 20%;
    right: 3%;
    -webkit-animation: animshape1 3s infinite linear;
    animation: animshape1 3s infinite linear;
}

.about-tab-content .shape.shape-2 img {
    transform: rotate(20deg);
}

.about-tab-content .shape.shape-3 {
    position: absolute;
    bottom: -2%;
    right: 3%;
    -webkit-animation: moveleftright1 3s infinite linear;
    animation: moveleftright1 3s infinite linear;
}

.about-tab-image {
    position: relative;
    margin-right: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-tab-image {
        margin-right: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-tab-image {
        margin-right: 0px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .about-tab-image {
        margin-right: 0px;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .about-tab-image .shape {
        display: none;
    }
}

.about-tab-image .shape.shape-1 {
    position: absolute;
    top: -3%;
    left: 0;
    -webkit-animation: animshape1 3s infinite linear;
    animation: animshape1 3s infinite linear;
}

.about-tab-image .shape.shape-2 {
    position: absolute;
    top: 64%;
    left: 76%;
    -webkit-animation: animshape1 3s infinite linear;
    animation: animshape1 3s infinite linear;
}

.about-tab-image-one {
    position: absolute;
    left: -42%;
    top: 13%;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-tab-image-one {
        left: -6%;
        width: 35%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-tab-image-one {
        left: 0;
        width: 35%;
    }
}

.about-tab-image-three {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-tab-image-three {
        width: 60%;
        margin-left: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-tab-image-three {
        width: 60%;
        margin-left: auto;
    }
}

.about-tab-image-two {
    position: relative;
    margin-top: -42px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-tab-image-two {
        width: 53%;
        margin-left: 57px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-tab-image-two {
        width: 53%;
        left: 20%;
    }
}


/* About Two Style */

.about-two {
    position: relative;
}

.about-two-images {
    margin-right: 70px;
    height: 650px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two-images {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-two-images {
        width: 100%;
        margin: 0 auto 60px;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 767px) {
    .about-two-images {
        margin: 0 auto 40px;
    }
}

@media only screen and (max-width: 479px) {
    .about-two-images {
        height: 400px;
    }
}

.about-two-images-one {
    position: relative;
}

@media only screen and (max-width: 479px) {
    .about-two-images-one {
        width: 70%;
    }
}

.about-two-images-two {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}

@media only screen and (max-width: 479px) {
    .about-two-images-two {
        width: 50%;
    }
}

.about-two-images-thumb {
    background-color: #322fb3;
    color: #ffffff;
    padding: 40px 25px;
    border-radius: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 60px;
    left: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two-images-thumb {
        left: -20px;
    }
}

@media only screen and (max-width: 767px) {
    .about-two-images-thumb {
        left: -20px;
        top: 20px;
    }
}

@media only screen and (max-width: 479px) {
    .about-two-images-thumb {
        padding: 15px 10px;
        left: -10px;
        top: 10px;
    }
}

.about-two-images-thumb .text {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px;
}

@media only screen and (max-width: 479px) {
    .about-two-images-thumb .text {
        font-size: 14px;
    }
}

.about-two-images-thumb .date {
    font-size: 42px;
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-weight: 700;
    line-height: 1;
}

@media only screen and (max-width: 479px) {
    .about-two-images-thumb .date {
        font-size: 24px;
    }
}

.about-two .heading-one {
    margin-left: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .heading-one {
        margin-left: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-two .heading-one {
        margin-left: 0px;
    }
}

.about-two .heading-one-title {
    margin: 10px 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .about-two .heading-one-title {
        font-size: 55px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .heading-one-title {
        font-size: 45px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .heading-one-title {
        margin: 10px 0 30px;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .heading-one-title {
        margin: 10px 0 20px;
    }
}

.about-two-text p {
    margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-two-text p {
        margin-bottom: 15px;
    }
}

.about-two-text .btn-style-one {
    margin-top: 20px;
    display: inline-block;
}

.about-two .shape {
    position: absolute;
}

.about-two .shape.shape-1 {
    bottom: -40px;
    left: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-1 {
        bottom: 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-1 {
        bottom: 43%;
    }
}

.about-two .shape.shape-1 img {
    -webkit-animation: zoom1 7s infinite linear;
    animation: zoom1 7s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-1 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-1 img {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-1 {
        display: none;
    }
}

.about-two .shape.shape-2 {
    bottom: 250px;
    left: 40px;
}

.about-two .shape.shape-2 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-2 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-2 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-2 {
        bottom: 60%;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-2 {
        display: none;
    }
}

.about-two .shape.shape-3 {
    top: 30px;
}

.about-two .shape.shape-3 img {
    -webkit-animation: zoom1 7s infinite linear;
    animation: zoom1 7s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-3 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-3 img {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-3 {
        display: none;
    }
}

.about-two .shape.shape-4 {
    top: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-4 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-4 img {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-4 {
        display: none;
    }
}

.about-two .shape.shape-5 {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
    right: 100px;
    top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-5 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-5 img {
        width: 70%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-5 {
        right: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-5 {
        display: none;
    }
}

.about-two .shape.shape-6 {
    bottom: 115px;
    right: 140px;
}

.about-two .shape.shape-6 img {
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-6 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-6 img {
        width: 50%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-6 {
        right: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-6 {
        bottom: 70px;
        right: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-6 {
        display: none;
    }
}

.about-two .shape.shape-7 {
    top: 72.5%;
    right: 0px;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-two .shape.shape-7 img {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-7 img {
        width: 34%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-two .shape.shape-7 {
        top: 88%;
    }
}

@media only screen and (max-width: 767px) {
    .about-two .shape.shape-7 {
        display: none;
    }
}

.about-creative-images {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-creative-images {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .about-creative-images {
        display: flex;
        margin-bottom: 50px;
    }
}

.about-creative-images .image-one {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -180px;
    z-index: -1;
}

.about-creative-images .image-one::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
    opacity: 0.7;
    z-index: 1;
    border-radius: 15px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-creative-images .image-one {
        left: -105px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-creative-images .image-one {
        left: -40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-creative-images .image-one {
        left: 0;
        width: 70%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-creative-images .image-one {
        left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .about-creative-images .image-one {
        left: 0;
        width: 70%;
    }
}

.about-creative-images .image-two {
    box-shadow: -50px 25px 90px 0px rgba(16, 37, 113, 0.13);
    display: inline-block;
    border-radius: 15px;
    position: relative;
    margin-right: 140px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-creative-images .image-two {
        margin-right: 0;
        right: -50px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-creative-images .image-two {
        margin-right: 130px;
        left: 131px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-creative-images .image-two {
        margin-right: 0;
        width: 70%;
        left: 30%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-creative-images .image-two {
        margin-left: 180px;
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .about-creative-images .image-two {
        width: 70%;
        margin-left: auto;
        margin-right: 0;
    }
}

.about-creative .heading-one-title {
    margin: 5px 0 40px;
}

@media only screen and (max-width: 767px) {
    .about-creative .heading-one-title {
        margin: 5px 0 30px;
    }
}

.about-creative-tab {
    margin-top: 50px;
}

@media only screen and (max-width: 767px) {
    .about-creative-tab {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .about-creative-tab {
        margin-top: 30px;
    }
}

.about-creative-tab .nav-tabs .nav-link {
    font-size: 18px;
    font-weight: 700;
    color: #343c55;
    border-top: 0px;
    border-right: 0;
    border-left: 0;
    padding: 0 35px 20px;
    line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-creative-tab .nav-tabs .nav-link {
        padding: 0 24px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .about-creative-tab .nav-tabs .nav-link {
        padding: 0 24px 20px;
    }
}

@media only screen and (max-width: 479px) {
    .about-creative-tab .nav-tabs .nav-link {
        padding: 0 10px 12px;
        font-size: 16px;
    }
}

.about-creative-tab .nav-tabs .nav-link.active {
    border-bottom: 2px solid #d5312c;
}

.about-creative-tab .nav-content-inner {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;
}

@media only screen and (max-width: 767px) {
    .about-creative-tab .nav-content-inner {
        gap: 20px;
    }
}

.about-creative-tab .nav-content-inner p {
    max-width: 303px;
    width: 100%;
    margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-creative-tab .nav-content-inner p {
        max-width: 278px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-creative-tab .nav-content-inner p {
        max-width: 255px;
    }
}

@media only screen and (max-width: 767px) {
    .about-creative-tab .nav-content-inner p {
        max-width: 278px;
    }
}

@media only screen and (max-width: 575px) {
    .about-creative-tab .nav-content-inner p {
        max-width: 255px;
    }
}

@media only screen and (max-width: 479px) {
    .about-creative-tab .nav-content-inner p {
        max-width: 100%;
        margin-bottom: 0;
    }
}

.about-creative-tab .nav-content-inner img {
    flex: 0 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-creative-tab .nav-content-inner img {
        width: 37%;
    }
}

@media only screen and (max-width: 767px) {
    .about-creative-tab .nav-content-inner img {
        width: 40%;
    }
}

@media only screen and (max-width: 575px) {
    .about-creative-tab .nav-content-inner img {
        width: 37%;
    }
}

@media only screen and (max-width: 479px) {
    .about-creative-tab .nav-content-inner img {
        width: 100%;
    }
}


/* About Three Style */

.about-three {
    position: relative;
}

.about-three-image-shape {
    position: absolute;
    margin-left: -154px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .about-three-image-shape {
        margin-left: -100px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three-image-shape {
        margin-left: -33px;
    }

    .about-three-image-shape img {
        width: 90%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-three-image-shape {
        margin-left: 25px;
    }

    .about-three-image-shape img {
        width: 75%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three-image-shape {
        margin-left: 34px;
        top: 30px;
    }

    .about-three-image-shape img {
        width: 60%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-three-image-shape {
        margin-left: 100px;
        top: -35px;
    }
}

@media only screen and (max-width: 767px) {
    .about-three-image-shape {
        margin-left: 44px;
        top: -20px;
    }

    .about-three-image-shape img {
        width: 90%;
    }
}

.about-three-image {
    position: relative;
    margin-left: -286px;
    top: 60px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .about-three-image {
        margin-left: -190px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three-image {
        margin-left: -116px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-three-image {
        margin-left: -40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three-image {
        margin-left: -30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-three-image {
        margin-left: 0px;
        top: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .about-three-image {
        margin-left: 0px;
        top: 0px;
    }
}

.about-three-chart {
    box-shadow: 15px 11px 48px 0px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    background-color: #ffffff;
    padding: 25px;
    position: absolute;
    top: 155px;
    margin-left: 235px;
    -webkit-animation: moveupdown1 4s infinite linear !important;
    animation: moveupdown1 4s infinite linear !important;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .about-three-chart {
        margin-left: 300px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three-chart {
        margin-left: 340px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-three-chart {
        top: 114px;
        margin-left: 330px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three-chart {
        top: 114px;
        margin-left: 275px;
        padding: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-three-chart {
        top: 100px;
        margin-left: 500px;
    }
}

@media only screen and (max-width: 767px) {
    .about-three-chart {
        top: 70px;
        margin-left: auto;
        right: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .about-three-chart {
        padding: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .about-three-chart {
        top: 30px;
    }
}

.about-three-duration {
    box-shadow: -18px 34px 72px 0px rgba(0, 0, 0, 0.07);
    background: #ffffff;
    padding: 44px 60px 50px 70px;
    position: absolute;
    top: 510px;
    margin-left: -186px;
    border-radius: 7px;
    -webkit-animation: moveupdown1 6s infinite linear !important;
    animation: moveupdown1 6s infinite linear !important;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .about-three-duration {
        margin-left: -100px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three-duration {
        margin-left: 0px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-three-duration {
        top: 425px;
        margin-left: 0px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three-duration {
        top: 380px;
        margin-left: 0px;
        padding: 30px 40px 30px 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-three-duration {
        top: 450px;
        margin-left: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .about-three-duration {
        padding: 30px 40px 30px 50px;
        top: 380px;
        margin-left: auto;
        left: 116px;
    }
}

@media only screen and (max-width: 575px) {
    .about-three-duration {
        padding: 20px 30px 20px 40px;
        top: 340px;
        margin-left: auto;
        left: 100px;
    }
}

@media only screen and (max-width: 479px) {
    .about-three-duration {
        top: 230px;
        left: 30px;
    }
}

.about-three-duration .years {
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-weight: 700;
    font-size: 72px;
    color: #d5312c;
    line-height: 1;
    margin-bottom: 10px;
    display: block;
}

@media only screen and (max-width: 575px) {
    .about-three-duration .years {
        font-size: 60px;
    }
}

.about-three-duration .text {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 21px;
    text-transform: uppercase;
    color: #343c55;
}

@media only screen and (max-width: 575px) {
    .about-three-duration .text {
        font-size: 18px;
    }
}

.about-three .heading-one-subtitle {
    margin-bottom: 10px;
    display: block;
}

.about-three .heading-one p {
    margin: 45px 0;
}

@media only screen and (max-width: 767px) {
    .about-three .heading-one p {
        margin: 20px 0 30px;
    }
}

.about-three-details {
    display: flex;
    flex-direction: column;
}

.about-three-service {
    display: flex;
    -moz-column-gap: 20px;
    column-gap: 20px;
}

@media only screen and (max-width: 575px) {
    .about-three-service {
        flex-direction: column;
        row-gap: 15px;
    }
}

.about-three-service-single {
    display: flex;
    -moz-column-gap: 22px;
    column-gap: 22px;
}

@media only screen and (max-width: 767px) {
    .about-three-service-single {
        -moz-column-gap: 15px;
        column-gap: 15px;
    }
}

.about-three-service-thumb {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    background: #eaf6fc;
    position: relative;
    transition: 0.4s;
}

.about-three-service-content .title {
    font-size: 21px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #343c55;
    margin-bottom: 15px;
    display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three-service-content .title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) {
    .about-three-service-content .title {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.about-three-content-list {
    margin-top: 40px;
}

@media only screen and (max-width: 767px) {
    .about-three-content-list p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .about-three-content-list {
        margin-top: 30px;
    }
}

.about-three-buttons {
    display: flex;
    align-items: center;
    -moz-column-gap: 35px;
    column-gap: 35px;
    margin-top: 50px;
}

@media only screen and (max-width: 767px) {
    .about-three-buttons {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 479px) {
    .about-three-buttons {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        row-gap: 15px;
    }
}

.about-three-buttons .btn-style-one {
    padding: 22px 58px;
}

@media only screen and (max-width: 575px) {
    .about-three-buttons .btn-style-one {
        padding: 18px 40px;
    }
}

.about-three-buttons .phone-call {
    display: flex;
    align-items: center;
    -moz-column-gap: 15px;
    column-gap: 15px;
}

.about-three-buttons .phone-call-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 575px) {
    .about-three-buttons .phone-call-icon {
        width: 40px;
        height: 40px;
    }
}

.about-three-buttons .phone-call-icon i {
    color: #d5312c;
    background: #ffffff;
    font-size: 14px;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

@media only screen and (max-width: 575px) {
    .about-three-buttons .phone-call-icon i {
        width: 36px;
        height: 36px;
    }
}

.about-three-buttons .phone-call-number {
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-weight: 700;
    font-size: 21px;
    color: #343c55;
    transition: 0.3s;
}

.about-three-buttons .phone-call-number:hover {
    color: #322fb3;
    transition: 0.3s;
}

@media only screen and (max-width: 575px) {
    .about-three-buttons .phone-call-number {
        font-size: 18px;
    }
}

.about-three .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
    z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .about-three .shape {
        display: none;
    }
}

.about-three .shape .shape1 {
    position: absolute;
    left: 9%;
    z-index: -1;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .about-three .shape .shape1 {
        left: 5%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three .shape .shape1 {
        left: 2%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three .shape .shape1 {
        left: 1%;
    }
}

.about-three .shape .shape2 {
    position: absolute;
    right: 4%;
    z-index: -1;
    margin-top: -60px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three .shape .shape2 {
        right: 2%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-three .shape .shape2 {
        right: 2%;
        margin-top: -100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three .shape .shape2 {
        right: 2%;
        margin-top: -100px;
        width: 8%;
    }
}

.about-three .shape .shape4 {
    position: absolute;
    left: 4.8%;
    z-index: -1;
    bottom: -23px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three .shape .shape4 {
        left: 3%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three .shape .shape4 {
        left: 2%;
        bottom: 80px;
    }
}

.about-three .shape .shape5 {
    position: absolute;
    right: 6%;
    z-index: -1;
    bottom: 18%;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .about-three .shape .shape5 {
        right: 4%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .about-three .shape .shape5 {
        right: 1%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-three .shape .shape5 {
        right: 0%;
    }
}


/* =============================
    2.5 - Progress Style
================================ */

.progress-section {
    position: relative;
}

.progress-section-content {
    /* background-image: url(../public/assets/images/progress/progress-bg.png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 90px;
    margin-top: 44px;
    border-radius: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-section-content {
        padding: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .progress-section-content {
        padding: 50px;
        margin-top: 0;
    }
}

@media only screen and (max-width: 575px) {
    .progress-section-content {
        padding: 30px;
        margin-top: 0;
    }
}

.progress-section-content .heading-one-title {
    color: #ffffff;
    margin: 12px 0 30px;
}

.progress-section-content p {
    color: #ffffff;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .progress-section .shape {
        display: none;
    }
}

.progress-section .shape.shape-1 {
    position: absolute;
    top: 5%;
    left: 15%;
    -webkit-animation: circle1 4s infinite linear;
    animation: circle1 4s infinite linear;
}

.progress-section .shape.shape-2 {
    position: absolute;
    top: 5%;
    left: 2%;
    -webkit-animation: animshape1 3s infinite linear;
    animation: animshape1 3s infinite linear;
}

.progress-section .shape.shape-2 img {
    transform: rotate(20deg);
}

.progress-section .shape.shape-3 {
    position: absolute;
    top: 22%;
    left: 8%;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
    z-index: -1;
}

.radial-progress-single {
    text-align: center;
    margin-top: 40px;
}

.radial-progress-single .circle-info {
    padding-top: 20px;
    text-align: center;
}

@media only screen and (max-width: 575px) {
    .radial-progress-single .circle-info {
        padding: 0 0 0 20px;
        max-width: 250px;
        margin: 10px auto 0;
    }
}

.radial-progress-single .circle-info .title {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 15px;
}

.radial-progress {
    position: relative;
}

.radial-progress .circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radial-progress .circle-text span.count {
    /* font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important; */
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    color: #d5312c;
    position: relative;
}

.progress-image {
    margin: 0px 10px 0 -110px;
    position: absolute;
    width: 54%;
    z-index: -1;
}

@media only screen and (min-width: 1701px) and (max-width: 1750px) {
    .progress-image {
        width: 53%;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1700px) {
    .progress-image {
        width: 46%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .progress-image {
        width: 40%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-image {
        width: 35%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .progress-image {
        width: 100%;
        margin: 0px 0 -40px;
        position: relative;
    }

    .progress-image img {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .custom-margin {
        padding-right: 0px;
        padding-left: 0px;
    }
}


/* -----Progress Two Style----- */

.progress-two {
    position: relative;
}

.progress-two-images .image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
}

@media only screen and (min-width: 1550px) and (max-width: 1700px) {
    .progress-two-images .image img {
        width: 90%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .progress-two-images .image img {
        width: 80%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .progress-two-images .image img {
        width: 70%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two-images .image {
        top: 50px;
    }

    .progress-two-images .image img {
        width: 58%;
    }
}

.progress-two-images .image-bg {
    position: absolute;
    left: 0;
    top: 156px;
}

@media only screen and (min-width: 1550px) and (max-width: 1700px) {
    .progress-two-images .image-bg img {
        width: 90%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .progress-two-images .image-bg img {
        width: 80%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .progress-two-images .image-bg img {
        width: 70%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two-images .image-bg img {
        width: 58%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .progress-two-images .image-bg {
        position: relative;
        margin: 0 0 60px -36px;
    }
}

@media only screen and (max-width: 767px) {
    .progress-two-images .image-bg {
        position: relative;
        top: 130px;
        margin: 0 0 40px -30px;
    }
}

@media only screen and (max-width: 479px) {
    .progress-two-images .image-bg {
        margin: 0 0 20px -12px;
        top: 80px;
    }
}

.progress-two-images .sheild {
    box-shadow: 15px 11px 90px 0px rgba(0, 0, 0, 0.07);
    padding: 25px 30px;
    background: #ffffff;
    border-radius: 7px;
    position: absolute;
    bottom: -46px;
    left: 22%;
    z-index: 6;
    -webkit-animation: moveupdown1 6s infinite linear !important;
    animation: moveupdown1 6s infinite linear !important;
}

@media only screen and (min-width: 1550px) and (max-width: 1700px) {
    .progress-two-images .sheild {
        bottom: 0px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .progress-two-images .sheild {
        left: 24%;
        bottom: 10px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .progress-two-images .sheild {
        bottom: 25px;
        left: 24%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two-images .sheild {
        bottom: 80px;
        left: 24%;
        padding: 15px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .progress-two-images .sheild {
        top: 44%;
        left: 50%;
        bottom: auto;
    }
}

@media only screen and (max-width: 767px) {
    .progress-two-images .sheild {
        top: 40%;
        left: 50%;
        bottom: auto;
        padding: 15px 20px;
    }
}

@media only screen and (max-width: 575px) {
    .progress-two-images .sheild {
        top: 36%;
    }
}

@media only screen and (max-width: 479px) {
    .progress-two-images .sheild {
        top: 28%;
        padding: 10px 15px;
    }
}

.progress-two-images .handshake {
    padding: 35px;
    background: linear-gradient(-45deg, #5bcff2 0%, #417dd2 50%, #322fb3 100%);
    border-radius: 7px;
    position: absolute;
    top: 25%;
    left: 30%;
    z-index: 6;
    -webkit-animation: moveupdown1 4s infinite linear !important;
    animation: moveupdown1 4s infinite linear !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two-images .handshake {
        padding: 25px;
        top: 18%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .progress-two-images .handshake {
        top: 12%;
        left: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .progress-two-images .handshake {
        top: 12%;
        left: 70%;
        padding: 20px;
    }
}

@media only screen and (max-width: 479px) {
    .progress-two-images .handshake {
        top: 6%;
        left: 60%;
        padding: 15px;
    }
}

.progress-two-wrapper {
    overflow: hidden;
    margin-top: 130px;
}

@media only screen and (min-width: 1550px) and (max-width: 1700px) {
    .progress-two-wrapper {
        margin-top: 100px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .progress-two-wrapper {
        margin-top: 50px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two-wrapper {
        margin-top: 0px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two-wrapper img {
        width: 58%;
    }
}

@media only screen and (max-width: 479px) {
    .progress-two-wrapper {
        margin-top: 90px;
    }
}

.progress-two .heading-one {
    padding-bottom: 15px;
}

.progress-two .heading-one-title {
    margin: 10px 0 30px;
}

@media only screen and (max-width: 767px) {
    .progress-two .heading-one {
        padding-bottom: 0px;
    }

    .progress-two .heading-one-title {
        margin: 0px 0 20px;
    }
}

.progress-two .progress {
    overflow: inherit;
    height: 5px;
    background: #dce0ed;
    display: flex;
    justify-content: space-between;
}

.progress-two .progress-bar {
    position: relative;
    top: -5px;
    height: 3px;
    background: #47bfe7;
}

.progress-two .progress-title {
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #343c55;
    margin: 35px 0 20px;
}

@media only screen and (max-width: 767px) {
    .progress-two .progress-title {
        margin: 25px 0 15px;
        font-size: 18px;
    }
}

.progress-two .progress-number {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #2d3757;
    position: relative;
    top: -50px;
}

@media only screen and (max-width: 767px) {
    .progress-two .progress-number {
        top: -40px;
        font-size: 16px;
    }
}

.progress-two .progress-button {
    display: flex;
    margin-top: 60px;
}

.progress-two .progress-button .btn-style-one {
    padding: 22px 58px;
}

@media only screen and (max-width: 767px) {
    .progress-two .progress-button {
        margin-top: 40px;
    }

    .progress-two .progress-button .btn-style-one {
        padding: 18px 45px;
    }
}

@media only screen and (max-width: 575px) {
    .progress-two .progress-button {
        margin-top: 40px;
    }

    .progress-two .progress-button .btn-style-one {
        padding: 18px 30px;
    }
}

.progress-two .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .progress-two .shape {
        display: none;
    }
}

.progress-two .shape .shape1 {
    position: absolute;
    left: 21%;
    top: 110px;
    z-index: -1;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .progress-two .shape .shape1 {
        top: 80px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .progress-two .shape .shape1 {
        top: 60px;
        left: 20%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two .shape .shape1 {
        top: 60px;
        left: 20%;
    }

    .progress-two .shape .shape1 img {
        width: 80%;
    }
}

.progress-two .shape .shape2 {
    position: absolute;
    right: 10%;
    bottom: -8%;
}

.progress-two .shape .shape3 {
    position: absolute;
    right: 2%;
    top: 81%;
    z-index: 3;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .progress-two .shape .shape3 {
        right: 1%;
        top: 80%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress-two .shape .shape3 {
        right: 0%;
        top: 80%;
    }
}


/*----------------------------------------*/


/*  2.6 - Service Styles CSS
/*----------------------------------------*/

.service {
    position: relative;
}

.service .heading-one {
    max-width: 570px;
    width: 100%;
    margin: 0 auto 0;
    margin-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service .heading-one {
        margin-bottom: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .service .heading-one {
        margin-bottom: 50px;
    }
}

.service .heading-one p {
    margin-top: 15px;
}

.service-single {
    position: relative;
}

.service-single-image {
    position: relative;
    transition: 0.4s;
}

.service-single-image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 15px;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
    opacity: 0.6;
}

.service-single-image-thumb img {
    width: 100%;
}

.service-single-image-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.service-single-image-content .icon {
    background-color: #ffffff;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .service-single-image-content .icon {
        width: 80px;
        height: 80px;
    }
}

.service-single-image-content .title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .service-single-image-content .title {
        font-size: 24px;
    }
}

.service-single-white {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    z-index: 6;
    box-shadow: 0px 18px 72px 0px rgba(0, 0, 0, 0.07);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 14px;
}

.service-single-white-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 35px;
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .service-single-white-content {
        padding: 12px;
    }
}

.service-single-white .icon {
    background-color: #322fb3;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-single-white .icon {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .service-single-white .icon {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
    }
}

.service-single-white .title {
    color: #343c55;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 479px) {
    .service-single-white .title {
        font-size: 26px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .service-single-white .title {
        font-size: 20px;
    }
}

.service-single-white .title:hover {
    color: #322fb3;
}

.service-single-white p {
    margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 479px) {
    .service-single-white p {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .service-single-white p {
        margin-top: 10px;
        font-size: 14px;
    }
}

.service-single:hover .service-single-image {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
}

.service-single:hover .service-single-white {
    opacity: 1;
    visibility: visible;
    transition: 0.4s;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .service .shape {
        display: none;
    }
}

.service .shape.shape-1 {
    position: absolute;
    top: -10%;
    left: 0%;
    -webkit-animation: moveupdown1 4s infinite linear;
    animation: moveupdown1 4s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .service .shape.shape-1 {
        top: -5%;
    }

    .service .shape.shape-1 img {
        width: 60%;
    }
}

.service .shape.shape-2 {
    position: absolute;
    top: 5%;
    right: 3%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

.service .shape.shape-2 img {
    transform: rotate(326deg);
}

.service .shape.shape-3 {
    position: absolute;
    top: 10%;
    right: 14%;
    -webkit-animation: circle1 5s infinite linear;
    animation: circle1 5s infinite linear;
    z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .service .shape.shape-3 {
        right: 18%;
    }
}

.service .shape.shape-4 {
    position: absolute;
    bottom: 14%;
    left: 0%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
    z-index: -1;
}

.service .shape.shape-5 {
    position: absolute;
    bottom: 12%;
    left: 2%;
    -webkit-animation: moveleftright1 3s infinite linear;
    animation: moveleftright1 3s infinite linear;
    z-index: -1;
}

.service .shape.shape-6 {
    position: absolute;
    top: 16%;
    right: 8%;
    -webkit-animation: zoom1 5s infinite linear;
    animation: zoom1 5s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .service .shape.shape-6 {
        right: 14%;
    }
}


/* Service Two Style */

.service-two {
    position: relative;
}

.service-two .heading-one {
    max-width: 570px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;
}

.service-two .heading-one-title {
    margin: 5px 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-two .heading-one {
        margin-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .service-two .heading-one {
        margin-bottom: 60px;
    }
}

.service-two-single {
    display: flex;
    -moz-column-gap: 30px;
    column-gap: 30px;
    box-shadow: 1px 7px 72px 0px rgba(0, 0, 213, 0.07);
    padding: 60px 50px;
    border-radius: 13px;
    position: relative;
    background: #fff;
}

.service-two-single:hover .service-two-thumb-bg::after {
    opacity: 0;
    transition: 0.5s;
}

.service-two-single:hover .service-two-thumb-bg::before {
    opacity: 1;
    transition: 0.5s;
}

.service-two-single:hover .service-two-thumb::before {
    opacity: 0;
    transition: 0.5s;
}

.service-two-single:hover .service-two-thumb::after {
    opacity: 1;
    transition: 0.5s;
}

.service-two-single:hover .service-two-thumb .img-white {
    opacity: 1;
    transition: 0.5s;
}

.service-two-single:hover .service-two-thumb .img-gradient {
    display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-two-single {
        padding: 40px 30px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
}

.service-two-thumb {
    position: relative;
    width: 84px;
    height: 84px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}

.service-two-thumb-bg::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100%;
    top: 0;
    left: 0;
    background: rgba(50, 47, 179, 0.36);
    background: linear-gradient(-45deg, rgba(91, 207, 242, 0.36) 0%, rgba(50, 47, 179, 0.36) 100%);
    transition: 0.4s;
    position: absolute;
    opacity: 1;
}

.service-two-thumb-bg::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100%;
    top: 0;
    left: 0;
    transition: 0.4s;
    position: absolute;
    background: #eae9fe;
    opacity: 0;
}

.service-two-thumb::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 66px;
    border-radius: 100%;
    z-index: 2;
    background: #eae9fe;
    transition: 0.4s;
    opacity: 1;
}

.service-two-thumb::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 66px;
    border-radius: 100%;
    z-index: 2;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
    transition: 0.5s;
    opacity: 0;
}

.service-two-thumb img {
    position: relative;
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.service-two-thumb .img-white {
    opacity: 0;
    transition: 0.5s;
}

.service-two-content .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-two-content .title {
        font-size: 22px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .service-two-content .title {
        font-size: 20px;
    }
}

.service-two-shape {
    position: absolute !important;
    width: 100%;
    height: 100%;
}

.service-two .shape {
    position: absolute;
}

@media only screen and (max-width: 767px) {
    .service-two .shape {
        display: none;
    }
}

.service-two .shape.shape-1 {
    top: 22%;
    left: 180px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-two .shape.shape-1 {
        left: 40px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .service-two .shape.shape-1 {
        left: 0px;
    }
}

.service-two .shape.shape-2 {
    bottom: 5%;
    left: 16%;
}

.service-two .shape.shape-2 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-two .shape.shape-2 {
        left: 5%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .service-two .shape.shape-2 {
        left: 0;
    }
}

.service-two .shape.shape-3 {
    bottom: 7%;
    right: 11%;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-two .shape.shape-3 {
        right: 5%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .service-two .shape.shape-3 {
        right: 2%;
    }
}

.service-two .shape.shape-4 {
    top: 0;
    right: 0;
}


/* Service Three Style */

.service-three {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-three {
        margin-top: 120px;
    }
}

@media only screen and (max-width: 767px) {
    .service-three {
        margin-top: 100px;
    }
}

.service-three-wrapper {
    margin-bottom: -30px;
}

.service-three-wrapper .single-service {
    margin-bottom: 30px;
}

.service-three-wrapper .single-service:nth-child(2n + 2) {
    margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-three-wrapper .single-service:nth-child(2n + 2) {
        margin-top: 0;
    }
}

.service-three-single {
    box-shadow: 15px 11px 48px 0px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    padding: 60px 50px;
    background: #ffffff;
    position: relative;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-three-single {
        padding: 40px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .service-three-single {
        padding: 30px 20px;
        min-height: 300px;
    }
}

@media only screen and (max-width: 575px) {
    .service-three-single {
        min-height: 230px;
    }
}

.service-three-title {
    font-size: 21px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #2d3757;
    margin: 30px 0;
}

@media only screen and (max-width: 767px) {
    .service-three-title {
        margin: 20px 0;
    }
}

@media only screen and (max-width: 575px) {
    .service-three-title {
        margin: 15px 0;
    }
}

.service-three .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-three .shape {
        display: none;
    }
}

.service-three .shape .shape1 {
    position: absolute;
    left: 13%;
    top: 300px;
    z-index: -1;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .service-three .shape .shape1 {
        left: 4%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-three .shape .shape1 {
        left: 4%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-three .shape .shape1 {
        left: 15px;
    }
}

.service-three .shape .shape2 {
    position: absolute;
    right: 13%;
    top: 20px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .service-three .shape .shape2 {
        right: 4%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-three .shape .shape2 {
        right: 4%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-three .shape .shape2 {
        right: 15px;
    }
}


/* Service Four Style */

.service-four {
    padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (max-width: 767px) {
    .service-four {
        padding-top: 0;
    }
}

.service-four .heading-one {
    max-width: 540px;
    width: 100%;
    margin: 0 auto 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-four .heading-one {
        margin: 0 auto 80px;
    }
}

@media only screen and (max-width: 767px) {
    .service-four .heading-one {
        margin: 0 auto 40px;
    }
}

.service-four .heading-one-subtitle {
    margin-bottom: 10px;
    display: block;
}

.service-four-single {
    padding: 45px;
    border-radius: 13px;
    box-shadow: 0px 3px 72px 0px rgba(0, 0, 213, 0.07);
    background: #ffffff;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
    text-decoration: none;
    overflow: hidden;
}

a {
    text-decoration: none !important;
}

.service-four-single::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(-45deg, #5bcff2 0%, #417dd2 50%, #322fb3 100%);
    z-index: -1;
    opacity: 0;
    transition: 0.4s;
    transform: translateX(-100%);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-four-single {
        padding: 30px;
    }
}

.service-four-single-top {
    display: flex;
    -moz-column-gap: 22px;
    column-gap: 22px;
    align-items: center;
    margin-bottom: 20px;
}

.service-four-single-top .icon {
    position: relative;
    width: 85px;
    height: 85px;
    border-radius: 100%;
    background: linear-gradient(-45deg, #5bcff2 0%, #417dd2 50%, #322fb3 100%);
    flex: 0 0 auto;
}

.service-four-single-top .icon-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: 0.3s;
}

.service-four-single-top .icon-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.3s;
}

.service-four-single-top .icon::before {
    content: "";
    border: 2px solid rgba(255, 255, 255, 0.36);
    border-radius: 100%;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.service-four-single-top .title {
    /* font-family: "Poppins", sans-serif; */
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.4;
    letter-spacing: 2px;
    transition: 0.3s;
}

.service-four-single:hover {
    transition: 0.3s;
}

.service-four-single:hover::before {
    opacity: 1;
    transition: 0.4s;
    transform: translateX(0%);
}

.service-four-single:hover .icon {
    background: #ffffff;
}

.service-four-single:hover .icon-1 {
    opacity: 0;
    transition: 0.3s;
}

.service-four-single:hover .icon-2 {
    opacity: 1;
    transition: 0.3s;
    z-index: 5;
}

.service-four-single:hover .icon::before {
    background: linear-gradient(-45deg, #5bcff2 0%, #417dd2 50%, #322fb3 100%);
    border: 0px;
    z-index: 2;
}

.service-four-single:hover .icon::after {
    content: "";
    background: #ffffff;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.service-four-single:hover .title {
    color: #ffffff;
    transition: 0.3s;
}

.service-four-single:hover p {
    color: #ffffff;
    transition: 0.3s;
}

.service-four .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-four .shape {
        display: none;
    }
}

.service-four .shape .shape1 {
    position: absolute;
    left: 0.4%;
    top: 208px;
    z-index: -1;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .service-four .shape .shape1 {
        left: 0.4%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-four .shape .shape1 {
        left: 0%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-four .shape .shape1 {
        left: 0%;
    }
}

.service-four .shape .shape1 img {
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

.service-four .shape .shape2 {
    position: absolute;
    left: 13.5%;
    top: 10%;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .service-four .shape .shape2 {
        left: 8%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-four .shape .shape2 {
        left: 5%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-four .shape .shape2 {
        left: 7%;
        top: 5%;
    }
}

.service-four .shape .shape3 {
    position: absolute;
    right: 0%;
    top: 67%;
    z-index: 3;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .service-four .shape .shape3 {
        right: 0%;
        top: 80%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .service-four .shape .shape3 {
        right: 0%;
        top: 80%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .service-four .shape .shape3 {
        right: 0%;
        top: 80%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-four .shape .shape3 {
        right: 0%;
        bottom: 0%;
        width: 30%;
    }
}

.service-four .shape .shape4 {
    position: absolute;
    left: 0;
    top: -29%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .service-four .shape .shape4 img {
        width: 85%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-four .shape .shape4 img {
        width: 92%;
    }
}

.service-four .shape .shape5 {
    position: absolute;
    right: 0;
    top: -54%;
    z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-four .shape .shape5 {
        display: flex;
        justify-content: flex-end;
    }

    .service-four .shape .shape5 img {
        width: 90%;
    }
}

.service-four .shape .shape6 {
    position: absolute;
    right: 0;
    top: -25%;
    z-index: 2;
}


/* Service Details Style */

.service-details-content-list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.service-details-content-list li:last-child {
    margin-bottom: 0px;
}

.service-details-content-list li img {
    flex: 0 0 auto;
    margin-right: 20px;
}

.service-details-content-list li p {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-details-content {
        margin-bottom: 50px;
    }
}

.service-details-title {
    font-size: 30px;
    font-weight: 700;
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .service-details-title {
        margin-top: 40px;
    }
}

.service-details-text1 {
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px;
}

.service-details p {
    margin-bottom: 20px;
}

.service-details-image2 img {
    width: 100%;
}

.service-details-key {
    margin: 30px 0;
}

.service-details-widget {
    margin-bottom: 40px;
}

.service-details-widget-list li {
    margin-bottom: 10px;
}

.service-details-widget-list li:last-child {
    margin-bottom: 0;
}

.service-details-widget-list li a {
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    color: #343c55;
    display: flex;
    background: rgba(50, 47, 179, 0.13);
    background: linear-gradient(-45deg, rgba(91, 207, 242, 0.13) 0%, rgba(65, 125, 210, 0.13) 50%, rgba(50, 47, 179, 0.13) 100%);
}

.service-details-widget-list li a:hover {
    color: #ffffff;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
}

.service-details-widget-list li a.active {
    color: #ffffff;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
}

.service-details-help {
    padding: 60px 50px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-details-help {
        padding: 50px 40px;
    }
}

.service-details-help::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #322fb3;
    background: linear-gradient(-54deg, #d5312c 0%, #417dd2 50%, #322fb3 100%);
    opacity: 0.88;
}

.service-details-help .title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
}

.service-details-help-text {
    color: white;
    position: relative;
    z-index: 2;
}

.service-details-help-call {
    display: flex;
    align-items: center;
    margin: 35px 0 20px;
    position: relative;
    z-index: 2;
}

.service-details-help-call .icon {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #343c55;
    transform: rotate(90deg);
    background-color: #d5312c;
    font-size: 21px;
    margin-right: 20px;
    color: #ffffff;
}

.service-details-help-call .call-area {
    display: flex;
    flex-direction: column;
}

.service-details-help-call .text {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

.service-details-help-call a {
    font-size: 18px;
    color: white;
    font-weight: 700;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.service-details-help-call a:hover {
    color: #343c55;
}


/*----------------------------------------*/


/*  2.7 - Work Styles CSS
/*----------------------------------------*/

.work-bg {
    /* background-image: url(../public/assets/images/work/work-bg.png); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 134px 170px;
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: -70px;
    z-index: 4;
    border-radius: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .work-bg {
        padding: 100px 50px;
        margin-right: -35px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-bg {
        padding: 72px 25px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-bg {
        max-width: 700px;
        padding: 50px;
        margin-right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .work-bg {
        max-width: 515px;
        padding: 40px;
        margin-right: auto;
    }
}

.work-bg .heading-one-title {
    color: #ffffff;
    margin: 15px 0 30px;
}

.work-bg p {
    font-size: 21px;
    color: #ffffff;
    font-weight: 400;
}

@media only screen and (max-width: 767px) {
    .work-bg p {
        font-size: 18px;
    }
}

.work-btn {
    display: flex;
    align-items: center;
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .work-btn {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .work-btn {
        margin-top: 30px;
        flex-direction: column;
        align-items: flex-start;
    }
}

.work-btn .btn-style-two {
    font-size: 18px;
}

@media only screen and (max-width: 767px) {
    .work-btn .btn-style-two {
        font-size: 16px;
    }
}

.work-btn .view-btn {
    font-size: 18px;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 48px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .work-btn .view-btn {
        margin-left: 20px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 479px) {
    .work-btn .view-btn {
        margin: 10px 0 0 0;
    }
}

.work-btn .view-btn::after {
    position: absolute;
    content: "";
    background-color: #d5312c;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    transition: 0.3s;
}

.work-btn .view-btn:hover {
    color: #d5312c;
}

.work-btn .view-btn:hover::after {
    background-color: #d5312c;
    transition: 0.3s;
}

.work-image {
    margin: 230px 0px 0 0px;
    position: absolute;
    right: 0;
    z-index: 3;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .work-image {
        width: 40%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-image {
        width: 36%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-image {
        max-width: 700px;
        width: 100%;
        position: relative;
        margin: -40px auto 0;
        z-index: -1;
    }

    .work-image img {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .work-image {
        max-width: 515px;
        width: 100%;
        position: relative;
        margin: -40px auto 0;
        z-index: -1;
    }

    .work-image img {
        width: 100%;
    }
}

.work-step {
    /* background-image: url(../public/assets/images/work/work-step-bg.png); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    position: relative;
    padding: 280px 0px 120px;
    margin-top: -160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-step {
        background-size: cover;
        padding: 180px 0px 120px;
        margin-top: -100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-step {
        padding: 180px 0px 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-step {
        background-size: cover;
        padding: 230px 0px 80px;
    }
}

@media only screen and (max-width: 767px) {
    .work-step {
        background-size: cover;
        padding: 230px 0px 60px;
    }
}

.work-step .heading-one {
    margin-bottom: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .work-step .heading-one {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .work-step .heading-one {
        margin-bottom: 40px;
    }
}

.work-step .heading-one-title {
    margin: 10px 0 30px;
}

.work-step-single .number {
    background-color: #d5312c;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .work-step-single .number {
        width: 60px;
        height: 60px;
        font-size: 24px;
    }
}

.work-step-single .title {
    color: #2d3757;
    font-weight: 700;
    font-size: 24px;
    margin: 18px 0 20px;
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .work-step-single .title {
        margin: 18px 0 10px;
        font-size: 20px;
    }
}

.work-step-single .title:hover {
    color: #322fb3;
}

.work-step-white-bg {
    position: absolute;
    width: 12%;
    height: 100%;
    top: 0;
    right: 0;
    background: #ffffff;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .work-step-white-bg {
        width: 8%;
    }
}

@media only screen and (min-width: 140px) and (max-width: 1399px) {
    .work-step-white-bg {
        display: none;
    }
}

.work-two {
    margin: 162px 0 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1400px) and (max-width: 1549px) {
    .work-two {
        margin: 120px 0 120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-two {
        margin: 80px 0;
    }
}

@media only screen and (max-width: 767px) {
    .work-two {
        margin: 60px 0;
    }
}

.work-two .heading-one {
    margin-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-two .heading-one {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .work-two .heading-one {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .work-two .heading-one {
        max-width: 290px;
    }
}

.work-two .heading-one-subtitle {
    margin-bottom: 10px;
    display: block;
}

.work-two .work-carousel {
    float: left;
    width: calc(100% + 432px);
    position: relative;
    padding-top: 160px;
    margin-top: -160px;
    margin-left: -215px;
}

@media only screen and (max-width: 767px) {
    .work-two .work-carousel {
        width: 100%;
        margin-left: 0px;
    }
}

.work-two .work-carousel-wrapper {
    overflow: hidden;
    padding-top: 160px;
    margin-top: -160px;
}

.work-two .work-carousel .single-work-carousel {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.work-two .work-carousel .single-work-carousel::before {
    content: "";
    background: linear-gradient(-50deg, #5bcff2 15%, #417dd2 47%, #322fb3 80%);
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    position: absolute;
    opacity: 0;
    transition: 0.5s;
    z-index: 1;
}

.work-two .work-carousel .single-work-carousel .thumbnail {
    overflow: hidden;
    border-radius: 13px;
    display: block;
    position: relative;
    transition: 0.3s;
}

.work-two .work-carousel .single-work-carousel .thumbnail img {
    transform: scale(1);
    transition: 0.3s;
}

.work-two .work-carousel .single-work-carousel .thumbnail:hover img {
    transform: scale(1.06);
    transition: 0.3s;
}

.work-two .work-carousel .single-work-carousel .lightbox {
    position: absolute;
    top: -20px;
    right: 30px;
    z-index: 9;
    transition: 0.6s;
    opacity: 0;
}

.work-two .work-carousel .single-work-carousel .lightbox .item {
    font-size: 16px;
    color: black;
    background: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: 0.3s;
}

.work-two .work-carousel .single-work-carousel .lightbox .item:hover {
    background: linear-gradient(-50deg, #5bcff2 15%, #417dd2 47%, #322fb3 80%);
    color: white;
    transition: 0.3s;
}

.work-two .work-carousel .single-work-carousel .content {
    position: absolute;
    bottom: -30px;
    left: 0;
    opacity: 0;
    transition: 0.6s;
    padding: 40px;
    z-index: 2;
}

.work-two .work-carousel .single-work-carousel .content .subtitle {
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.work-two .work-carousel .single-work-carousel .content .title {
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-top: 5px;
}

.work-two .work-carousel .single-work-carousel .content .title a {
    color: white;
}

.work-two .work-carousel .single-work-carousel:hover .lightbox {
    top: 30px;
    transition: 0.6s;
    opacity: 1;
}

.work-two .work-carousel .single-work-carousel:hover .content {
    bottom: 0%;
    opacity: 1;
    transition: 0.6s;
}

.work-two .work-carousel .single-work-carousel:hover::before {
    opacity: 0.7;
}

.work-two .work-carousel .work-swiper-arrow {
    position: absolute;
    right: 25%;
    top: 0px;
    display: flex;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 22%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 20%;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 20%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 16%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 24%;
        top: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 30px;
        top: 70px;
        -moz-column-gap: 10px;
        column-gap: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .work-two .work-carousel .work-swiper-arrow {
        right: 12px;
    }
}

@media only screen and (max-width: 479px) {
    .work-two .work-carousel .work-swiper-arrow {
        top: 90px;
        right: 12px;
    }

    .work-two .work-carousel .work-swiper-arrow .prev-button,
    .work-two .work-carousel .work-swiper-arrow .next-button {
        height: 40px;
        width: 40px;
    }
}

.work-two .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .work-two .shape {
        display: none;
    }
}

.work-two .shape .shape1 {
    position: absolute;
    right: 0%;
    top: -65px;
    z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-two .shape .shape1 {
        top: -20px;
    }
}

.work-two .shape .shape2 {
    position: absolute;
    right: 0%;
    top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-two .shape .shape2 {
        display: flex;
        justify-content: flex-end;
    }

    .work-two .shape .shape2 img {
        width: 85%;
    }
}


/*----------------------------------------*/


/*  2.8 - Qualit Styles CSS
/*----------------------------------------*/

.quality {
    position: relative;
}

.quality-images {
    margin-right: 70px;
    /* height: 650px; */
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .quality-images {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .quality-images {
        max-width: 600px;
        width: 100%;
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .quality-images {
        margin: 0 auto 40px;
    }
}

@media only screen and (max-width: 479px) {
    .quality-images {
        height: 400px;
    }
}

.quality-images-one {
    position: relative;
}

@media only screen and (max-width: 479px) {
    .quality-images-one {
        width: 70%;
    }
}

.quality-images-two {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}

@media only screen and (max-width: 479px) {
    .quality-images-two {
        width: 50%;
    }
}

.quality-images-thumb {
    position: absolute;
    width: 98px;
    height: 98px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
    left: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 767px) {
    .quality-images-thumb {
        left: -20px;
    }
}

@media only screen and (max-width: 575px) {
    .quality-images-thumb {
        left: 10px;
    }
}

@media only screen and (max-width: 479px) {
    .quality-images-thumb {
        width: 70px;
        height: 70px;
    }
}

.quality .heading-one {
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .quality .heading-one {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .quality .heading-one {
        margin-bottom: 30px;
    }
}

.quality .heading-one-title {
    margin: 10px 0 36px;
}

@media only screen and (max-width: 767px) {
    .quality .heading-one-title {
        margin: 10px 0 20px;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .quality .shape {
        display: none;
    }
}

.quality .shape.shape-1 {
    position: absolute;
    top: 3%;
    left: 3%;
    -webkit-animation: moveupdown1 4s infinite linear;
    animation: moveupdown1 4s infinite linear;
}

.quality .shape.shape-2 {
    position: absolute;
    top: 16%;
    left: 10%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
    z-index: -1;
}

.quality .shape.shape-3 {
    position: absolute;
    top: -5%;
    right: 0%;
    -webkit-animation: moveupdown1 5s infinite linear;
    animation: moveupdown1 5s infinite linear;
}

.quality .shape.shape-4 {
    position: absolute;
    top: 12%;
    right: 8%;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
    z-index: -1;
}

.quality .shape.shape-5 {
    position: absolute;
    bottom: 12%;
    right: 0%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
    z-index: -1;
}

.faq-accordion-button {
    font-size: 24px;
    color: #343c55;
    font-weight: 700;
    padding: 0px 30px 0 50px;
}

@media only screen and (max-width: 767px) {
    .faq-accordion-button {
        padding: 0px 0px 0 20px;
        font-size: 20px;
    }
}

.faq-accordion-button:not(.collapsed) {
    color: #343c55;
    background-color: #ffffff;
    box-shadow: none;
}

.faq-accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: translateY(-50%) translateX(-50%);
}

.faq-accordion-button::after {
    background-image: none;
    position: absolute;
    display: none;
    top: 50%;
    right: 1%;
    display: block;
    width: 20px;
    height: 4px;
    content: "";
    transition: all 0.4s ease 0s;
    transform: translateY(-50%) translateX(-50%) rotate(90deg);
}

@media only screen and (max-width: 767px) {
    .faq-accordion-button::after {
        width: 10px;
        height: 2px;
    }
}


/* ::before.faq-accordion-button {
  position: absolute;
  top: 50%;
  right: 1%;
  display: block;
  width: 20px;
  height: 4px;
  content: "";
  transition: all 0.4s ease 0s;
  transform: translateY(-50%) translateX(-50%);
  background-color: #363273;
} */

@media only screen and (max-width: 767px) {
    .faq-accordion-button::before {
        width: 10px;
        height: 2px;
    }
}

.faq-accordion-button:focus {
    box-shadow: none;
}

.faq-accordion-item {
    border: 0px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .faq-accordion-item {
        margin-bottom: 20px;
    }
}

.faq-accordion-collapse .accordion-body {
    padding: 20px 20px 10px 50px;
}

@media only screen and (max-width: 767px) {
    .faq-accordion-collapse .accordion-body {
        padding: 20px 0px 10px 20px;
        font-size: 16px;
    }
}

.faq .accordion-item:last-child {
    margin-bottom: 0px;
}

.about-accordion {
    background-color: #ffffff;
}


/*----------------------------------------*/


/*  2.9 - Testimonial Styles CSS
/*----------------------------------------*/

.testimonial-one {
    position: relative;
}

.testimonial-one-inner {
    display: flex;
}

@media only screen and (max-width: 479px) {
    .testimonial-one-inner {
        flex-direction: column;
    }
}

.testimonial-one-image {
    position: absolute;
    margin-top: 120px;
    margin-left: -12px;
    width: 36%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-one-image {
        margin-top: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one-image {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-one-image {
        margin-top: 0px;
        width: 40%;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-one-image {
        margin-top: 0px;
        width: 46%;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-one-image {
        margin-top: 0px;
        width: 52%;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-one-image {
        margin-top: 0px;
        width: 63%;
    }
}

.testimonial-one-thumb {
    width: 20%;
    height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    position: absolute;
    top: 0;
    right: 140px;
}

@media only screen and (min-width: 1550px) and (max-width: 1680px) {
    .testimonial-one-thumb {
        right: 100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-one-thumb {
        height: 260px;
        width: 260px;
        right: 12px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one-thumb {
        height: 200px;
        width: 200px;
        right: 12px;
    }

    .testimonial-one-thumb img {
        width: 70%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-one-thumb {
        height: 120px;
        width: 120px;
        right: 12px;
    }

    .testimonial-one-thumb img {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-one-thumb {
        height: 100px;
        width: 100px;
        right: 12px;
        top: 100px;
    }

    .testimonial-one-thumb img {
        width: 40%;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-one-thumb {
        height: 80px;
        width: 80px;
    }

    .testimonial-one-thumb img {
        width: 40%;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-one-thumb {
        height: 80px;
        width: 80px;
        right: 12px;
        top: 18%;
    }

    .testimonial-one-thumb img {
        width: 40%;
    }
}

.testimonial-one-content {
    background-color: #f2fbfe;
    padding: 120px 160px;
    position: relative;
    right: 0;
    width: 74%;
    margin-top: 240px;
    margin-left: auto;
    margin-right: -12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-one-content {
        padding: 120px 100px;
        margin-top: 180px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one-content {
        padding: 120px 80px 100px;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-one-content {
        padding: 90px 70px;
        width: 80%;
        margin-top: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-one-content {
        padding: 50px 50px;
        width: 80%;
        margin-top: 170px;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-one-content {
        width: 100%;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-one-content {
        padding: 42px 20px;
        width: 100%;
        margin-top: 170px;
        margin-right: auto;
    }
}

.testimonial-one .testimonialone {
    overflow: hidden;
    position: relative;
    padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
    .testimonial-one .testimonialone {
        padding-bottom: 60px;
    }
}

.testimonial-one .testimonialone-single {
    max-width: 870px;
    width: 100%;
}

.testimonial-one .testimonialone-content {
    display: flex;
    align-items: center;
}

.testimonial-one .testimonialone-text {
    color: #717788;
    font-size: 30px;
    font-style: italic;
    line-height: 1.4;
    margin: 70px 0 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one .testimonialone-text {
        margin: 40px 0 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one .testimonialone-text {
        font-size: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-one .testimonialone-text {
        font-size: 22px;
        margin: 30px 0 40px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-one .testimonialone-text {
        font-size: 20px;
        margin: 20px 0 30px;
    }
}

.testimonial-one .testimonialone-info {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

@media only screen and (max-width: 767px) {
    .testimonial-one .testimonialone-info {
        margin-left: 15px;
    }
}

.testimonial-one .testimonialone-info .name {
    font-size: 24px;
    font-weight: 700;
    color: #343c55;
}

@media only screen and (max-width: 767px) {
    .testimonial-one .testimonialone-info .name {
        font-size: 20px;
    }
}

.testimonial-one .testimonialone-info .designation {
    color: #717788;
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    .testimonial-one .testimonialone-info .designation {
        margin-top: 5px;
    }
}

.testimonial-one .testimonialone-pagination {
    text-align: start;
    bottom: 0;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-one .shape {
        display: none;
    }
}

.testimonial-one .shape.shape-1 {
    position: absolute;
    top: -15%;
    left: 4%;
    -webkit-animation: moveupdown1 4s infinite linear;
    animation: moveupdown1 4s infinite linear;
}

.testimonial-one .shape.shape-2 {
    position: absolute;
    top: -3%;
    left: 14%;
    -webkit-animation: circle1 3s infinite linear;
    animation: circle1 3s infinite linear;
}


/* Testimonial Two */

.testimonial-two {
    position: relative;
}

.testimonial-two .heading-one {
    text-align: center;
    margin: 0 auto 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-two .heading-one {
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-two .heading-one {
        margin: 0 auto 30px;
    }
}

.testimonial-two-single {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.07);
    border-radius: 13px;
    padding: 0 50px 50px;
}

.testimonial-two-single .quote {
    padding: 35px 0px;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 90px;
    margin: 0 auto 40px;
    border-radius: 0 0 7px 7px;
}

.testimonial-two-single .text {
    font-style: italic;
    font-size: 21px;
    line-height: 1.42;
    margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
    .testimonial-two-single .text {
        font-size: 18px;
    }
}

.testimonial-two-single .user {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.testimonial-two-single .user-meta {
    display: flex;
    flex-direction: column;
}

.testimonial-two-single .user-meta .name {
    font-size: 21px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
    color: #343c55;
}

.testimonial-two .testimonialtwo {
    overflow: hidden;
    margin: 0 -15px;
}

.testimonial-two .testimonialtwo .swiper-slide {
    padding: 15px 15px;
}

@media only screen and (max-width: 767px) {
    .testimonial-two .testimonialtwo {
        margin: 0 0;
    }
}

.testimonial-two .testimonial-two-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    width: 100%;
}

.testimonial-two .testimonial-two-arrow .swiper-arrow-long {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonial-two .testimonial-two-arrow .swiper-arrow-long .prev-button {
    left: -8.5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-two .testimonial-two-arrow .swiper-arrow-long .prev-button {
        left: -2%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .testimonial-two .testimonial-two-arrow .swiper-arrow-long .prev-button {
        left: -1%;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-two .testimonial-two-arrow .swiper-arrow-long .prev-button {
        left: 0%;
    }
}

.testimonial-two .testimonial-two-arrow .swiper-arrow-long .next-button {
    right: -8.5%;
    left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-two .testimonial-two-arrow .swiper-arrow-long .next-button {
        right: -2%;
        left: auto;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .testimonial-two .testimonial-two-arrow .swiper-arrow-long .next-button {
        right: -1%;
        left: auto;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-two .testimonial-two-arrow .swiper-arrow-long .next-button {
        right: 0%;
        left: auto;
    }
}

.testimonial-two .shape {
    position: absolute;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-two .shape {
        display: none;
    }
}

.testimonial-two .shape.shape-1 {
    top: -140px;
    right: 135px;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-two .shape.shape-1 {
        display: none;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-two .shape.shape-1 {
        right: 20px;
        top: -80px;
    }
}

.swiper-arrow-long .prev-button .arrow-2 {
    opacity: 0;
    transition: 0.3s;
    transform: rotate(180deg);
    position: absolute;
    top: 43%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .swiper-arrow-long .prev-button .arrow-2 {
        top: 40%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .swiper-arrow-long .prev-button .arrow-2 {
        top: 40%;
    }
}

.swiper-arrow-long .next-button .arrow-1 {
    transform: rotate(-180deg);
}

.swiper-arrow-long .next-button .arrow-2 {
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    top: 39%;
    left: 33%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .swiper-arrow-long .next-button .arrow-2 {
        top: 36%;
        left: 28%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .swiper-arrow-long .next-button .arrow-2 {
        top: 36%;
        left: 28%;
    }
}

.swiper-arrow-long .prev-button,
.swiper-arrow-long .next-button {
    height: 60px;
    width: 60px;
    background-color: #ffffff;
    border: 1px solid #d5312c;
    border-radius: 100%;
    transition: 0.3s;
    position: relative;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .swiper-arrow-long .prev-button,
    .swiper-arrow-long .next-button {
        height: 50px;
        width: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {

    .swiper-arrow-long .prev-button,
    .swiper-arrow-long .next-button {
        height: 45px;
        width: 45px;
    }
}

.swiper-arrow-long .prev-button:hover,
.swiper-arrow-long .next-button:hover {
    background-color: #d5312c;
    box-shadow: 15px 11px 48px 0px rgba(0, 0, 0, 0.13);
}

.swiper-arrow-long .prev-button:hover .arrow-2,
.swiper-arrow-long .next-button:hover .arrow-2 {
    opacity: 1;
    transition: 0.3s;
}

.swiper-arrow-long .prev-button:hover .arrow-1,
.swiper-arrow-long .next-button:hover .arrow-1 {
    opacity: 0;
    transition: 0.3s;
}


/* Testimonial Three Style */

.testimonial-three {
    position: relative;
}

.testimonial-three .heading-one {
    max-width: 600px;
    margin-bottom: 60px;
}

.testimonial-three .heading-one-subtitle {
    display: block;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three .heading-one {
        max-width: 500px;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three .heading-one {
        max-width: 400px;
        margin-bottom: 30px;
    }
}

.testimonial-three .vertical-testimonial {
    height: 666px;
}

.testimonial-three .vertical-testimonial .swiper-slide {
    padding: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three .vertical-testimonial {
        height: 715px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three .vertical-testimonial {
        height: 620px;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-three .vertical-testimonial {
        height: 520px;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three .vertical-testimonial {
        height: 340px;
    }
}

.testimonial-three-single-content {
    padding: 50px;
    border-radius: 13px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 0px rgba(0, 0, 0, 0.07);
    max-width: 570px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-single-content {
        max-width: 640px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-single-content {
        right: 0px;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-three-single-content {
        padding: 25px;
    }
}

.testimonial-three-single-content .text {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-style: italic;
    font-size: 21px;
    color: #717788;
}

@media only screen and (max-width: 767px) {
    .testimonial-three-single-content .text {
        font-size: 17px;
    }
}

.testimonial-three-single-content .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonial-three-single-content .bottom .client-description {
    display: flex;
    align-items: center;
    -moz-column-gap: 30px;
    column-gap: 30px;
}

@media only screen and (max-width: 479px) {
    .testimonial-three-single-content .bottom .client-description {
        -moz-column-gap: 15px;
        column-gap: 15px;
    }
}

.testimonial-three-single-content .bottom .client-description .name {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 21px;
}

@media only screen and (max-width: 575px) {
    .testimonial-three-single-content .bottom .client-description .name {
        font-size: 18px;
    }
}

.testimonial-three .vertical-pagination .swiper-pagination {
    height: 330px;
    background: #d6edf7;
    border-radius: 5px;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    right: 15px;
}

.testimonial-three .vertical-pagination .swiper-pagination .swiper-pagination-bullet {
    background: #d6edf7;
}

.testimonial-three .vertical-pagination .swiper-pagination .swiper-pagination-bullet-active {
    background: #47bfe7;
    width: 6px;
    height: 56px;
    border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three .vertical-pagination .swiper-pagination {
        right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three .vertical-pagination .swiper-pagination {
        right: 0px;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three .vertical-pagination .swiper-pagination {
        height: 230px;
    }
}

.testimonial-three-images {
    position: relative;
    height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images {
        margin-bottom: 30px;
    }
}

.testimonial-three-images .image1 {
    position: absolute;
    top: -35px;
    right: 60px;
}

.testimonial-three-images .image1 img {
    -webkit-animation: zoom1 2s infinite linear;
    animation: zoom1 2s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three-images .image1 {
        right: 130px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three-images .image1 {
        right: 130px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images .image1 {
        position: relative;
        top: 0px;
        margin-left: 56%;
        right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images .image1 {
        position: relative;
        top: 0px;
        margin-left: 56%;
        right: auto;
    }
}

.testimonial-three-images .image2 {
    position: absolute;
    top: 100px;
    right: 30%;
}

.testimonial-three-images .image2 img {
    -webkit-animation: zoom1 5s infinite linear;
    animation: zoom1 5s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three-images .image2 {
        right: 46%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three-images .image2 {
        right: 46%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images .image2 {
        position: relative;
        top: 25px;
        margin-left: 18%;
        right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images .image2 {
        position: relative;
        top: 25px;
        margin-left: 18%;
        right: auto;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-three-images .image2 img {
        width: 50%;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three-images .image2 {
        top: -40px;
        margin-left: 5%;
    }

    .testimonial-three-images .image2 img {
        width: 40%;
    }
}

.testimonial-three-images .image3 {
    position: absolute;
    top: 145px;
    right: -110px;
}

.testimonial-three-images .image3 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .testimonial-three-images .image3 {
        right: -30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three-images .image3 {
        right: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three-images .image3 {
        right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images .image3 {
        position: relative;
        margin-top: -180px;
        margin-left: 67%;
        top: auto;
        right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images .image3 {
        position: relative;
        margin-top: -180px;
        margin-left: 67%;
        top: auto;
        right: auto;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three-images .image3 {
        margin-top: -100px;
        margin-left: 60%;
    }

    .testimonial-three-images .image3 img {
        width: 65%;
    }
}

.testimonial-three-images .image4 {
    position: absolute;
    top: 330px;
    right: -304px;
}

.testimonial-three-images .image4 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1650px) and (max-width: 1800px) {
    .testimonial-three-images .image4 {
        right: -230px;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1649px) {
    .testimonial-three-images .image4 {
        top: 310px;
        right: -180px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .testimonial-three-images .image4 {
        top: 300px;
        right: -100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three-images .image4 {
        top: 300px;
        right: -30px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three-images .image4 {
        top: 300px;
        right: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images .image4 {
        position: relative;
        margin-top: 85px;
        margin-left: 79%;
        top: auto;
        right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images .image4 {
        position: relative;
        margin-top: 85px;
        margin-left: 79%;
        top: auto;
        right: auto;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three-images .image4 {
        margin-top: 100px;
        margin-left: 74%;
        bottom: 80px;
    }

    .testimonial-three-images .image4 img {
        width: 68%;
    }
}

.testimonial-three-images .image5 {
    position: absolute;
    bottom: 70px;
    left: 90px;
}

.testimonial-three-images .image5 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three-images .image5 {
        left: 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three-images .image5 {
        left: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images .image5 {
        position: relative;
        bottom: 70px;
        margin-left: 1%;
        left: auto;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images .image5 {
        position: relative;
        bottom: 70px;
        margin-left: 1%;
        left: auto;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three-images .image5 {
        bottom: 120px;
    }

    .testimonial-three-images .image5 img {
        width: 25%;
    }
}

.testimonial-three-images .image6 {
    position: absolute;
    bottom: 30px;
    right: -64px;
}

.testimonial-three-images .image6 img {
    -webkit-animation: zoom1 6s infinite linear;
    animation: zoom1 6s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three-images .image6 {
        right: 45px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three-images .image6 {
        right: 15px;
        bottom: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-three-images .image6 {
        position: relative;
        margin-top: -200px;
        margin-left: 39%;
        bottom: auto;
        right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-three-images .image6 {
        position: relative;
        margin-top: -200px;
        margin-left: 39%;
        bottom: auto;
        right: auto;
    }
}

@media only screen and (max-width: 575px) {
    .testimonial-three-images .image6 img {
        width: 60%;
    }
}

@media only screen and (max-width: 479px) {
    .testimonial-three-images .image6 {
        margin-top: -220px;
        margin-left: 33%;
    }

    .testimonial-three-images .image6 img {
        width: 50%;
    }
}

.testimonial-three .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .testimonial-three .shape {
        display: none;
    }
}

.testimonial-three .shape .shape1 {
    position: absolute;
    left: 0%;
    top: 20px;
    z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three .shape .shape1 img {
        width: 85%;
    }
}

.testimonial-three .shape .shape2 {
    position: absolute;
    left: 0%;
    top: 20%;
    z-index: 1;
}

.testimonial-three .shape .shape3 {
    position: absolute;
    left: 55px;
    top: 46%;
    z-index: 1;
}

.testimonial-three .shape .shape4 {
    position: absolute;
    right: 0%;
    top: -80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three .shape .shape4 {
        right: 2%;
        top: 4%;
    }
}

.testimonial-three .shape .shape5 {
    position: absolute;
    right: 0%;
    top: 60px;
}

.testimonial-three .shape .shape6 {
    position: absolute;
    right: 33%;
    top: 23%;
}

.testimonial-three .shape .shape7 {
    position: absolute;
    right: 5.5%;
    top: 15%;
}

.testimonial-three .shape .shape8 {
    position: absolute;
    right: 2%;
    top: 42%;
}

.testimonial-three .shape .shape9 {
    position: absolute;
    right: 24%;
    top: 61%;
}

@media only screen and (min-width: 1550px) and (max-width: 1649px) {
    .testimonial-three .shape .shape9 {
        right: 20%;
        top: 63%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-three .shape .shape9 {
        right: 20%;
        top: 63%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three .shape .shape9 {
        right: 20%;
        top: 63%;
    }
}

.testimonial-three .shape .shape10 {
    position: absolute;
    right: 39%;
    top: 67%;
}

.testimonial-three .shape .shape11 {
    position: absolute;
    right: 5.5%;
    bottom: -7%;
}


/*----------------------------------------*/


/*  2.10 - Brand Styles CSS
/*----------------------------------------*/

.brand-style .brand-before {
    display: block;
    transition: all 0.4s;
}

.brand-style .brand-after {
    top: 150%;
    position: absolute;
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    transition: all 0.4s;
}

.brand-style .swiper-slide:hover .brand-before {
    transform: translateY(-100%);
}

.brand-style .swiper-slide:hover .brand-after {
    top: 0%;
}


/* Brand Two Style */

.brand-style-two {
    background-color: #f2f5fb;
    border-radius: 10px;
    padding: 110px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brand-style-two {
        padding: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-style-two {
        padding: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-style-two {
        padding: 60px;
    }
}

@media only screen and (max-width: 479px) {
    .brand-style-two {
        padding: 40px 30px;
    }
}

.brand-style-two .shape-one {
    position: absolute;
    bottom: -105px;
    right: -105px;
    z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .brand-style-two .shape-one {
        right: -35px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brand-style-two .shape-one {
        right: -25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .brand-style-two .shape-one {
        right: -30px;
    }
}

@media only screen and (max-width: 575px) {
    .brand-style-two .shape-one {
        right: 0px;
        bottom: -40px;
        width: 35%;
    }
}


/* Brand Three Style */

.brand-three {
    margin-top: 70px;
    position: relative;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-three .shape {
        display: none;
    }
}

.brand-three .shape.shape-1 {
    position: absolute;
    top: -190%;
    left: 7%;
    -webkit-animation: moveupdown1 4s infinite linear;
    animation: moveupdown1 4s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .brand-three .shape.shape-1 {
        left: 3%;
        top: -240%;
    }
}

@media only screen and (min-width: 140px) and (max-width: 1399px) {
    .brand-three .shape.shape-1 {
        display: none;
    }
}

.brand-three .shape.shape-2 {
    position: absolute;
    top: 10%;
    left: 6%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .brand-three .shape.shape-2 {
        left: 2%;
    }
}

@media only screen and (min-width: 140px) and (max-width: 1399px) {
    .brand-three .shape.shape-2 {
        display: none;
    }
}

.brand-three .shape.shape-3 {
    position: absolute;
    top: -89%;
    left: 13%;
    -webkit-animation: circle1 5s infinite linear;
    animation: circle1 5s infinite linear;
    z-index: -1;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .brand-three .shape.shape-3 {
        left: 10%;
        top: -153%;
    }
}

@media only screen and (min-width: 140px) and (max-width: 1399px) {
    .brand-three .shape.shape-3 {
        display: none;
    }
}


/* Brand Four Style */

.brand-four {
    /* background-image: url(../public/assets//images/brand/brand-three/brand-bg.png); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0 395px;
    margin-bottom: -1px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-four {
        padding: 80px 0 355px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-four {
        padding: 60px 0 335px;
    }
}

.brand-four .shape {
    position: absolute;
}

.brand-four .shape.shape-1 {
    top: -100px;
    left: 90px;
}

.brand-four .shape.shape-1 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-four .shape.shape-1 img {
        width: 60%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-four .shape.shape-1 {
        left: 20px;
        top: -60px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-four .shape.shape-1 {
        display: none;
    }
}

.brand-four .shape.shape-2 {
    bottom: 100px;
    right: 310px;
}

.brand-four .shape.shape-2 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (max-width: 767px) {
    .brand-four .shape.shape-2 {
        display: none;
    }
}


/* Brand Five Style */

.brand-five {
    margin: 100px 0 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brand-five {
        margin: 90px 0 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-five {
        margin: 60px 0 80px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-five {
        margin: 40px 0 60px;
    }
}

.brand-five .heading-one {
    max-width: 500px;
    margin: 0 auto 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brand-five .heading-one {
        margin: 0 auto 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-five .heading-one {
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-five .heading-one {
        margin: 0 auto 40px;
    }
}

.brand-five .heading-one-subtitle {
    display: block;
    margin-bottom: 10px;
}


/*----------------------------------------*/


/*  2.11 - Blog Styles CSS
/*----------------------------------------*/


/* Blog Post */

.blog-post {
    position: relative;
}

.blog-post-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-post-top {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 575px) {
    .blog-post-top {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;
    }
}

.blog-post-top .heading-one {
    max-width: 560px;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-post-top .heading-one {
        max-width: 500px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-post-top .heading-one {
        max-width: 320px;
    }
}

@media only screen and (max-width: 575px) {
    .blog-post-top .heading-one {
        margin-bottom: 30px;
    }
}

.blog-post-top .heading-one-title {
    margin: 5px 0 35px;
}

@media only screen and (max-width: 767px) {
    .blog-post-top .heading-one-title {
        margin: 5px 0 20px;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-post .shape {
        display: none;
    }
}

.blog-post .shape.shape-1 {
    position: absolute;
    top: 9%;
    right: 7%;
    -webkit-animation: moveupdown1 4s infinite linear;
    animation: moveupdown1 4s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .blog-post .shape.shape-1 {
        right: 2%;
    }
}

.blog-post .shape.shape-2 {
    position: absolute;
    bottom: 30%;
    left: 5%;
    -webkit-animation: circle1 3s infinite linear;
    animation: circle1 3s infinite linear;
    z-index: -1;
}

.blog-post .shape.shape-3 {
    position: absolute;
    bottom: 22%;
    left: 9%;
    -webkit-animation: circle1 5s infinite linear;
    animation: circle1 5s infinite linear;
    z-index: -1;
}

.blog-post .shape.shape-4 {
    position: absolute;
    bottom: 21%;
    right: 12%;
    -webkit-animation: circle1 3s infinite linear;
    animation: circle1 3s infinite linear;
    z-index: -1;
}

.blog-post .shape.shape-5 {
    position: absolute;
    bottom: 3%;
    right: 4%;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
    z-index: -1;
}

.blog-post .shape.shape-6 {
    position: absolute;
    bottom: 1%;
    right: 2%;
    -webkit-animation: circle1 3s infinite linear;
    animation: circle1 3s infinite linear;
    z-index: -1;
}

.blog-post-two {
    position: relative;
}

.blog-post-two .heading-one {
    max-width: 570px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;
}

.blog-post-two .heading-one-title {
    margin: 10px 0 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-two .heading-one {
        margin-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-post-two .heading-one {
        margin-bottom: 60px;
    }
}

.blog-post-two .blog-grid-content {
    box-shadow: none;
    padding: 35px 0 0;
}

.blog-post-two .blog-grid-content .title {
    margin-bottom: 0;
    margin-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .blog-post-two .blog-grid-content {
        padding: 25px 0 0;
    }
}

.blog-post-two .blogpost-image {
    border-radius: 7px;
}

.blog-post-two .shape {
    position: absolute;
}

.blog-post-two .shape.shape-1 {
    top: 0px;
    left: 0px;
    z-index: -2;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .blog-post-two .shape.shape-1 {
        display: none;
    }
}

.blog-post-two .shape.shape-2 {
    bottom: 230px;
    left: 0px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .blog-post-two .shape.shape-2 {
        display: none;
    }
}

.blog-post-two .shape.shape-3 {
    right: 140px;
    top: 0;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .blog-post-two .shape.shape-3 {
        right: 40px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .blog-post-two .shape.shape-3 {
        right: 40px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .blog-post-two .shape.shape-3 {
        right: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-two .shape.shape-3 {
        right: 20px;
        margin-top: -60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-post-two .shape.shape-3 {
        right: 20px;
        margin-top: -35px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-post-two .shape.shape-3 {
        display: none;
    }
}

.blog-post-three {
    position: relative;
}

.blog-post-three-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin: 0 auto 40px;
    position: relative;
    z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three-top {
        margin: 0 auto 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-post-three-top {
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-post-three-top {
        margin: 0 auto 40px;
    }
}

@media only screen and (max-width: 575px) {
    .blog-post-three-top {
        flex-direction: column;
        align-items: start;
        row-gap: 20px;
    }
}

.blog-post-three-top .heading-one {
    max-width: 500px;
}

.blog-post-three-top .heading-one-subtitle {
    display: block;
    margin-bottom: 10px;
}

.blog-post-three-top .more-blog-button {
    display: flex;
}

.blog-post-three-single {
    position: relative;
    overflow: hidden;
    border-radius: 13px;
}

.blog-post-three-single .image {
    transform: scale(1);
    transition: 0.3s;
    display: block;
}

@media only screen and (max-width: 575px) {
    .blog-post-three-single .image img {
        width: 100%;
    }
}

.blog-post-three-single .content {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: #ffffff;
    border-radius: 13px;
    padding: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three-single .content {
        padding: 25px 20px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-post-three-single .content {
        left: 10px;
        right: 10px;
        bottom: 10px;
        padding: 20px 15px 15px;
    }
}

.blog-post-three-single .content-subtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #47bfe7;
    text-transform: uppercase;
    line-height: 0.5;
}

@media only screen and (max-width: 767px) {
    .blog-post-three-single .content-subtitle {
        font-size: 14px;
    }
}

.blog-post-three-single .content-title {
    font-weight: 700;
    font-size: 21px;
    line-height: 1.4;
    margin-top: 10px;
    display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three-single .content-title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-post-three-single .content-title {
        font-size: 18px;
    }
}

.blog-post-three-single:hover .image {
    transform: scale(1.1);
    transition: 0.3s;
}

.blog-post-three .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .blog-post-three .shape {
        display: none;
    }
}

.blog-post-three .shape .shape1 {
    position: absolute;
    left: 145px;
    top: 122px;
}

@media only screen and (min-width: 1651px) and (max-width: 1770px) {
    .blog-post-three .shape .shape1 {
        left: 5%;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1649px) {
    .blog-post-three .shape .shape1 {
        left: 1%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape1 {
        left: 0;
        top: 172px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape1 {
        left: 0;
        top: 172px;
    }

    .blog-post-three .shape .shape1 img {
        width: 50%;
    }
}

.blog-post-three .shape .shape2 {
    position: absolute;
    left: 0%;
    top: 74%;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape2 img {
        width: 50%;
    }
}

.blog-post-three .shape .shape3 {
    position: absolute;
    left: 0px;
    top: 23%;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape3 img {
        width: 80%;
    }
}

.blog-post-three .shape .shape4 {
    position: absolute;
    right: 80px;
    top: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape4 {
        right: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape4 {
        top: 100px;
    }

    .blog-post-three .shape .shape4 img {
        width: 50%;
    }
}

.blog-post-three .shape .shape5 {
    position: absolute;
    right: 0%;
    top: -34%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape5 {
        display: flex;
        justify-content: flex-end;
        top: 0;
    }

    .blog-post-three .shape .shape5 img {
        width: 60%;
    }
}

.blog-post-three .shape .shape6 {
    position: absolute;
    right: 0%;
    top: 23%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-post-three .shape .shape6 {
        display: flex;
        justify-content: flex-end;
        top: 60%;
    }

    .blog-post-three .shape .shape6 img {
        width: 50%;
    }
}

.blog-post-three .shape .shape7 {
    position: absolute;
    right: 16.5%;
    bottom: -14%;
    z-index: 9;
}

.blogpost {
    position: relative;
}

.img-br-10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.pa {
    position: absolute;
    top: 0px;
}

.bg-g {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9514006286108193) 0%, rgba(255, 255, 255, 0) 33%);
}

.d-table {
    display: table-cell;
    vertical-align: bottom;
}

.blogpost-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.blogpost-image-thumb {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #3754b2;
    border-radius: 5px;
    color: #ffffff;
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogpost-image-thumb .date {
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
}

.blogpost-image-thumb .text {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 3px;
    line-height: 1;
}

.blogpost-image img {
    width: 100%;
    transition: 0.4s;
    transform: scale(1);
}

.blogpost-image:hover img {
    transform: scale(1.1);
    transition: 0.4s;
}

.blogpost-image:hover .blogpost-content {
    box-shadow: 0px 18px 48px 0px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    transition: 0.4s;
}

.blogpost-content {
    padding: 30px;
    border-radius: 0 0 7px 7px;
    background-color: #f2fbfe;
    transition: 0.4s;
}

@media only screen and (max-width: 767px) {
    .blogpost-content {
        padding: 20px 14px;
    }
}

.blogpost-meta {
    display: flex;
    margin-bottom: 10px;
}

.blogpost-meta li {
    margin-right: 15px;
}

.blogpost-meta li:last-child {
    margin-right: 0;
}

.blogpost-meta li i {
    color: #3754b2;
    margin-right: 5px;
}

.blogpost-meta li a {
    color: #717788;
    font-size: 16px;
}

@media only screen and (max-width: 767px) {
    .blogpost-meta li a {
        font-size: 14px;
    }
}

.blogpost-meta li a:hover {
    color: #322fb3;
}

.blogpost-title {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.42;
}

@media only screen and (max-width: 767px) {
    .blogpost-title {
        font-size: 18px;
    }
}

.blogpost-read-more {
    color: #3754b2;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 15px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .blogpost-read-more {
        font-size: 14px;
        margin-top: 10px;
    }
}

.blogpost-read-more:hover {
    color: #343c55;
}

.blogpost:hover .blogpost-content {
    box-shadow: 0px 18px 48px 0px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    transition: 0.4s;
}


/* Standard Blog */

@media only screen and (max-width: 767px) {
    .standard-blog-single {
        margin-bottom: 46px;
    }
}

.standard-blog-image {
    display: block;
}

.standard-blog-image img {
    width: 100%;
}

.standard-blog-content {
    margin-top: 36px;
}

@media only screen and (max-width: 767px) {
    .standard-blog-content {
        margin-top: 25px;
    }
}

.standard-blog-content p {
    margin-bottom: 0px;
}

.standard-blog-meta {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.standard-blog-meta li {
    position: relative;
    margin-right: 10px;
    padding-right: 20px;
}

.standard-blog-meta li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.standard-blog-meta li a {
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: #d5312c;
}

.standard-blog-meta li a:hover {
    color: #322fb3;
}

@media only screen and (max-width: 767px) {
    .standard-blog-meta li a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .standard-blog-meta li p {
        font-size: 14px;
    }
}

.standard-blog-meta li::after {
    position: absolute;
    content: "";
    background-color: #bbbbbb;
    width: 1px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.standard-blog-meta li:last-child::after {
    display: none;
}

.standard-blog-title {
    font-size: 36px;
    font-weight: 700;
    margin: 10px 0 20px;
}

@media only screen and (max-width: 767px) {
    .standard-blog-title {
        font-size: 30px;
    }
}

.standard-blog-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .standard-blog-bottom {
        margin-top: 25px;
    }
}

.standard-blog-social {
    display: flex;
    align-items: center;
}

.standard-blog-social-label {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-transform: uppercase;
    margin-right: 25px;
    line-height: 1;
}

.standard-blog-social ul {
    display: flex;
    align-items: center;
    gap: 15px;
}

.standard-blog-social ul li a {
    font-size: 18px;
    line-height: 1;
}


/* Classic Blog */

.classic-blog-single {
    display: flex;
    align-items: center;
    gap: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .classic-blog-single {
        gap: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classic-blog-single {
        gap: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .classic-blog-single {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        margin-bottom: 46px;
    }
}

.classic-blog-single .standard-blog-content {
    margin-top: 0;
    max-width: 670px;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .classic-blog-single .standard-blog-content {
        max-width: 380px;
    }
}

@media only screen and (max-width: 767px) {
    .classic-blog-single .standard-blog-content {
        max-width: 100%;
    }

    .header-wrapper-two {
        padding: 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .classic-blog-single .standard-blog-content p {
        margin-bottom: 0px;
    }
}

.classic-blog-single:nth-child(2n) {
    flex-direction: row-reverse;
}

@media only screen and (max-width: 767px) {
    .classic-blog-single:nth-child(2n) {
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .classic-blog-single .standard-blog-bottom {
        margin-top: 25px;
    }
}

.classic-blog-single .standard-blog-image {
    display: block;
    overflow: hidden;
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    .classic-blog-single .standard-blog-image {
        width: 100%;
    }
}

.classic-blog-single img {
    transform: scale(1);
    transition: 0.4s;
}

.classic-blog-single:hover img {
    transform: scale(1.1);
    transition: 0.4s;
}


/* Blog Grid */

.blog-grid-image {
    position: relative;
}

.blog-grid-image a {
    display: block;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
}

.blog-grid-image a img {
    width: 100%;
    transition: 0.4s;
    transform: scale(1);
}

.blog-grid-image-thumb {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 73px;
    height: 73px;
    border-radius: 7px;
    bottom: -25px;
    right: 35px;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .blog-grid-image-thumb {
        width: 67px;
        height: 67px;
        right: 10px;
    }
}

.blog-grid-image-thumb::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 20%, #417dd2 50%, #322fb3 100%);
    opacity: 0.93;
    z-index: 1;
}

.blog-grid-image-thumb .date {
    position: relative;
    color: #ffffff;
    z-index: 3;
    font-size: 27px;
    font-weight: 700;
    line-height: 1;
}

@media only screen and (max-width: 767px) {
    .blog-grid-image-thumb .date {
        font-size: 24px;
    }
}

.blog-grid-image-thumb .text {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 16px;
    z-index: 3;
    position: relative;
    font-weight: 700;
}

@media only screen and (max-width: 767px) {
    .blog-grid-image-thumb .text {
        font-size: 14px;
    }
}

.blog-grid-content {
    box-shadow: 0px 18px 48px 0px rgba(0, 0, 0, 0.05);
    padding: 30px 35px 40px;
    transition: 0.4s;
}

@media only screen and (max-width: 767px) {
    .blog-grid-content {
        padding: 26px 20px 30px;
    }
}

.blog-grid-content .subtitle {
    color: #d5312c;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
}

.blog-grid-content .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.42;
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .blog-grid-content .title {
        font-size: 18px;
    }
}

.blog-grid-single:hover img {
    transform: scale(1.1);
    transition: 0.4s;
}

.blog-grid-single:hover .blog-grid-content {
    box-shadow: 0px 18px 48px 0px rgba(0, 0, 0, 0.1);
    background-color: #f2fbfe;
    transition: 0.4s;
}

.blog-grid-pagination {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-grid-pagination {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-grid-pagination {
        margin-top: 40px;
    }
}


/* Blog Masonry */

.blog-menu-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px 0 60px;
}

.blog-masonry-button {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-masonry-button {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-masonry-button {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-menu-style {
        margin: 0px 0 50px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-menu-style {
        margin: 0px 0 30px;
    }
}

.blog-menu-style .btn-blog {
    border: 0px;
    background-color: transparent;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-right: 35px;
    color: #343c55;
    text-transform: uppercase;
}

.blog-menu-style .btn-blog:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 767px) {
    .blog-menu-style .btn-blog {
        font-size: 16px;
    }
}

.blog-menu-style .btn-blog:last-child {
    margin-right: 0px;
}

.blog-menu-style .btn-blog:hover {
    color: #322fb3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-menu-style .btn-blog {
        margin: 0px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-menu-style .btn-blog {
        margin: 0px 10px;
    }
}

.blog-menu-style .btn-blog.active {
    color: #322fb3;
}

.blog-more-btn {
    width: 190px;
    height: 56px;
    color: #343c55;
    font-size: 16px;
    position: relative;
    border-radius: 7px;
    text-transform: uppercase;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .blog-more-btn {
        width: 160px;
        height: 46px;
    }
}

.blog-more-btn span {
    background-color: #ffffff;
    width: 98%;
    height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    transition: 0.6s;
}

.blog-more-btn span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(110deg, #322fb3 20%, #417dd2 50%, #d5312c 100%);
    z-index: -1;
    opacity: 0;
    transition: 0.6s;
    border-radius: 7px;
}

.blog-more-btn:hover span {
    width: 100%;
    height: 100%;
    color: #ffffff;
    transition: 0.6s;
}

.blog-more-btn:hover span::before {
    opacity: 1;
    transition: 0.6s;
}

.bg-gradient-4 {
    padding-top: 150px;
    background: rgba(50, 47, 179, 0.1);
    background: linear-gradient(0deg, rgba(50, 47, 179, 0.1) 0%, rgba(79, 203, 241, 0.1) 100%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bg-gradient-4 {
        padding-top: 120px;
    }
}

@media only screen and (max-width: 767px) {
    .bg-gradient-4 {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 575px) {
    .bg-gradient-4 {
        padding-top: 80px;
    }
}


/*----------------------------------------*/


/*  2.12 - Footer Styles CSS
/*----------------------------------------*/

.footer {
    position: relative;
    overflow: hidden;
}

.footer-left {
    background-color: #322fb3;
    padding: 120px 100px 280px 0;
    position: relative;
    border-radius: 0 3px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-left {
        padding: 120px 40px 280px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-left {
        padding: 60px 60px 160px 0;
        border-radius: 0 0px 0 0;
        margin-top: 195px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-left {
        padding: 60px 0;
        margin-top: 256px;
    }

    .footer-left::before {
        background-color: #322fb3;
        content: "";
        height: 100%;
        left: 98%;
        position: absolute;
        top: 0;
        width: 5000px;
        z-index: -1;
    }
}

.footer-left::after {
    background-color: #322fb3;
    content: "";
    height: 100%;
    right: 98%;
    position: absolute;
    top: 0;
    width: 5000px;
    z-index: -1;
}

.footer-left-text {
    color: #abb1c3;
    font-size: 16px;
    margin: 40px 0;
}

@media only screen and (max-width: 767px) {
    .footer-left-text {
        margin: 30px 0;
    }
}

.footer-left-list-icon li {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
}

.footer-left-list-icon li i {
    color: #abb1c3;
}

.footer-left-list-icon li span {
    color: #abb1c3;
    font-size: 16px;
    margin-left: 20px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.footer-left .social-icon {
    margin-top: 60px;
}

@media only screen and (max-width: 767px) {
    .footer-left .social-icon {
        margin-top: 40px;
    }
}

.footer-left .social-icon li {
    margin-right: 25px;
}

.footer-left .social-icon a {
    color: #ffffff;
}

.footer-left .social-icon a:hover {
    color: #d5312c;
}

.footer-right {
    margin-top: 73px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .footer-right {
        margin-top: 0px;
    }
}

.footer-top {
    background-color: #d5312c;
    position: relative;
    padding: 60px 0 60px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-top {
        padding: 60px 0 60px 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-top {
        padding: 40px 40px 40px 40px;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .footer-top {
        padding: 40px 12px 40px 0px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .footer-top::before {
        background-color: #d5312c;
        content: "";
        height: 100%;
        right: 98%;
        position: absolute;
        top: 0;
        width: 5000px;
        z-index: -1;
    }
}

.footer-top::after {
    background-color: #d5312c;
    content: "";
    height: 100%;
    left: 98%;
    position: absolute;
    top: 0;
    width: 5000px;
    z-index: -1;
}

.footer-top .heading-one-subtitle {
    color: #ffffff;
}

.footer-top .heading-one-title {
    color: #ffffff;
    font-size: 36px;
    margin-bottom: 18px;
    margin-top: 5px;
}

@media only screen and (max-width: 767px) {
    .footer-top .heading-one-title {
        font-size: 30px;
        margin-bottom: 12px;
    }
}

.footer-top .heading-one p {
    color: #ffffff;
    font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-top .btn-style-three {
        padding: 20px 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-top .btn-style-three {
        padding: 20px 30px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-top .btn-style-three {
        margin-top: 30px;
    }
}

.footer-bottom {
    background-color: #343c55;
    position: relative;
    padding: 120px 0 173px 100px;
    display: flex;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer-bottom {
        padding: 120px 0 173px 100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-bottom {
        padding: 94px 0 174px 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-bottom {
        padding: 60px 0 172px 40px;
        flex-wrap: wrap;
        margin-top: 196px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-bottom {
        padding: 60px 0 173px 0px;
        flex-wrap: wrap;
    }

    .footer-bottom::before {
        background-color: #343c55;
        content: "";
        height: 100%;
        right: 98%;
        position: absolute;
        top: 0;
        width: 5000px;
        z-index: -1;
    }
}

@media only screen and (max-width: 479px) {
    .footer-bottom {
        padding: 60px 0 150px 0px;
    }
}

.footer-bottom::after {
    background-color: #343c55;
    content: "";
    height: 100%;
    left: 98%;
    position: absolute;
    top: 0;
    width: 5000px;
    z-index: -1;
}

.footer-widget {
    max-width: 160px;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .footer-widget {
        max-width: 50%;
        width: 50%;
    }
}

.footer-widget-title {
    color: #ffffff;
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .footer-widget-title {
        margin-bottom: 20px;
    }
}

.footer-widget-list li {
    margin-bottom: 10px;
}

.footer-widget-list li a {
    color: #abb1c3;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
}

.footer-widget-list li a:hover {
    color: #ffffff;
}

.footer-widget-text {
    color: #abb1c3;
}

.footer-widget-form {
    position: relative;
    margin-top: 45px;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .footer-widget-form {
        margin-top: 30px;
    }
}

.footer-widget-form input {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    border: 0px;
    padding: 0 60px 0 15px;
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
}

.footer-widget-form input::-moz-placeholder {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
}

.footer-widget-form input::placeholder {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
}

.footer-widget-form button {
    border: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-widget-subscription {
        width: 100%;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-widget-subscription {
        width: 100%;
        margin-top: 30px;
    }
}

.footer-copyright-inner {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 80px 20px 70px 0;
    bottom: 0;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-copyright-inner {
        padding: 80px 20px 60px 0;
    }
}

@media only screen and (max-width: 767px) {
    .footer-copyright-inner {
        padding: 50px 20px 40px 0;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

.footer-copyright-inner p {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    color: #abb1c3;
    font-size: 16px;
    margin-bottom: 0px;
}

.footer-copyright-inner p span {
    font-weight: 500;
}

.footer-copyright-inner .footer-bootm-list {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .footer-copyright-inner .footer-bootm-list {
        margin-bottom: 20px;
        justify-content: center;
    }
}

.footer-copyright-inner .footer-bootm-list li {
    position: relative;
    margin-right: 40px;
}

@media only screen and (max-width: 479px) {
    .footer-copyright-inner .footer-bootm-list li {
        margin-right: 15px;
    }
}

.footer-copyright-inner .footer-bootm-list li:last-child {
    padding-right: 0;
    margin-right: 0;
}

.footer-copyright-inner .footer-bootm-list li a {
    font-size: 16px;
    color: #abb1c3;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.footer-copyright-inner .footer-bootm-list li a:hover {
    color: #ffffff;
}

.footer-two-bg {
    /* background-image: url(../public/assets/images/footer/footer-two-bg.png); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 300px 0 0px;
    position: relative;
    margin-top: -166px;
}

.footer-two-bg::after {
    position: absolute;
    background: rgb(231, 233, 242);
    background: linear-gradient(0deg, rgb(231, 233, 242) 0%, rgba(231, 233, 242, 0.1853116246) 100%, rgb(231, 233, 242) 100%);
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-two-bg {
        margin-top: -185px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-two-bg {
        padding: 280px 0 0px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-two-bg {
        padding: 250px 0 0px;
    }
}

.footer-two-copyright-inner {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 110px 0px 70px 0;
    bottom: 0;
    width: 100%;
    position: relative;
    z-index: 3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-two-copyright-inner {
        padding: 70px 0px 60px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-two-copyright-inner {
        padding: 80px 0px 60px 0;
    }
}

@media only screen and (max-width: 767px) {
    .footer-two-copyright-inner {
        padding: 50px 0px 40px 0;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

@media only screen and (max-width: 575px) {
    .footer-two-copyright-inner {
        padding: 40px 0;
    }
}

.footer-two-copyright-inner p {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    color: #717788;
    font-size: 16px;
    margin-bottom: 0px;
}

.footer-two-copyright-inner p span {
    font-weight: 500;
}

.footer-two-copyright-inner .footer-two-bootm-list {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .footer-two-copyright-inner .footer-two-bootm-list {
        margin-bottom: 20px;
        justify-content: center;
    }
}

.footer-two-copyright-inner .footer-two-bootm-list li {
    position: relative;
    margin-right: 40px;
}

.footer-two-copyright-inner .footer-two-bootm-list li:last-child {
    padding-right: 0;
    margin-right: 0;
}

@media only screen and (max-width: 575px) {
    .footer-two-copyright-inner .footer-two-bootm-list li {
        margin-right: 20px;
    }
}

.footer-two-copyright-inner .footer-two-bootm-list li a {
    font-size: 16px;
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.footer-two-copyright-inner .footer-two-bootm-list li a:hover {
    color: #322fb3;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer-about .footer-bottom {
        padding: 120px 0 174px 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-about .footer-bottom {
        padding: 60px 0 171px 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-team .footer-bottom {
        padding: 60px 0 173px 40px;
        margin-top: 195px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-service .footer-bottom {
        padding: 60px 0 171px 40px;
        margin-top: 196px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-service-details .footer-bottom {
        padding: 60px 0 171px 40px;
    }
}

@media only screen and (min-width: 1400px) {
    .footer-project-details .footer-bottom {
        padding: 120px 0 174px 100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer-project-details .footer-bottom {
        padding: 120px 0 173px 100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-project-details .footer-bottom {
        padding: 95px 0 173px 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-project-details .footer-bottom {
        padding: 60px 0 173px 40px;
        margin-top: 195px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer-blog-standard .footer-bottom {
        padding: 120px 0 174px 100px;
    }
}

@media only screen and (min-width: 1400px) {
    .footer-blog-masonry .footer-bottom {
        padding: 120px 0 174px 100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer-blog-masonry .footer-bottom {
        padding: 120px 0 174px 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-blog-details .footer-bottom {
        padding: 60px 0 173px 40px;
        margin-top: 195px;
    }
}

@media only screen and (min-width: 1200px) {
    .footer-contact .footer-bottom {
        padding: 120px 0 174px 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-contact .footer-bottom {
        padding: 60px 0 173px 40px;
        margin-top: 195px;
    }
}

.footer-widget-two {
    position: relative;
    z-index: 1;
}

.footer-widget-two .negative-margin {
    /* top: -30px; */
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .footer-widget-two .negative-margin {
        top: -20px;
        width: 180px;
    }
}

@media only screen and (max-width: 575px) {
    .footer-widget-two .negative-margin {
        width: 200px;
        top: auto;
        bottom: 20px;
    }
}

.footer-widget-two .footer-widget-two-list-icon li {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
}

.footer-widget-two .footer-widget-two-list-icon li:last-child {
    margin-bottom: 0px;
}

.footer-widget-two .footer-widget-two-list-icon li i {
    color: #7c7790;
}

.footer-widget-two .footer-widget-two-list-icon li span {
    color: #54545f;
    font-size: 16px;
    margin-left: 15px;
}

.footer-widget-two .social-icon {
    margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-widget-two .social-icon {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-widget-two .social-icon {
        margin-top: 20px;
    }
}

.footer-widget-two .widget-list-wrapper {
    display: flex;
    -moz-column-gap: 70px;
    column-gap: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-widget-two .widget-list-wrapper {
        -moz-column-gap: 35px;
        column-gap: 35px;
    }
}

.footer-widget-two-title {
    font-size: 18px;
    color: #343c55;
    font-weight: 600;
    margin-bottom: 30px;
    letter-spacing: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-widget-two-title {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-widget-two-title {
        margin-bottom: 20px;
    }
}

.footer-widget-two-list li {
    font-size: 16px;
    margin-bottom: 10px;
}

.footer-widget-two-list li:last-child {
    margin-bottom: 0px;
}

.footer-widget-two-list a {
    color: #717788;
}

.footer-widget-two-list a:hover {
    color: #322fb3;
}

.footer-widget-two .sidebar-blog li {
    margin-bottom: 25px;
}

.footer-widget-two .sidebar-blog li .image {
    flex: 1 0 60px;
    margin-right: 20px;
}

@media only screen and (max-width: 575px) {
    .footer-widget-two .sidebar-blog li .image {
        flex: 0 0 80px;
    }
}

.footer-widget-two .sidebar-blog li .content .date {
    margin-bottom: 8px;
}

.footer-widget-two .sidebar-blog li .content .title {
    font-size: 16px;
}

.footer-widget-two .instagrm {
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-widget-two .instagrm {
        margin-right: 0px;
    }
}

.footer-widget-two .instagrm li {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    flex: 0 0 72px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-widget-two .instagrm li {
        flex: 0 0 62px;
    }
}

.footer-widget-two .instagrm li a {
    display: block;
    position: relative;
}

.footer-widget-two .instagrm li a::after {
    background: #322fb3;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    transition: 0.5s;
}

.footer-widget-two .instagrm li a .add-action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: 0.5s;
}

.footer-widget-two .instagrm li a .add-action i {
    font-size: 20px;
    color: white;
    vertical-align: middle;
    line-height: 1;
}

.footer-widget-two .instagrm li a:hover::after {
    opacity: 0.5;
    visibility: visible;
    transition: 0.5s;
}

.footer-widget-two .instagrm li a:hover .add-action {
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
}

.footer-widget-two .footer-text {
    color: #54545f;
    font-size: 18px;
    margin-top: 40px;
}

.footer-widget-two .tweets li {
    margin-top: -5px;
    margin-bottom: 25px;
    display: flex;
}

.footer-widget-two .tweets li .image {
    flex: 1 0 20px;
    margin-right: 15px;
    margin-top: 4px;
}

.footer-widget-two .tweets li .content .date {
    margin-top: 8px;
    font-size: 14px;
    color: #9a9ea8;
}

.footer-widget-two .tweets li .content .has-link {
    font-size: 16px;
    font-weight: 700;
    color: #222222;
    margin-right: 4px;
    text-transform: uppercase;
}

.footer-widget-two .tweets li .content .text {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
}

.footer-widget-two .tweets li .content .bit-link {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-weight: 700;
    font-style: italic;
    font-size: 16px;
}

.footer-three {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0 0px;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-three {
        background-size: contain;
        margin-top: 55px;
        padding: 120px 0 0px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-three {
        background-size: contain;
        margin-top: 35px;
        padding: 0px 0 0px;
    }
}

.footer-three .footer-widget-two-title {
    color: #222222;
}

.footer-three .footer-widget-two-list-icon li {
    margin-bottom: 5px;
}

.footer-three .footer-widget-two-list-icon li span {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    margin-left: 25px;
}

.footer-three .footer-widget-two-list a {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 14px;
}

.footer-three .footer-two-copyright-inner {
    padding: 60px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-three .footer-two-copyright-inner {
        padding: 40px 0px 60px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-three .footer-two-copyright-inner {
        padding: 25px 0px 60px;
    }
}

.footer-three .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
    top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .footer-three .shape {
        display: none;
    }
}

.footer-three .shape .shape1 {
    position: absolute;
    left: 12%;
    top: 9%;
    z-index: 2;
}

.footer-three .shape .shape2 {
    position: absolute;
    left: 17%;
    top: 16%;
    z-index: 1;
}

.footer-three .shape .shape3 {
    position: absolute;
    left: 3%;
    top: 16%;
    z-index: 1;
}

.footer-three .shape .shape4 {
    position: absolute;
    left: 5%;
    top: 20%;
    z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .footer-three .shape .shape4 {
        left: 2%;
        top: 15%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer-three .shape .shape4 {
        left: 2%;
        top: 12%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-three .shape .shape4 {
        left: 2%;
        top: 14%;
        width: 10%;
    }
}

.footer-three .shape .shape5 {
    position: absolute;
    left: 0%;
    top: 22%;
    z-index: 1;
}

.footer-three .shape .shape6 {
    position: absolute;
    left: 10%;
    top: 48%;
    z-index: 1;
}

.footer-three .shape .shape7 {
    position: absolute;
    left: 7%;
    top: 63%;
    z-index: 1;
}

.footer-three .shape .shape7 img {
    -webkit-animation: moveupdown1 2s infinite linear;
    animation: moveupdown1 2s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-three .shape .shape7 {
        left: 0%;
        top: 50%;
    }
}

.footer-three .shape .shape8 {
    position: absolute;
    left: 0%;
    bottom: 0%;
    z-index: 1;
}

.footer-three .shape .shape9 {
    position: absolute;
    right: 17%;
    top: 16%;
    z-index: 1;
}

.footer-three .shape .shape10 {
    position: absolute;
    right: 0%;
    top: 46%;
    z-index: 1;
}

.footer-three .shape .shape10 img {
    -webkit-animation: moveupdown1 4s infinite linear;
    animation: moveupdown1 4s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-three .shape .shape10 {
        display: flex;
        justify-content: flex-end;
        top: 54%;
    }

    .footer-three .shape .shape10 img {
        width: 60%;
    }
}

.footer-three .shape .shape11 {
    position: absolute;
    right: 0%;
    top: 20%;
    z-index: 1;
}

.footer-three .shape .shape12 {
    position: absolute;
    right: 9%;
    top: 40%;
    z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .footer-three .shape .shape12 {
        right: 3%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-three .shape .shape12 {
        right: 3%;
        top: 43%;
    }
}

.footer-three .shape .shape13 {
    position: absolute;
    right: 1%;
    bottom: 6%;
    z-index: 1;
}

.footer-three .shape .shape14 {
    position: absolute;
    left: 5%;
    top: 0%;
    z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-three .shape .shape14 {
        top: -5%;
    }
}


/*----------------------------------------*/


/*  2.13 - Breadcrumb Styles CSS
/*----------------------------------------*/

.breadcrumb {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 386px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}


/* .breadcrumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #322fb3;
  background: linear-gradient(-45deg, #000 20%, #417dd2 50%, #322fb3 100%);

  opacity: 0.81;
  z-index: 1;
} */


/* .breadcrumb.breadcrumb-2::before {
  background: linear-gradient(-45deg, #000 20%, #417dd2 50%, #322fb3 100%);
  opacity: 0.1;
} */

.breadcrumb.breadcrumb-2 .breadcrumb-wrapper .title {
    color: #343c55;
    font-size: 48px;
}

@media only screen and (max-width: 767px) {
    .breadcrumb.breadcrumb-2 .breadcrumb-wrapper .title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .breadcrumb.breadcrumb-2 .breadcrumb-wrapper .title {
        font-size: 30px;
    }
}

.breadcrumb.breadcrumb-2 .breadcrumb-list li {
    color: #717788;
}

.breadcrumb.breadcrumb-2 .breadcrumb-list li::after {
    color: #717788;
}

.breadcrumb.breadcrumb-2 .breadcrumb-list li a {
    color: #717788;
}

.breadcrumb.breadcrumb-2 .breadcrumb-list li span {
    color: #717788;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb {
        height: 480px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb {
        height: 400px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb {
        height: 300px;
    }
}

@media only screen and (max-width: 479px) {
    .breadcrumb {
        height: 200px;
    }

    .breadcrumb-wrapper {
        margin-top: 20px;
    }
}

.breadcrumb-wrapper {
    position: relative;
    z-index: 2;
}

.breadcrumb-wrapper .title {
    font-size: 60px;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 67px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-wrapper .title {
        font-size: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-wrapper .title {
        font-size: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-wrapper .title {
        font-size: 40px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .breadcrumb-wrapper .title {
        font-size: 30px;
    }
}

.breadcrumb-wrapper .title-two {
    font-size: 60px;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .breadcrumb-wrapper .title-two {
        font-size: 50px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-wrapper .title-two {
        font-size: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .breadcrumb-wrapper .title-two {
        font-size: 30px;
    }
}

.breadcrumb-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.breadcrumb-list li {
    color: #ffffff;
    font-size: 18px;
    position: relative;
    padding-right: 15px;
    margin-right: 5px;
}

@media only screen and (max-width: 767px) {
    .breadcrumb-list li {
        font-size: 16px;
    }
}

.breadcrumb-list li::after {
    position: absolute;
    content: ">";
    right: 0;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
}

.breadcrumb-list li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.breadcrumb-list li:last-child::after {
    display: none;
}

.breadcrumb-list li a {
    color: #ffffff;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-weight: 400;
    letter-spacing: 2px;
}

.breadcrumb-list li a:hover {
    color: #343c55;
}

.breadcrumb-list li span {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-weight: 400;
    letter-spacing: 2px;
}

.breadcrumb-three {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: flex-end;
}

@media only screen and (max-width: 767px) {
    .breadcrumb-three {
        height: 600px;
    }
}

@media only screen and (max-width: 575px) {
    .breadcrumb-three {
        height: 500px;
    }
}

.breadcrumb-three::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.36);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.breadcrumb-three-wrapper {
    position: relative;
    z-index: 9;
    margin-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-three-wrapper {
        margin-bottom: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-three-wrapper {
        margin-bottom: 60px;
    }
}

.breadcrumb-three-wrapper .title {
    font-size: 48px;
    color: #ffffff;
    font-weight: 700;
}

@media only screen and (max-width: 767px) {
    .breadcrumb-three-wrapper .title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .breadcrumb-three-wrapper .title {
        font-size: 30px;
    }
}

.breadcrumb-three-meta {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;
    margin-top: 30px;
}

.breadcrumb-three-meta li {
    position: relative;
    margin-right: 10px;
    padding-right: 20px;
}

.breadcrumb-three-meta li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.breadcrumb-three-meta li a {
    position: relative;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
}

.breadcrumb-three-meta li a:hover {
    color: #322fb3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-three-meta li a {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-three-meta li a {
        font-size: 14px;
    }
}

.breadcrumb-three-meta li p {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-three-meta li p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-three-meta li p {
        font-size: 14px;
    }
}

.breadcrumb-three-meta li::after {
    position: absolute;
    content: "";
    background-color: #fff;
    width: 1px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.breadcrumb-three-meta li:last-child::after {
    display: none;
}


/*----------------------------------------*/


/*  2.14 - Funfact Styles CSS
/*----------------------------------------*/

.funfact {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.funfact .heading-one-title {
    color: #ffffff;
    margin: 5px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .funfact .heading-one-title {
        margin: 5px 0 30px;
    }
}

.funfact .heading-one p {
    color: #ffffff;
}

.funfact .about-counter {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .funfact .about-counter {
        justify-content: flex-start;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 479px) {
    .funfact .about-counter {
        gap: 15px;
        margin-top: 40px;
    }
}

.funfact .about-counter-2 {
    padding: 45px 50px 55px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    border-radius: 13px;
    background-color: #d5312c;
    max-width: 230px;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .funfact .about-counter-2 {
        max-width: 200px;
        padding: 30px 30px 38px;
    }
}

@media only screen and (max-width: 479px) {
    .funfact .about-counter-2 {
        max-width: 160px;
        padding: 20px 25px 24px;
    }
}

.funfact .about-counter-2 .odometer,
.funfact .about-counter-2 .niktext {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 60px;
    font-weight: 700;
    color: #ffffff;
}

@media only screen and (max-width: 479px) {

    .funfact .about-counter-2 .odometer,
    .funfact .about-counter-2 .niktext {
        font-size: 40px;
    }
}

.funfact .about-counter-2 .niktext {
    line-height: 1;
}

.funfact .about-counter-2 .increment {
    color: #ffffff;
    font-size: 26px;
    position: relative;
    top: -10px;
}

.funfact .about-counter-title {
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.5;
}

@media only screen and (max-width: 479px) {
    .funfact .about-counter-title {
        font-size: 16px;
    }
}

.funfact .about-counter .funfact-inner {
    display: flex;
    align-items: center;
}


/*----------------------------------------*/


/*  2.15 - Team Styles CSS
/*----------------------------------------*/

.team-bg {
    background: rgba(50, 47, 179, 0.08);
    background: linear-gradient(-38deg, rgba(50, 47, 179, 0.08) 0%, rgba(65, 125, 210, 0.08) 50%, rgba(91, 207, 242, 0.08) 100%);
}

.team-bg .heading-one {
    text-align: center;
    max-width: 520px;
    width: 100%;
    margin: 0 auto 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-bg .heading-one {
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .team-bg .heading-one {
        margin: 0 auto 40px;
    }
}

.single-team {
    position: relative;
    padding-bottom: 100px;
}

.single-team-image img {
    width: 100%;
}

.single-team-content {
    background-color: white;
    box-shadow: 15px 11px 48px 0px rgba(0, 0, 0, 0.08);
    padding: 25px 0 30px 0;
    max-width: 300px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    bottom: 0px;
}

.single-team-content .name {
    color: #343c55;
    font-size: 21px;
    font-weight: 700;
    transition: 0.3s;
}

.single-team-content .name:hover {
    color: #322fb3;
    transition: 0.3s;
}

.single-team-content .desig {
    font-size: 16px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-weight: 400;
    color: #717788;
    display: block;
    margin: 10px 0 0px;
}

.single-team-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 0;
    transition: 0.3s;
}

.single-team-social li {
    opacity: 0;
    visibility: hidden;
}

.single-team-social li a {
    font-size: 18px;
}

.single-team-social li:nth-child(1) {
    transform: translateY(30px);
    transition: 0.3s;
}

.single-team-social li:nth-child(2) {
    transform: translateY(30px);
    transition: 0.4s;
}

.single-team-social li:nth-child(3) {
    transform: translateY(30px);
    transition: 0.5s;
}

.single-team:hover {
    transition: 0.5s;
}

.single-team:hover .single-team-social {
    height: 30px;
    transition: 0.3s;
    margin-top: 10px;
}

.single-team:hover .single-team-social li {
    opacity: 1;
    visibility: visible;
}

.single-team:hover .single-team-social li:nth-child(1) {
    transform: translateY(0px);
    transition: 0.3s;
}

.single-team:hover .single-team-social li:nth-child(2) {
    transform: translateY(0px);
    transition: 0.4s;
}

.single-team:hover .single-team-social li:nth-child(3) {
    transform: translateY(0px);
    transition: 0.5s;
}


/* Team Two Style */

.team-two {
    position: relative;
    margin-bottom: 30px;
}

.team-two_images {
    position: relative;
    z-index: 4;
}

.team-two_images .image1 {
    left: -60px;
    top: -25px;
    position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .team-two_images .image1 {
        left: -20px;
        top: -25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_images .image1 {
        left: -20px;
        top: -25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_images .image1 {
        left: 40px;
        top: -25px;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_images .image1 {
        left: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .team-two_images .image1 {
        width: 40%;
    }
}

.team-two_images .image2 {
    left: 30px;
    top: 110px;
    position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .team-two_images .image2 {
        left: 80px;
        top: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_images .image2 {
        left: 90px;
        top: 110px;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_images .image2 {
        left: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .team-two_images .image2 {
        width: 50%;
    }
}

.team-two_images .image3 {
    left: 15px;
    top: 35px;
    position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .team-two_images .image3 {
        left: 90px;
        top: 35px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_images .image3 {
        left: 60px;
        top: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_images .image3 {
        left: 110px;
        top: auto;
        position: relative;
        bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_images .image3 {
        left: 40px;
        top: auto;
        bottom: 0;
    }
}

@media only screen and (max-width: 575px) {
    .team-two_images .image3 {
        width: 50%;
    }
}

.team-two_images .image4 {
    left: -40px;
    top: -375px;
    position: relative;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .team-two_images .image4 {
        left: 12px;
        top: -375px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_images .image4 {
        left: 15px;
        top: -375px;
        position: relative;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_images .image4 {
        display: none;
    }
}

.team-two_images .image5 {
    right: 75%;
    top: -85px;
    position: relative;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .team-two_images .image5 {
        right: 58%;
        top: -85px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_images .image5 {
        right: 70%;
        top: -85px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_images .image5 {
        right: 50%;
        top: -85px;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_images .image5 {
        display: none;
    }
}

.team-two .heading-one {
    z-index: 99;
    position: relative;
}

.team-two .heading-one-title {
    margin: 10px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .team-two .heading-one-title {
        margin: 10px 0 20px;
    }
}

.team-two .heading-one p {
    margin: 25px 0 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two .heading-one p {
        margin: 0px 0 50px;
    }
}

@media only screen and (max-width: 767px) {
    .team-two .heading-one p {
        margin: 0px 0 40px;
    }
}

.team-two_shape {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
}

.team-two_shape .shape {
    position: absolute;
}

.team-two_shape .shape1 {
    left: 0px;
    top: -170px;
    z-index: -5;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_shape .shape1 {
        top: -97px;
        width: 60%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_shape .shape1 {
        left: 0px;
        top: -60px;
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_shape .shape1 {
        display: none;
    }
}

.team-two_shape .shape2 {
    top: 80px;
    left: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_shape .shape2 {
        width: 58%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_shape .shape2 {
        left: 80px;
        top: 0px;
        width: 75%;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_shape .shape2 {
        display: none;
    }
}

.team-two_shape .shape3 {
    top: 60px;
    left: 35px;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (max-width: 767px) {
    .team-two_shape .shape3 {
        display: none;
    }
}

.team-two_shape .shape4 {
    bottom: -24%;
    right: 110px;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_shape .shape4 {
        bottom: -8%;
        right: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_shape .shape4 {
        bottom: 3%;
        right: 25px;
        width: 4%;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_shape .shape4 {
        display: none;
    }
}

.team-two_shape .shape5 {
    bottom: -100px;
    right: 215px;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_shape .shape5 {
        bottom: 0px;
        right: 181px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_shape .shape5 {
        bottom: 50px;
        right: 120px;
        width: 6%;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_shape .shape5 {
        display: none;
    }
}

.team-two_shape .shape6 {
    bottom: -43%;
    right: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-two_shape .shape6 {
        bottom: -25%;
        right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-two_shape .shape6 {
        bottom: -2%;
        right: 35px;
        width: 15%;
    }
}

@media only screen and (max-width: 767px) {
    .team-two_shape .shape6 {
        display: none;
    }
}


/* Team Three Style */

.team-three .heading-one {
    max-width: 600px;
    width: 100%;
    margin: 0 auto 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-three .heading-one {
        margin: 0 auto 50px;
    }
}

@media only screen and (max-width: 767px) {
    .team-three .heading-one {
        margin: 0 auto 40px;
    }
}

.team-three .heading-one-subtitle {
    display: block;
    margin-bottom: 10px;
}

.team-three-wrapper {
    margin-bottom: -20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-three-wrapper {
        margin-bottom: -40px;
    }
}

@media only screen and (max-width: 575px) {
    .team-three-wrapper {
        margin-bottom: -30px;
    }
}

.team-three-wrapper .team-three-single {
    margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-three-wrapper .team-three-single {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 575px) {
    .team-three-wrapper .team-three-single {
        margin-bottom: 20px;
    }
}

.team-three-wrapper .team-three-single:nth-child(2n + 2) {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .team-three-wrapper .team-three-single:nth-child(2n + 2) {
        margin-top: 0;
    }
}

.team-three .single-team-content {
    padding: 35px 0 30px 0;
    border-radius: 7px;
    bottom: -10px;
}

@media only screen and (max-width: 575px) {
    .team-three .single-team-content {
        bottom: 15px;
    }
}

.team-three .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .team-three .shape {
        display: none;
    }
}

.team-three .shape .shape1 {
    position: absolute;
    left: 14%;
    top: -11%;
}

.team-three .shape .shape2 {
    position: absolute;
    left: 0%;
    top: -19%;
}

.team-three .shape .shape3 {
    position: absolute;
    left: 0%;
    top: 3%;
}

.team-three .shape .shape4 {
    position: absolute;
    right: 6%;
    top: 10%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-three .shape .shape4 {
        right: 2%;
        top: 4%;
    }
}

.team-three .shape .shape5 {
    position: absolute;
    right: 9.7%;
    top: 21%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-three .shape .shape5 {
        top: 15%;
    }
}

.team-three .shape .shape6 {
    position: absolute;
    left: 4%;
    bottom: 10%;
}

.team-three .shape .shape7 {
    position: absolute;
    right: 7%;
    bottom: 8%;
}

.team-three .shape .shape7 img {
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}


/*----------------------------------------*/


/*  2.16 - System Styles CSS
/*----------------------------------------*/

.system {
    position: relative;
    overflow: hidden;
}

.system-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0;
    position: relative;
    width: 180%;
    right: 78%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    row-gap: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .system-bg {
        padding: 108px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .system-bg {
        padding: 80px 0;
        right: 58%;
        row-gap: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .system-bg {
        padding: 60px 0;
        width: 154%;
        left: -29%;
        right: inherit;
        row-gap: 35px;
    }
}

@media only screen and (max-width: 575px) {
    .system-bg {
        width: 108%;
        left: -12px;
        row-gap: 30px;
    }
}

.system-single {
    display: flex;
    align-items: flex-start;
    max-width: 470px;
    width: 100%;
    padding-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .system-single {
        max-width: 382px;
        width: 100%;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .system-single {
        margin-right: 378px;
    }
}

@media only screen and (max-width: 767px) {
    .system-single {
        padding-right: 100px;
        margin: 0 auto 0;
    }
}

@media only screen and (max-width: 575px) {
    .system-single {
        margin: 0 auto 0 12px;
        padding-right: 28px;
    }
}

.system-icon {
    flex: 0 0 auto;
    margin-right: 30px;
    position: relative;
}

.system-icon img {
    position: relative;
}

.system-content .title {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 25px;
    color: #ffffff;
}

@media only screen and (max-width: 767px) {
    .system-content .title {
        margin-bottom: 15px;
    }
}

.system-content p {
    color: #ffffff;
}

.system-bg2 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 171px 0;
    position: relative;
    width: 157%;
    left: -2.2%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    row-gap: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .system-bg2 {
        left: -2.4%;
        padding: 171.5px 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .system-bg2 {
        left: -3%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .system-bg2 {
        padding: 80px 0;
        left: -21.2%;
    }
}

@media only screen and (max-width: 767px) {
    .system-bg2 {
        padding: 60px 0;
        width: 150%;
        left: -25%;
    }
}

@media only screen and (max-width: 575px) {
    .system-bg2 {
        width: 108%;
        left: -12px;
    }
}

.system-bg2::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    background-color: #000000;
    opacity: 0.48;
}

.system-bg2 .heading-one {
    max-width: 560px;
    width: 100%;
    margin: 0 auto 0 130px;
    position: relative;
    z-index: 4;
}

@media only screen and (min-width: 1200px) and (max-width: 1549px) {
    .system-bg2 .heading-one {
        max-width: 530px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .system-bg2 .heading-one {
        max-width: 520px;
        width: 100%;
        margin: 0 auto 0 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .system-bg2 .heading-one {
        margin: 0 auto 0 150px;
    }
}

@media only screen and (max-width: 767px) {
    .system-bg2 .heading-one {
        max-width: 500px;
    }
}

@media only screen and (max-width: 575px) {
    .system-bg2 .heading-one {
        max-width: 456px;
        margin: 0 auto 0 12px;
    }
}

@media only screen and (max-width: 479px) {
    .system-bg2 .heading-one {
        max-width: 336px;
        margin: 0 auto 0 12px;
    }
}

.system-bg2 .heading-one-subtitle {
    color: #ffffff;
}

.system-bg2 .heading-one-title {
    color: #ffffff;
    margin: 5px 0 40px;
}

@media only screen and (max-width: 767px) {
    .system-bg2 .heading-one-title {
        margin: 5px 0 30px;
    }
}

.system-bg2 .heading-one p {
    color: #ffffff;
}

.system-bg2 .heading-one .btn-style-one {
    display: inline-flex;
    margin-top: 30px;
}

@media only screen and (max-width: 767px) {
    .system-bg2 .heading-one .btn-style-one {
        margin-top: 20px;
    }
}


/*----------------------------------------*/


/*  2.17 - Sidebar Styles CSS
/*----------------------------------------*/

.sidebar-wrap {
    max-width: 342px;
    width: 100%;
}

.sidebar-wrap .sidebar:last-child {
    margin-bottom: 0px;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-wrap {
        max-width: 100%;
    }
}

.sidebar {
    margin-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar {
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .sidebar {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-wrap {
        margin-top: 60px;
    }
}

.sidebar .sidebar-title {
    padding: 24px 30px;
    border-radius: 5px;
    font-size: 21px;
    font-weight: 700;
    color: #343c55;
    display: flex;
    background: rgba(50, 47, 179, 0.13);
    background: linear-gradient(-45deg, rgba(91, 207, 242, 0.13) 0%, rgba(65, 125, 210, 0.13) 50%, rgba(50, 47, 179, 0.13) 100%);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.sidebar .sidebar-title i {
    font-size: 14px;
    color: #d5312c;
    margin-left: 10px;
}

.sidebar.sidebar-help {
    background-color: #322fb3;
}

.sidebar-search {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    border-radius: 5px;
}

.sidebar-search-btn {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    border: 0px;
    background-color: white;
    color: #ffffff;
    font-size: 18px;
    padding: 20px;
    border-radius: 0;
}

.sidebar-search-btn::before {
    border-radius: 0;
}

.sidebar-input {
    font-size: 14px;
    width: 100%;
    border: none;
    height: 60px;
    border-radius: 5px;
    padding-right: 50px;
    padding-left: 20px;
    background-color: #ffffff;
    color: #343c55;
    border: 1px solid #bdbdcc;
}

.sidebar-input::-moz-placeholder {
    color: #717788;
    font-size: 16px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.sidebar-input::placeholder {
    color: #717788;
    font-size: 16px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.sidebar-blog li {
    display: flex;
    margin-bottom: 30px;
}

.sidebar-blog li:last-child {
    margin-bottom: 0px;
}

.sidebar-blog li .image {
    flex: 1 0 85px;
    margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-blog li .image {
        flex: none;
    }
}

@media only screen and (max-width: 767px) {
    .sidebar-blog li .image {
        flex: none;
    }
}

.sidebar-blog li .image img {
    width: 100%;
}

.sidebar-blog li .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.sidebar-blog li .content .title {
    font-size: 18px;
    font-weight: 700;
    color: #343c55;
}

.sidebar-blog li .content .title:hover {
    color: #322fb3;
}

.sidebar-blog li .content .date {
    font-size: 14px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    color: #717788;
    line-height: 1;
    margin-bottom: 10px;
}

.sidebar-category li {
    margin-bottom: 8px;
}

.sidebar-category li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0px;
}

.sidebar-category li a {
    font-weight: 700;
    color: #343c55;
    font-size: 18px;
}

.sidebar-category li a:hover {
    color: #322fb3;
    padding-left: 5px;
}

.sidebar-category li a i {
    color: #6c63ff;
    font-size: 14px;
    margin-right: 10px;
}

.sidebar-tags a {
    font-size: 14px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #d1dbe5;
    color: #54545f;
    display: inline-flex;
    margin-bottom: 6px;
    margin-right: 4px;
    line-height: 1;
}

.sidebar-tags a:last-child {
    margin-bottom: 0px;
}

.sidebar-tags a:hover,
.sidebar-tags a.active {
    background-color: #d5312c;
    color: White;
    border: 1px solid #d5312c;
}

.sidebar-instagram li {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.sidebar-instagram li a {
    display: block;
    position: relative;
}

.sidebar-instagram li a::after {
    background: #322fb3;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    transition: 0.5s;
}

.sidebar-instagram li a .add-action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: 0.5s;
}

.sidebar-instagram li a .add-action i {
    font-size: 24px;
    color: white;
    vertical-align: middle;
    line-height: 1;
}

.sidebar-instagram li a:hover::after {
    opacity: 0.5;
    visibility: visible;
    transition: 0.5s;
}

.sidebar-instagram li a:hover .add-action {
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
}

.sidebar-feature-content {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.sidebar-feature-content .date {
    margin-bottom: 3px;
}

.sidebar-feature-content .title {
    font-size: 21px;
    font-weight: 700;
    color: #343c55;
}

.sidebar-feature-content .title:hover {
    color: #322fb3;
}

.sidebar-banner {
    padding: 60px 50px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.sidebar-banner:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #322fb3;
    background: linear-gradient(-55deg, #5bcff2 0%, #417dd2 50%, #322fb3 100%);
    opacity: 0.8;
}

.sidebar-banner-subtitle {
    color: #d5312c;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    z-index: 1;
}

.sidebar-banner-title {
    font-weight: 700;
    font-size: 36px;
    color: #ffffff;
    position: relative;
    z-index: 1;
}

.sidebar-banner p {
    position: relative;
    z-index: 1;
    color: #ffffff;
}

.sidebar-banner .btn-style-two {
    padding: 16px 38px;
    display: inline-block;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    position: relative;
    z-index: 1;
}


/*----------------------------------------*/


/*  2.18 - Blog Details Styles CSS
/*----------------------------------------*/


/* Blog Details */

.blog-details .text {
    margin-bottom: 25px;
}

.blog-details-two {
    margin-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-details-two {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .blog-details-two {
        margin-top: 60px;
    }
}

.blockquote {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    margin: 50px 0;
}

.blockquote img {
    position: relative;
    margin-top: 8px;
}

.blockquote span {
    font-size: 21px;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-style: italic;
    line-height: 1.42;
    color: #717788;
}


/* Blog Share */

.blog-share-tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
    padding-bottom: 40px;
    border-bottom: 1px solid #bdbdcc;
}

@media only screen and (max-width: 767px) {
    .blog-share-tag {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 50px;
        padding-bottom: 20px;
    }
}

.blog-share-tag .tags,
.blog-share-tag .share {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.blog-share-tag .tags .label,
.blog-share-tag .share .label {
    color: #343c55;
    font-weight: 400;
    margin-right: 20px;
    font-size: 16px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .blog-share-tag .tags {
        margin-bottom: 20px;
    }
}

.blog-share-tag .tags ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.blog-share-tag .tags ul li {
    margin-right: 5px;
}

.blog-share-tag .tags ul li a {
    color: #343c55;
    font-size: 16px;
    font-weight: 700;
}

@media only screen and (max-width: 767px) {
    .blog-share-tag .tags ul li a {
        font-size: 16px;
    }
}

.blog-share-tag .tags ul li a:hover {
    color: #322fb3;
}

.blog-share-tag .widget-social {
    display: flex;
    gap: 10px;
}

.blog-share-tag .widget-social li a {
    font-size: 18px;
}

.blog-share-tag .widget-social .share-link {
    color: #cdccdd;
}

.blog-share-tag .widget-social .share-link:hover {
    color: #322fb3;
}


/* Post Nav Link */

.post-nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
}

@media only screen and (max-width: 767px) {
    .post-nav-link {
        margin-top: 20px;
    }
}

.post-nav-link .post-prev {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

@media only screen and (max-width: 767px) {
    .post-nav-link .post-prev {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.post-nav-link .post-next {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: end;
    margin-left: 10px;
}

@media only screen and (max-width: 767px) {
    .post-nav-link .post-next {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

@media only screen and (max-width: 767px) {
    .post-nav-link .content {
        width: 100%;
        margin-top: 10px;
    }
}

.post-nav-link .content .prev-arrow,
.post-nav-link .content .next-arrow {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
    color: #343c55;
    text-transform: uppercase;
}

.post-nav-link .content .prev-arrow img {
    margin-right: 7px;
}

.post-nav-link .content .next-arrow img {
    margin-left: 7px;
}

.post-nav-link .content .title {
    font-size: 18px;
    color: #343c55;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 5px;
}


/* Entry Author */

.entry-author {
    box-shadow: 0px 18px 90px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 20px;
    display: flex;
    margin-top: 70px;
}

@media only screen and (max-width: 767px) {
    .entry-author {
        padding: 30px;
        margin-top: 50px;
    }
}

.entry-author .thumb {
    flex-shrink: 0;
    margin-right: 30px;
}

@media only screen and (max-width: 767px) {
    .entry-author .thumb {
        margin-right: 20px;
        width: 80px;
    }
}

.entry-author .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .entry-author .head {
        margin-bottom: 15px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
    }
}

.entry-author .head .title {
    color: #343c55;
    font-size: 21px;
    font-weight: 700;
}

.entry-author .head .widget-social {
    display: flex;
    gap: 10px;
}

.entry-author .head .widget-social li a {
    font-size: 18px;
}

@media only screen and (max-width: 767px) {
    .entry-author .head .widget-social li a {
        font-size: 16px;
    }
}

.entry-author .text {
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .entry-author .text {
        margin-bottom: 15px;
        font-size: 16px;
    }
}

.entry-author .read-more {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.16;
    border-bottom: 2px solid #322fb3;
    color: #343c55;
    text-transform: uppercase;
}

.entry-author .read-more:hover {
    color: #322fb3;
}


/* Comment */

.comment {
    margin-top: 70px;
}

@media only screen and (max-width: 767px) {
    .comment {
        margin-top: 44px;
    }
}

.comment .title {
    font-size: 30px;
    font-weight: 700;
    color: #343c55;
    margin-bottom: 45px;
}

@media only screen and (max-width: 767px) {
    .comment .title {
        font-size: 26px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .comment .title {
        font-size: 24px;
        margin-bottom: 10px;
    }
}

.comment-1 {
    display: flex;
    align-items: flex-start;
}

@media only screen and (max-width: 767px) {
    .comment-1 {
        padding: 20px 0;
    }
}

.comment-thumb {
    flex-shrink: 0;
    margin-right: 30px;
}

@media only screen and (max-width: 479px) {
    .comment-thumb {
        margin-right: 15px;
        width: 50px;
    }
}

.comment-content {
    padding-bottom: 45px;
    border-bottom: 1px solid #e5e5e5;
}

@media only screen and (max-width: 575px) {
    .comment-content {
        padding-bottom: 30px;
    }
}

.comment-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.comment-head .meta {
    display: flex;
    align-items: center;
}

.comment-title {
    color: #343c55;
    font-weight: 700;
    font-size: 18px;
}

@media only screen and (max-width: 767px) {
    .comment-title {
        margin-bottom: 0;
    }
}

.comment .meta {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .comment .meta {
        line-height: 1;
    }
}

.comment .meta .date {
    font-size: 14px;
    color: #a3a8af;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.comment .text {
    margin-top: 20px;
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .comment .text {
        font-size: 16px;
        margin-top: 15px;
    }
}

.comment .replay {
    padding: 10px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    border: 1px solid #d1dbe5;
    color: #4a4f6b;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .comment .replay {
        padding: 8px 20px 5px;
        font-size: 14px;
    }
}

.comment .replay:hover {
    background-color: #322fb3;
    border-color: #322fb3;
    color: white;
}

.comment-list .comment-inner {
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .comment-list .comment-inner {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .comment-list .comment-inner {
        margin-bottom: 0px;
    }
}

.comment-list .comment-inner:last-child {
    margin-bottom: 0;
}

.comment-children {
    margin-left: 120px;
    margin-bottom: 30px;
    margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .comment-children {
        margin-left: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .comment-children {
        margin-left: 60px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .comment-children {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 479px) {
    .comment-children {
        margin: 0 0 0 30px;
    }
}

.comment-form {
    margin-top: 70px;
}

@media only screen and (max-width: 767px) {
    .comment-form {
        margin-top: 50px;
    }
}

.comment-form .title {
    font-size: 30px;
    font-weight: 700;
    color: #343c55;
    margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
    .comment-form .title {
        font-size: 26px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .comment-form .title {
        font-size: 24px;
        margin-bottom: 10px;
    }
}

.comment-form-text {
    margin-bottom: 30px;
}

.comment-form-text span {
    color: #d71602;
}

.comment-form-inner input,
.comment-form-inner textarea {
    font-size: 14px;
    width: 100%;
    border: none;
    height: 60px;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #ffffff;
    border: 1px solid #cbcbdc;
}

.comment-form-inner input::-moz-placeholder,
.comment-form-inner textarea::-moz-placeholder {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
    line-height: 1;
}

.comment-form-inner input::placeholder,
.comment-form-inner textarea::placeholder {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 16px;
    line-height: 1;
}

.comment-form-inner textarea {
    height: 190px;
    padding-top: 20px;
}

.comment-form .btn {
    text-align: center;
    display: flex;
    justify-content: center;
}


/*----------------------------------------*/


/*  2.19 - Projects Styles CSS
/*----------------------------------------*/

.project-details-images .group-image {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media only screen and (max-width: 767px) {
    .project-details-images .group-image {
        margin-top: 30px;
    }
}

.project-details {
    margin-top: 45px;
}

.project-details-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .project-details-title {
        font-size: 30px;
    }
}

.project-details-sidebar {
    position: relative;
    padding: 40px 50px;
}

@media only screen and (max-width: 767px) {
    .project-details-sidebar {
        padding: 30px;
    }
}

.project-details-sidebar::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(50, 47, 179, 0.07);
    background: linear-gradient(-45deg, rgba(91, 207, 242, 0.07) 0%, rgba(50, 47, 179, 0.07) 100%);
    z-index: 1;
    border-radius: 13px;
}

.project-details-sidebar .title {
    font-size: 18px;
    color: #343c55;
    font-weight: 700;
    display: flex;
    margin-bottom: 5px;
}

.project-details-sidebar li {
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .project-details-sidebar li {
        margin-bottom: 15px;
    }
}

.project-details-sidebar li:last-child {
    margin-bottom: 0;
}

.project-details-social {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .project-details-social {
        margin: 30px 0;
    }
}

.project-details-social .label {
    text-transform: uppercase;
    color: #717788;
}

.project-details-social .social {
    display: flex;
    gap: 10px;
}

.project-details-social .social li a {
    font-size: 16px;
}

.more-project {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .more-project {
        margin-top: 40px;
    }
}

.more-project-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .more-project-title {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .more-project-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: 767px) {
    .more-project img {
        width: 100%;
    }
}

.single-project {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.single-project::before {
    content: "";
    background: linear-gradient(-50deg, #5bcff2 15%, #417dd2 47%, #322fb3 80%);
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    position: absolute;
    opacity: 0;
    transition: 0.5s;
    z-index: 1;
}

.single-project .thumbnail {
    overflow: hidden;
    border-radius: 13px;
    display: block;
    position: relative;
    transition: 0.3s;
}

.single-project .thumbnail img {
    transform: scale(1);
    transition: 0.3s;
    width: 100%;
}

.single-project .thumbnail:hover img {
    transform: scale(1.06);
    transition: 0.3s;
}

.single-project .lightbox {
    position: absolute;
    top: -20px;
    right: 30px;
    z-index: 9;
    transition: 0.6s;
    opacity: 0;
}

.single-project .lightbox .item {
    font-size: 16px;
    color: black;
    background: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: 0.3s;
}

.single-project .lightbox .item:hover {
    background: linear-gradient(-50deg, #5bcff2 15%, #417dd2 47%, #322fb3 80%);
    color: white;
    transition: 0.3s;
}

.single-project .content {
    position: absolute;
    bottom: -20px;
    left: 0;
    opacity: 0;
    transition: 0.6s;
    padding: 30px;
    z-index: 2;
}

@media only screen and (max-width: 767px) {
    .single-project .content {
        padding: 20px;
    }
}

.single-project .content .subtitle {
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.single-project .content .title {
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
}

.single-project .content .title a {
    color: white;
}

.single-project:hover .lightbox {
    top: 20px;
    transition: 0.6s;
    opacity: 1;
}

.single-project:hover .content {
    bottom: 0%;
    opacity: 1;
    transition: 0.6s;
}

.single-project:hover::before {
    opacity: 0.7;
}


/*----------------------------------------*/


/*  2.20 - Support Styles CSS
/*----------------------------------------*/

.support {
    position: relative;
    margin-top: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support {
        margin-top: 0;
    }
}

.support-bg {
    padding: 100px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .support-bg {
        padding: 60px 0;
    }
}

@media only screen and (max-width: 767px) {
    .support-bg {
        padding: 60px 0 0;
    }
}

.support-bg::after {
    background-color: #322fb3;
    content: "";
    height: 100%;
    right: 8%;
    position: absolute;
    top: 0;
    width: 5000px;
    z-index: -1;
    border-radius: 0 13px 13px 0;
}

@media only screen and (max-width: 767px) {
    .support-bg::after {
        right: 0;
    }
}

.support-bg-img {
    position: absolute;
    left: -12px;
    top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .support-bg-img {
        width: 90%;
    }
}

@media only screen and (max-width: 767px) {
    .support-bg-img {
        width: 100%;
    }
}

.support .heading-one {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support .heading-one-subtitle {
        font-size: 16px;
        letter-spacing: 2px;
        margin-bottom: 10px;
    }
}

.support .heading-one-title {
    color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support .heading-one-title {
        font-size: 40px;
    }
}

.support .heading-one-text {
    color: #ffffff;
    margin: 40px 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support .heading-one-text {
        margin: 20px 0;
    }
}

.support .phone-call {
    display: flex;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
}

.support .phone-call .phone-icon {
    height: 60px;
    width: 60px;
    background: #d5312c;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
}

.support .phone-call .phone-icon i {
    color: #ffffff;
    font-size: 18px;
    transition: 0.4s;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support .phone-call .phone-icon {
        height: 50px;
        width: 50px;
    }
}

.support .phone-call .phone-text {
    font-size: 24px;
    font-weight: 600;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    color: #ffffff;
    transition: 0.4s;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support .phone-call .phone-text {
        font-size: 20px;
    }
}

.support .phone-call:hover .phone-icon {
    transition: 0.4s;
    background: #31bbe5;
}

.support .phone-call:hover .phone-icon i {
    color: #f1f1f1;
    transition: 0.4s;
}

.support .phone-call:hover .phone-text {
    color: #d5312c;
    transition: 0.4s;
}

.support-man {
    position: absolute;
    bottom: 0;
    margin-left: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .support-man {
        margin-left: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .support-man {
        margin-left: 0px;
        width: 46%;
    }
}

@media only screen and (max-width: 767px) {
    .support-man {
        position: relative;
        margin-left: 40px;
    }
}

.support-shape-one {
    position: absolute;
    top: -50px;
    right: 12%;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .support-shape-one {
        right: 10%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .support-shape-one {
        right: 4%;
    }
}

@media only screen and (max-width: 767px) {
    .support-shape-one {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .support-shape-one img {
        width: 90%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .support-shape-one img {
        width: 80%;
    }
}

.support-shape-two {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -28%;
}

.support-shape-two img {
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .support-shape-two {
        display: none;
    }
}


/*----------------------------------------*/


/*  2.21 - Speciality Styles CSS
/*----------------------------------------*/

.speciality {
    position: relative;
}

.speciality-image {
    position: relative;
    display: inline-flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .speciality-image {
        margin-right: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .speciality-image {
        display: block;
    }

    .speciality-image img {
        width: 100%;
    }
}

.speciality-image-circle .circle1 {
    top: -65px;
    position: absolute;
    left: -80px;
    -webkit-animation: zoom1 3s infinite linear;
    animation: zoom1 3s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .speciality-image-circle .circle1 {
        left: -20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .speciality-image-circle .circle1 {
        left: -20px;
        top: -80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality-image-circle .circle1 {
        top: -50px;
        left: -20px;
        width: 8%;
    }
}

@media only screen and (max-width: 767px) {
    .speciality-image-circle .circle1 {
        display: none;
    }
}

.speciality-image-circle .circle2 {
    top: 100px;
    right: -50px;
    position: absolute;
    -webkit-animation: zoom1 4s infinite linear;
    animation: zoom1 4s infinite linear;
    z-index: 4;
}

@media only screen and (max-width: 767px) {
    .speciality-image-circle .circle2 {
        display: none;
    }
}

.speciality-image-circle .circle3 {
    left: -85px;
    bottom: -175px;
    position: absolute;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality-image-circle .circle3 {
        width: 40%;
        bottom: -50px;
    }
}

@media only screen and (max-width: 767px) {
    .speciality-image-circle .circle3 {
        display: none;
    }
}

.speciality-image-inner {
    position: relative;
    z-index: 3;
}

.speciality .heading-one-title {
    margin: 10px 0px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality .heading-one-title {
        margin: 10px 0px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .speciality .heading-one-title {
        margin: 10px 0px 20px;
    }
}

.speciality-shape {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.speciality-shape .shape {
    position: absolute;
}

.speciality-shape .shape1 {
    left: 170px;
    bottom: 185px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .speciality-shape .shape1 {
        left: 60px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .speciality-shape .shape1 {
        left: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .speciality-shape .shape1 {
        left: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .speciality-shape .shape1 {
        display: none;
    }
}

.speciality-shape .shape2 {
    top: -25px;
    right: 60px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .speciality-shape .shape2 {
        right: 30;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .speciality-shape .shape2 {
        right: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .speciality-shape .shape2 {
        right: 10px;
        top: -40px;
        width: 18%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality-shape .shape2 {
        right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .speciality-shape .shape2 {
        display: none;
    }
}

.speciality-shape .shape3 {
    right: 114px;
    bottom: 0;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .speciality-shape .shape3 {
        right: 60px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .speciality-shape .shape3 {
        right: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .speciality-shape .shape3 {
        right: 20px;
        bottom: -40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .speciality-shape .shape3 {
        right: 10px;
        bottom: -40px;
        width: 2%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality-shape .shape3 {
        right: 40px;
        bottom: auto;
        top: 50%;
        width: 2%;
    }
}

@media only screen and (max-width: 767px) {
    .speciality-shape .shape3 {
        display: none;
    }
}

.speciality_list {
    display: flex;
    margin-top: 45px;
    gap: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality_list {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .speciality_list {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .speciality_list {
        margin-top: 30px;
        gap: 20px;
    }
}

.speciality_list__single .content_title {
    font-size: 24px;
    font-weight: 700;
    margin: 35px 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .speciality_list__single .content_title {
        margin: 20px 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .speciality_list__single .content_title {
        margin: 20px 0 15px;
        font-size: 22px;
    }
}

@media only screen and (max-width: 575px) {
    .speciality_list__single .content_title {
        margin: 15px 0 10px;
        font-size: 18px;
    }
}


/*----------------------------------------*/


/*  2.22 - Why Choose Us Styles CSS
/*----------------------------------------*/

.why-choose-us {
    position: relative;
    z-index: 1;
}

.why-choose-us_inner {
    background-color: #322fb3;
    padding: 100px;
    border-radius: 13px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: -3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us_inner {
        padding: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .why-choose-us_inner {
        padding: 60px;
        flex-direction: column;
        row-gap: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .why-choose-us_inner {
        padding: 40px;
    }
}

.why-choose-us_content {
    flex: 0 0 37%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .why-choose-us_content {
        flex: 0 0 45%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us_content {
        flex: 0 0 45%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .why-choose-us_content {
        flex: 0 0 50%;
    }
}

.why-choose-us_content .heading-one-subtitle,
.why-choose-us_content .heading-one-title,
.why-choose-us_content .heading-one p {
    color: #ffffff;
}

.why-choose-us_content .heading-one-title {
    margin: 10px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .why-choose-us_content .heading-one-title {
        margin: 10px 0 20px;
    }
}

.why-choose-us_list {
    flex: 0 0 48%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .why-choose-us_list {
        flex: 0 0 50%;
    }
}

.why-choose-us_list__single {
    display: flex;
    margin-bottom: 50px;
}

.why-choose-us_list__single:last-child {
    margin-bottom: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .why-choose-us_list__single {
        margin-bottom: 30px;
    }
}

.why-choose-us_list__single .check-icon {
    height: 48px;
    flex: 0 0 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 100%;
    margin-right: 20px;
}

.why-choose-us_list__single .check-content_title {
    /* font-size: 24px; */
    font-weight: 800;
    color: #ffffff;
    font-size: 1.3rem !important;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .why-choose-us_list__single .check-content_title {
        margin-bottom: 15px;
        font-size: 22px;
    }
}

@media only screen and (max-width: 767px) {
    .why-choose-us_list__single .check-content_title {
        margin-bottom: 15px;
        font-size: 20px;
    }
}

.why-choose-us_list__single .check-content_text {
    color: #ffffff;
}

.why-choose-us_shape {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -4;
}

.why-choose-us_shape .shape {
    position: absolute;
}

.why-choose-us_shape .shape1 {
    right: 0;
    top: -200px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .why-choose-us_shape .shape1 {
        top: -170px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .why-choose-us_shape .shape1 {
        top: -150px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us_shape .shape1 {
        width: 50%;
        top: -90px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .why-choose-us_shape .shape1 {
        width: 65%;
        top: -65px;
    }
}

@media only screen and (max-width: 767px) {
    .why-choose-us_shape .shape1 {
        display: none;
    }
}

.why-choose-us_shape .shape2 {
    right: 230px;
    bottom: 70px;
    -webkit-animation: moveupdown1 3s infinite linear;
    animation: moveupdown1 3s infinite linear;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .why-choose-us_shape .shape2 {
        right: 60px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .why-choose-us_shape .shape2 {
        right: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .why-choose-us_shape .shape2 {
        right: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us_shape .shape2 {
        right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .why-choose-us_shape .shape2 {
        display: none;
    }
}

.why-choose-us_shape .shape3 {
    bottom: -110px;
    left: 140px;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .why-choose-us_shape .shape3 {
        left: 60px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .why-choose-us_shape .shape3 {
        left: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .why-choose-us_shape .shape3 {
        left: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us_shape .shape3 {
        left: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .why-choose-us_shape .shape3 {
        display: none;
    }
}


/*----------------------------------------*/


/*  2.23 - Contact Styles CSS
/*----------------------------------------*/

.home-contact {
    position: relative;
    margin-top: -275px;
}

.home-contact_wrapper {
    background-color: #322fb3;
    padding: 90px 100px;
    border-radius: 13px;
    display: flex;
    position: relative;
    z-index: 3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-contact_wrapper {
        padding: 90px 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-contact_wrapper {
        padding: 50px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact_wrapper {
        padding: 30px 20px;
        flex-direction: column;
    }
}

.home-contact_content {
    flex: 0 0 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-contact_content {
        flex: 0 0 52%;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact_content {
        flex: 0 0 100%;
        margin-bottom: 40px;
    }
}

.home-contact_content .subtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 4px;
}

.home-contact_content .title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #ffffff;
    line-height: 1.3;
    margin: 10px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-contact_content .title {
        margin: 10px 0 20px;
        font-size: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact_content .title {
        margin: 10px 0 20px;
        font-size: 36px;
    }
}

.home-contact_content p {
    color: #ffffff;
    margin-bottom: 0px;
}

.home-contact_info {
    margin-top: 40px;
}

@media only screen and (max-width: 767px) {
    .home-contact_info {
        margin-top: 30px;
    }
}

.home-contact_info li {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 30px;
}

.home-contact_info li:last-child {
    margin-bottom: 0px;
}

.home-contact_info .icon {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: #d5312c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 18px;
}

.home-contact_info .details-title {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 6px;
}

.home-contact_info .details-text {
    color: #ffffff;
}

.home-contact_info .details-text:hover {
    color: #d5312c;
}

.home-contact_form {
    flex: 0 0 50%;
    padding-left: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-contact_form {
        padding-left: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-contact_form {
        flex: 0 0 48%;
        padding-left: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact_form {
        flex: 0 0 100%;
        padding-left: 0px;
    }
}

.home-contact_form .form-single {
    margin-bottom: 20px;
    line-height: 1;
}

.home-contact_form input,
.home-contact_form textarea,
.home-contact_form button {
    width: 100%;
    border-radius: 7px;
    border: 0px;
}

.home-contact_form input::-moz-placeholder,
.home-contact_form textarea::-moz-placeholder {
    font-size: 16px;
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.home-contact_form input::placeholder,
.home-contact_form textarea::placeholder {
    font-size: 16px;
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
}

.home-contact_form input {
    height: 60px;
    padding: 0 25px;
}

.home-contact_form textarea {
    height: 120px;
    padding: 20px 25px;
}

.home-contact .shape {
    position: absolute;
    z-index: 2;
}

.home-contact .shape.shape-1 {
    bottom: -245px;
    left: 180px;
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .home-contact .shape.shape-1 {
        left: 20px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .home-contact .shape.shape-1 {
        left: -80px;
        bottom: -180px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-contact .shape.shape-1 {
        left: -80px;
        bottom: -120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-contact .shape.shape-1 {
        left: -80px;
        bottom: -100px;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact .shape.shape-1 {
        display: none;
    }
}

.home-contact .shape.shape-2 {
    bottom: -260px;
    right: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .home-contact .shape.shape-2 {
        bottom: -180px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-contact .shape.shape-2 {
        bottom: -120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-contact .shape.shape-2 {
        bottom: -100px;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact .shape.shape-2 {
        display: none;
    }
}

.contact-us .heading-one {
    margin: 0 auto 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-us .heading-one {
        margin: 0 auto 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-us .heading-one {
        margin: 0 auto 60px;
    }
}

@media only screen and (max-width: 767px) {
    .contact-us .heading-one {
        margin: 0 auto 40px;
    }
}

.contact-us .contact-head {
    max-width: 480px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .contact-us .contact-head {
        max-width: 100%;
        margin-bottom: 40px;
    }
}

.contact-us .contact-info li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.contact-us .contact-info li:last-child {
    margin-bottom: 0px;
}

.contact-us .contact-info li .icon {
    height: 75px;
    width: 75px;
    background-color: #eaf6fc;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #322fb3;
    margin-right: 20px;
}

@media only screen and (max-width: 479px) {
    .contact-us .contact-info li .icon {
        height: 60px;
        width: 60px;
        margin-right: 20px;
        font-size: 20px;
    }
}

.contact-us .contact-info li .title {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {
    .contact-us .contact-info li .title {
        font-size: 16px;
    }
}

.contact-us .contact-info li .text {
    color: #717788;
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .contact-us .contact-info li .text {
        font-size: 16px;
    }
}

.contact-us .contact-info li .text a {
    color: #717788;
}

.contact-us .contact-info li .text a:hover {
    color: #322fb3;
}

.contact-us .contact-info .contact-head .text {
    color: #54545f;
    font-size: 18px;
}

.contact-us .contact-info .contact-head .text a {
    color: #54545f;
}

.contact-us .contact-info .contact-head .text a:hover {
    color: #322fb3;
}

@media only screen and (max-width: 767px) {
    .contact-us .contact-form {
        margin-top: 20px;
    }
}

.contact-us .contact-form input,
.contact-us .contact-form textarea {
    font-size: 14px;
    width: 100%;
    border: 1px solid #cbcbdc;
    height: 60px;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #ffffff;
    color: #343c55;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .contact-us .contact-form input,
    .contact-us .contact-form textarea {
        height: 50px;
    }
}

@media only screen and (max-width: 767px) {

    .contact-us .contact-form input,
    .contact-us .contact-form textarea {
        height: 50px;
    }
}

.contact-us .contact-form input::-moz-placeholder,
.contact-us .contact-form textarea::-moz-placeholder {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    color: #717788;
    font-size: 16px;
    line-height: 1;
}

.contact-us .contact-form input::placeholder,
.contact-us .contact-form textarea::placeholder {
    font-family: "Helvetica Neue LT W01 45 Light", Arial, sans-serif !important;
    color: #717788;
    font-size: 16px;
    line-height: 1;
}

.contact-us .contact-form input:focus-visible,
.contact-us .contact-form textarea:focus-visible {
    outline: 1px solid rgb(50, 47, 179);
}

.contact-us .contact-form textarea {
    height: 160px;
    padding-top: 20px;
    border-radius: 7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-us .contact-form textarea {
        height: 140px;
    }
}

@media only screen and (max-width: 767px) {
    .contact-us .contact-form textarea {
        height: 120px;
    }
}

.contact-us .contact-form .btn {
    padding: 22px 58px;
}

@media only screen and (max-width: 767px) {
    .contact-us .contact-form .btn {
        padding: 18px 45px;
    }
}

@media only screen and (max-width: 575px) {
    .contact-us .contact-form .btn {
        padding: 18px 30px;
    }
}

.contact-us .shape {
    height: 100%;
    width: 100%;
    position: absolute !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .contact-us .shape {
        display: none;
    }
}

.contact-us .shape .shape1 {
    position: absolute;
    left: 4%;
    bottom: 15%;
    z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
    .contact-us .shape .shape1 img {
        width: 85%;
    }
}

.contact-us .shape .shape2 {
    position: absolute;
    left: 84.4%;
    bottom: 25%;
    z-index: 1;
}


/* Map Style */

.google-map-area {
    position: relative;
}

.google-map-area .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
}

.google-map-area .map-inner iframe {
    border-radius: 20px;
    width: 100%;
    height: 425px;
}


/*----------------------------------------*/


/*  2.24 - Newsletter Styles CSS
/*----------------------------------------*/

.newsletter {
    position: relative;
}

.newsletter-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 9;
}

.newsletter-inner {
    background: #322fb3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 13px 0 0 13px;
    padding: 70px 0px 70px 70px;
    position: relative;
}

.newsletter-inner::after {
    background-color: #322fb3;
    content: "";
    height: 100%;
    left: 98%;
    position: absolute;
    top: 0;
    width: 5000px;
    z-index: -1;
}

@media only screen and (max-width: 767px) {
    .newsletter-inner::after {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .newsletter-inner {
        padding: 70px 0px 70px 40px;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .newsletter-inner {
        padding: 70px 0px 70px 30px;
        -moz-column-gap: 10px;
        column-gap: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .newsletter-inner {
        padding: 70px 30px 70px 30px;
        border-radius: 13px;
        row-gap: 30px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 575px) {
    .newsletter-inner {
        padding: 50px 30px;
    }
}

.newsletter-inner .heading-one {
    max-width: 530px;
    width: 100%;
}

.newsletter-inner .heading-one-subtitle {
    color: #ffffff;
}

.newsletter-inner .heading-one-title {
    color: #ffffff;
    font-size: 48px;
    margin: 5px 0 20px;
}

@media only screen and (max-width: 767px) {
    .newsletter-inner .heading-one-title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .newsletter-inner .heading-one-title {
        font-size: 36px;
    }
}

.newsletter-inner .heading-one-text {
    color: #ffffff;
}

.newsletter-inner .footer-widget-form {
    max-width: 470px;
    width: 100%;
    margin-top: 0px;
}

.newsletter-inner .footer-widget-form input {
    height: 72px;
    padding: 0 60px 0 30px;
}

@media only screen and (max-width: 767px) {
    .newsletter-inner .footer-widget-form {
        max-width: 500px;
    }
}

.newsletter .shape {
    position: absolute;
}

.newsletter .shape.shape-1 {
    top: -70px;
    left: 155px;
    z-index: -2;
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .newsletter .shape.shape-1 {
        top: -50px;
        left: 15px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
    .newsletter .shape.shape-1 {
        top: 5px;
        left: 0px;
        width: 40%;
    }

    .newsletter .shape.shape-1 img {
        width: 20%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .newsletter .shape.shape-1 {
        display: none;
    }
}

.newsletter .shape.shape-2 {
    top: -50px;
    right: 60px;
    z-index: 10;
}

@media only screen and (max-width: 767px) {
    .newsletter .shape.shape-2 {
        display: none;
    }
}

.newsletter-two {
    position: relative;
}

.newsletter-two-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 40px;
    /* border-bottom: 1px solid rgba(50, 47, 179, 0.13); */
    /* margin-bottom: 120px; */
    -moz-column-gap: 20px;
    column-gap: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .newsletter-two-inner {
        flex-direction: column;
        row-gap: 30px;
        padding: 0 0 0px;
        align-items: start;
        margin-bottom: 15px;
    }
}

.newsletter-two-inner .heading-one {
    max-width: 530px;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .newsletter-two-inner .heading-one {
        max-width: 100%;
    }
}

.newsletter-two-inner .heading-one-title {
    font-size: 48px;
    margin: 5px 0 20px;
}

@media only screen and (max-width: 767px) {
    .newsletter-two-inner .heading-one-title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .newsletter-two-inner .heading-one-title {
        font-size: 36px;
    }
}

.newsletter-two-inner .footer-widget-form {
    max-width: 500px;
    width: 100%;
    margin-top: 0px;
    overflow: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .newsletter-two-inner .footer-widget-form {
        max-width: 100%;
    }
}

.newsletter-two-inner .footer-widget-form input {
    height: 72px;
    padding: 0 200px 0 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .newsletter-two-inner .footer-widget-form input {
        padding: 0 180px 0 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .newsletter-two-inner .footer-widget-form input {
        padding: 0 180px 0 20px;
    }
}

@media only screen and (max-width: 767px) {
    .newsletter-two-inner .footer-widget-form input {
        padding: 0 160px 0 20px;
        height: 60px;
    }
}

.newsletter-two-inner .footer-widget-form .btn {
    right: 0;
    top: 0;
    height: 100%;
    transform: inherit;
    background: #322fb3;
    background: linear-gradient(-45deg, #5bcff2 0%, #417dd2 50%, #322fb3 100%);
    border-radius: 0 7px 7px 0;
    padding: 20px 48px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 18px;
}

.newsletter-two-inner .footer-widget-form .btn::before {
    border-radius: 0 7px 7px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
    .newsletter-two-inner .footer-widget-form .btn {
        padding: 20px 35px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .newsletter-two-inner .footer-widget-form .btn {
        padding: 20px 35px;
    }
}

@media only screen and (max-width: 767px) {
    .newsletter-two-inner .footer-widget-form .btn {
        padding: 20px 25px;
    }
}

.shape1,
.shape2,
.shape3,
.shape4,
.shape5,
.shape6,
.shape7,
.shape8,
.shape9,
.shape10,
.shape11,
.shape12,
.shape13,
.shape14,
.shape-4 {
    opacity: 0.5 !important;
}

.shape10 {
    text-align: right !important;
}

.shape12 {
    text-align: right;
    top: 0 !important;
}

.mob-text {
    font-size: 17px !important;
    font-weight: 600 !important;
}

.f-600 {
    font-weight: 600 !important;
}

.active {
    font-weight: 700 !important;
    color: #ea8122 !important;
}

.breadcrumb {
    background-size: auto;
    background-repeat: repeat;
}

.newsCaption {
    position: absolute;
    top: auto;
    bottom: -80px;
    opacity: 1;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    padding: 15px;
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s;
}

.blogpost:hover .newsCaption {
    bottom: 0px;
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s;
    transition: transform 0.4s, opacity 0.1s, -webkit-transform 0.4s;
}

b {
    font-weight: 600 !important;
}

.faq-accordion-button {
    font-weight: 600;
    letter-spacing: 2px;
}

.owl-stage {
    display: flex !important;
    align-items: center !important;
}